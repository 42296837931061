import React from 'react';
import "../../Css/companypage.css"
import { Bolt, BoltRounded } from '@mui/icons-material';
import { Helmet } from "react-helmet";
function Terms() {
   
  return (
      <>
      <Helmet>
        <title>RxOnecare - Terms of Service 
        </title></Helmet>
        <Helmet> <meta name="description" content="Explore our comprehensive healthcare services, offering telemedicine consultations, personalized patient care, and advanced electronic medical records. Join us for convenient access to healthcare providers and improved wellness management from the comfort of your home." /></Helmet>
      <Helmet>  <meta name="keywords" content="RxOne, healthcare services, telemedicine consultations, online doctor visits, digital health solutions, patient care management, medical technology innovations, telehealth platforms, wellness programs, electronic medical records, health management tools, healthcare access, chronic disease management, preventive health services, mental health support, virtual healthcare solutions, personalized medicine, healthcare providers directory, health education resources, medical advice online, remote patient monitoring
" />
      </Helmet>
         <div className="pp__first">
             <h1 style={{fontWeight:"700",color:"#005C74"}} >Terms of Service</h1>
             <div className="tt__firstpara">
                 <h4 style={{display:"flex", alignItems:"start"}}>Last Revision Date: 8 th October 2024</h4>
                 <p>These terms and conditions specific to Healthcare Providers (“HP Terms”) form a legally binding
agreement between Rx One Care Private Limited (“We” or “Us” or “Our” or “RxOne” or “Company”),
having its registered office at M3M Sierra 4 101 Sector 68, Gurugram, Haryana, India – 122101 and
You (“You” or “Your”), as an HP User of Our Website, System and Services.</p>
<p>You and We are hereinafter collectively referred to as the "Parties".</p>
<p>By clicking “sign up” or the “I accept” tab at the time of registration, or by entering into an
agreement with RxOne to provide committed services as set out in these HP Terms , or through the
continued use of the System and/or Services,, or by Accessing the System and/or Services through
any medium, including but not limited to accessing the System through mobile phones, smart
phones and tablets, You agree to be subject to these HP Terms.</p>
<p>We request You to please read these HP Terms carefully and do not click “sign up” or “I accept” or
continue the use of the Website, System and Service unless You agree fully with these HP Terms.</p>
<p>These HP Terms are in addition to the Terms of Use of the Website available
at [https://rxone.app/terms], the Privacy Policy [https://rxone.app/privacypolicy] and any other
policy which may govern the use of the Website, System and Services referred to as the "Other Terms" and collectively with the HP Terms referred to as <h6>"Agreement"</h6></p>
             </div>
             <div className="tt__secondpara">
                 <h5 style={{display:"flex",alignItems:"start"}}>1. Definitions</h5>
                 
               
                  <p>1.1. As used in these HP Terms, the following terms shall have the meaning set forth below:<br></br><br></br>
i. &quot;Effective Date&quot; means the Date on which You accept these HP Terms by clicking
“Sign Up” or “I Accept”;<br></br><br></br>
ii. “User Information” means information regarding Registered Users which includes
personal and medical information and any other information which may be provided
by a Registered Users to You or may be transferred to You by RxOne;<br></br><br></br>
iii. &quot;Services&quot; means the services offered to You by RxOne that involves use of the
System, which may include the practice management service, electronic medical
records service and other services as may be introduced by RxOne from time to
time;<br></br><br></br>
iv. &quot;Website&quot; means www.rxonecare.com<br></br><br></br>
v. &quot;System&quot; means the technology platform provided as part of the Website consisting
of hardware and / or software used or provided by Us for the purpose of providing
the Services to You;</p>
</div>
<div className="tt__secondpara">
                 <h5 style={{display:"flex",alignItems:"start"}}>2. Grant of Rights</h5>
                 
               
                  <p>2.1. Subject to the terms of the Agreement, we grant to You and You accept a non-exclusive,
personal, non-transferable, limited right to have access to and to use the System for the
duration of Your engagement with Us.<br></br><br></br>
2.2. The aforementioned right does not extend to : (a) use the System for time-sharing, rental or
service bureau purposes; (b) make the System, in whole or in part, available to any other
person, entity or business; (c) modify the contents of the Systems and the Website or use
such content for any commercial purpose, or any public display, performance, sale or rental

other than envisaged in the Agreement; (c) copy, reverse engineer, decompile or
disassemble the System or the Website, in whole or in part, or otherwise attempt to
discover the source code to the software used in the System; or (d) modify the System or
associated software or combine the System with any other software or services not provided
or approved by Us.<br></br><br></br>
2.3. You will obtain no rights to the System except for the limited rights to use the System
expressly granted by these HP Terms.<br></br><br></br>
2.4. The System/Website may links or references which direct you to third party websites /
applications / content or service providers, including advertisers and e-commerce websites
(collectively referred to as &quot;Third Party Websites&quot;). Links to such Third-Party Websites are
provided for your convenience only. Please exercise your independent judgment and
prudence when visiting / using any Third Party Websites via a link available on the System /
Website. Should You decide to click on the links to visit such Third Party Website, You do so
of Your own volition. Your usage of such Third Party Websites and all content available on
such Third Party Websites is subject to the terms of use of the respective Third Party
Website and we is not responsible for Your use of any Third Party Websites.</p>
</div>
<div className="tt__secondpara">
                 <h5 style={{display:"flex",alignItems:"start"}}>3. By accepting these HP Terms, You agree that:</h5>
                 
               
                  <p>3.1. You will acquire, install, configure and maintain all hardware, software and communications
systems necessary to access the System (&quot;Implementation&quot;) and receive the Services.<br></br><br></br>
3.2. To the extent possible, such an assessment should be done before making advance payment
for the Service. Your Implementation will comply with the specifications from time to time
established by Us. You will ensure that Your Implementation is compatible with the System
and Services.<br></br><br></br>
3.3. If We notify You that Your Implementation is incompatible with the System and / or Services,
You will rectify such incompatibility, and We will have the right to suspend Services to You
until such rectification has been implemented. Under no circumstances will You be eligible
for any refund or any financial assistance in relation to Implementation.</p>
</div>
<div className="tt__secondpara">
                 <h5 style={{display:"flex",alignItems:"start"}}>4. Engagement of HPs by RxOne</h5>
                 
               
                  <p>4.1. In certain cases, You and RxOne may agree that You will commit to providing information
and responses on the Website for a specific period of time (such as a specific number of
hours per day / week/ month).<br></br><br></br>
4.2. In such a case, while all the terms of the Agreement will continue to apply to You, there may
be some additional terms which will apply to You which will be agreed between You and
RxOne.</p>
</div>
<div className="tt__secondpara">
                 <h5 style={{display:"flex",alignItems:"start"}}>5. Access to the System and Use of Services</h5>
                 
               
                  <p>5.1. Verification<br></br>
a) You agree that Your receipt of Services is subject to verification by Us of Your
identity and credentials as a health care practitioner and to Your ongoing
qualification as such. As part of the registration process and at any time thereafter,
You may be required to provide Us with various information such as Your Photo Id,

Your medical registration details (as recognized by the Medical Council of India and
Your State Medical Council), Your qualifications and other information in order to
prove that You are a valid health care practitioner in the field that You claim
(&quot;Credential Information&quot;). We may verify such Credential Information or may ask
You for additional information.<br></br><br></br>
b) We may also make enquiries from third parties to verify the authenticity of Your
Credential Information.<br></br><br></br>
c) You authorize Us to make such enquiries from such third parties, and You agree to
hold them and Us harmless from any claim or liability arising from the request for or
disclosure of such information.<br></br><br></br>
d) You agree that We may terminate Your access to or use of the System and Services
at any time if We are unable at any time to determine or verify Your Credential
Information.<br></br><br></br>
e) We reserve to right to carry out re-verification of Credential Information as and
when required, and the above rights and commitments will extend to re-verification
as well.<br></br><br></br>
5.2. Safeguards<br></br>
a) You will implement and maintain appropriate administrative, physical and technical
safeguards to protect the System from access, use or alteration; and You will always
use the User ID assigned to You.<br></br><br></br>
b) You will immediately notify Us of any breach or suspected breach of the security of
the System of which You become aware, or any unauthorized use or disclosure of
information within or obtained from the System, and You will take such action to
mitigate the breach or suspected breach as We may direct, and will cooperate with
Us in investigating and mitigating such breach.<br></br><br></br>

5.3. No Third-Party Access<br></br>
a) You will not permit any third party to have access to the System or to use the
System or the Services without Our prior written consent. You will not allow any
third party to access the System or provide information to Registered Users/ Non-
Registered Users on the Website.<br></br><br></br>
b) You will promptly notify Us of any order or demand for compulsory disclosure of
health information if the disclosure requires access to or use of the System or
Services.</p>
</div>
<div className="tt__secondpara">
                 <h5 style={{display:"flex",alignItems:"start"}}>6. Compliance</h5>
                 
               
                  <p>6.1. You are solely responsible for ensuring that Your use of the System and the Services
complies with applicable law. You will also ensure that Your use of the System, the Website
and the Services in always in accordance with the terms of the Agreement. You will not
undertake or permit any unlawful use of the System or Services, or take any action that
would render the operation or use of the System or Services by us.<br></br> <br></br>

6.2. Without limiting the generality of the foregoing, You represent that You shall not use the
System in violation of any applicable laws including MCIs Code of Medical Ethics Regulations
or any other code of conduct governed by your council. Notwithstanding the generality of
the foregoing, You shall not use the System to:<br></br>
a) Interact with a Registered Users at the time of medical emergencies.<br></br>
b) Discriminate in any way between appointments booked in the ordinary course and
appointments booked through RxOne.<br></br>
c) Boast of cases, operations, cures or remedies through System, Services or Website.
d) Directly or indirectly solicit Registered Users for consultation.<br></br>
e) Claim to be a specialist, through System, Services or Website, unless You have a
special qualification in that branch.<br></br>
f) Give any positive assertion or representation regarding the risk-free nature of
communicating over online media.<br></br><br></br>

6.3. You shall keep Your Credential Information updated and will inform Us immediately should
any portion of Your Credential Information be revoked, is cancelled or expires.</p>
</div>
<div className="tt__secondpara">
                 <h5 style={{display:"flex",alignItems:"start"}}>7. System Automated Services</h5>
                 
               
                  <p>7.1. The System has automated features which allow you to automate daily activities including
but not limited to appointment management, writing digital prescriptions, patient
engagement, patient vital monitoring, summarization of medical records and files, and
managing interdepartmental workflow.<br></br><br></br>
7.2. You hereby agree that you agree to use these Services with reasonable care and precaution
while providing Your services to the Registered User.<br></br><br></br>
7.3. You hereby agree that in case of any faults in the System, You shall duly inform RxOne
regarding such faults and exercise reasonable steps to mitigate such faults. RxOne shall not
be held responsible under any circumstances for the lapse in Services due to such faults.</p>
</div>
<div className="tt__secondpara">
                 <h5 style={{display:"flex",alignItems:"start"}}>8. User information</h5>
                 
               
                  <p>8.1. You hereby acknowledge that You may get access to User Information including identifiable
health related information.<br></br><br></br>
8.2. You represent and warrant that You will, at all times during the use of the Services and
thereafter, comply with all laws directly or indirectly applicable to You that may now or
hereafter govern the collection, use, transmission, processing, receipt, reporting, disclosure,
maintenance, and storage of User Information, including but not limited to the Information
Technology Act, 2000 and The Information Technology (Reasonable Security Practices and
Procedures and Sensitive Personal Data or Information) Rules, 2011 made thereunder.<br></br><br></br>
8.3. Notwithstanding the generality of the aforementioned provision<br></br>
a) You acknowledge that You have read, understood and agree to comply with RxOne&#39;s
Privacy Policy available at [https://rxone.app/privacypolicy] when dealing with User
Information.<br></br>

b) You represent and warrant that You will not use the User Information of Registered
Users and Non-Registered Users for any other purpose than for providing medical
consultations to such Registered Users and Non-Registered Users and /or fixing
appointments with the Registered Users.</p>
</div>
<div className="tt__secondpara">
                 <h5 style={{display:"flex",alignItems:"start"}}>9. Cooperation</h5>
                 
               
                  <p>9.1. You will cooperate with Us in the administration of the System, including providing
reasonable assistance in evaluating the System and collecting and reporting data requested
by Us for the purposes of administering the System.<br></br><br></br>
9.2. We may provide Your reference to other potential users of the system as a referral to Our
Services. These referrals shall be adhere to the referral arrangement / agreement executed
between You and RxOne.</p>
</div>
<div className="tt__secondpara">
                 <h5 style={{display:"flex",alignItems:"start"}}>10. Providing Physician Data</h5>
                 
               
                  <p>10.1. You agree that We may provide de-identified health information and other information
including Your personal information and information concerning Your practice to any
medical group, independent practice association of physicians, health plan or other
organization including any organization with which You have a contract to provide medical
services, or to whose members or enrollees You provide medical services.<br></br><br></br>
10.2. Such information may identify You, but will not identify any individual to whom You provide
services. Such information may include (without limitation) aggregate data concerning Your
patients, diagnoses, procedures, orders etc.</p>
</div>
<div className="tt__secondpara">
                 <h5 style={{display:"flex",alignItems:"start"}}>11. Intellectual Property Rights</h5>
                 
               
                  <p>11.1. All intellectual property rights in and title to the System, the present or future modifications
/ upgradations thereof and standard enhancements thereto shall remain the property of
RxOne Inc. and its licensors.<br></br><br></br>
11.2. These HP Terms or the Agreement do not and shall not transfer any ownership or
proprietary interest in the System from RxOne to You, except as may be otherwise expressly
provided in these HP Terms or as may be agreed to by and between RxOne and You.</p>
</div>
<div className="tt__secondpara">
                 <h5 style={{display:"flex",alignItems:"start"}}>12. Fees and Charges</h5>
                 
               
                  <p>12.1. You will be obligated to pay Us Our standard service fee (the “Service Fee”) for the Services
to which You have access during Your subscription to the System and Our Services. You also
agree to pay, Our then current rates, for all goods or services that You request from Us and
that are not included in Our standard services (“Miscellaneous Charges”). We will notify You
of the Service Fee and Miscellaneous Charges when You are granted access to a service, and
We will notify You of the applicable Miscellaneous Charges before providing services to
which a Miscellaneous Charge will apply.<br></br><br></br>
12.2. For more information on RxOne&#39;s Service Fees with respect to Services including modes of
payment, please refer to the detailed agreement / order / arrangement agreed with RxOne.
RxOne shall have the right to change the Service Fees for the Services at any time, with
fifteen (15) days’ notice to You. Your continued use of the System and the Website shall be
deemed to be Your acceptance of such changes.<br></br><br></br>

12.3. You understand and acknowledge that We follow a prepay payment model for Our Services.
Accordingly, Your access to the Services can be limited or suspended or terminated in case
You are unable to pay the Service Fee which you may have agreed to at the time of
subscription (&quot;Non Payment State&quot;). RxOne reserves the right to charge additional charges
(&quot;Activation Fees&quot;) in case Your Services need to be restored after being suspended for non-
payment.<br></br><br></br>
12.4. All charges and fees shall be exclusive of all state, municipal, or other government excise,
sales, service, use, occupational, or like taxes now in force. We have a right to pass on any
additional taxes or other state levies to You and You agree to pay any tax (excluding taxes on
Our net income) that We may be required to collect or pay now or at any time in the future
and that are imposed upon the sale or delivery of items and Services purchased under these
HP Terms.<br></br><br></br>
12.5. You are responsible for any charges You incur to use the System, such as telephone and
equipment charges, and fees charged by third-party vendors of products and services. <br></br><br></br>
12.6. We reserve to change the terms applicable to fee at any point in time by giving You fifteen
(15) days&#39; prior intimation via email. The revised fee terms shall be automatically applicable
to You after the fifteen (15) days&#39; notice period. You agree that no separate confirmation or
approval is required from You to amend the terms with regard to the fee. <br></br><br></br>
12.7. We do not have any refunds policy. You can stop payment of the Service Fees at any point in
time to discontinue using Our Service. At such point, Your subscription shall last till the end
of Your subscription period (a maximum of 30 days) and shall then be moved to Non
Payment State. Provisions of Section 11.3 above shall apply. <br></br><br></br>
12.8. The HP Fees collected by RxOne on your behalf from Registered Users shall be disbursed to
your bank within 45 days of the date of the completion of the consultation for which such
HP Fees were collected. You agree that the consultation shall be deemed to have been
completed after expiry of fifteen (15) days from the date of the consultation. For the
removal of doubts, a consultation shall be deemed to have been completed when the payee
Registered User has been provided the level of consultation reasonably expected from you
in consideration of the HP Fees.<br></br><br></br>
12.9. You hereby grant Us written consent to collect, store, handle and use Your financial
information such as bank account or credit card or debit card or other payment instrument
details for the purpose of paying for Services and access to System in accordance with Our
Privacy Policy.<br></br><br></br>
12.10. In case RxOne is of the opinion that the consultation provided by you to Registered User
against the HP Fees is unsatisfactory or deficient in some way, then it reserves the right to
(a) where the corresponding HP Fees is unpaid, forfeit the said fees or (b) where the
corresponding HP Fee is paid, (i) recover the corresponding HP Fees by adjusting the amount
equivalent to HP Fees against any future payments that may be due or (ii) make a claim of
the corresponding HP Fees on you. You agree that upon receipt to a claim from RxOne, you
will pay the amount to RxOne within fifteen (15) days from the date of the claim.</p>
</div>
<div className="tt__secondpara">
                 <h5 style={{display:"flex",alignItems:"start"}}>13. Confidential Information</h5>
                 
               
                  <p>13.1. You will treat all information received from Us as confidential. You may not disclose Our
confidential information to any other person, and You may not use any confidential
information except as provided herein. Except as otherwise provided in HP Terms and Other
Terms, You may not, without Our prior written consent, at any time, during or after the
applicability of these HP Terms, directly or indirectly, divulge or disclose confidential
information for any purpose or use confidential information for Your own benefit or for the
purposes or benefit of any other person. You agree to hold all confidential information in
strict confidence and to take all measures necessary to prevent unauthorized copying, use,
or disclosure of confidential information, and to keep the confidential information from
being disclosed into the public domain or into the possession of persons not bound to
maintain confidentiality. You will disclose confidential information only to your employees,
agents or contractors who have a need to use it for the purposes permitted under the HP
Terms and Other Terms only. You will inform all such recipients of the confidential nature of
confidential information and will instruct them to deal with confidential information in
accordance with these HP Terms. You will promptly notify Us in writing of any improper
disclosure, misappropriation, or misuse of the confidential information by any person, which
may come to Your attention.<br></br><br></br>
13.2. You agree that We will suffer irreparable harm if You fail to comply with the obligations set
forth in this Section 12, and You further agree that monetary damages will be inadequate to
compensate Us for any such breach. Accordingly, You agree that We will, in addition to any
other remedies available to Us at law or in equity, be entitled to seek injunctive relief to
enforce the provisions hereof, immediately and without the necessity of posting a bond. <br></br><br></br>
13.3. This Section 12 will survive the termination or expiration of these HP Terms or Agreement
for any reason.</p>
</div>
<div className="tt__secondpara">
                 <h5 style={{display:"flex",alignItems:"start"}}>14. Disclaimer and Exclusion of Warranties</h5>
                 
               
                  <p>14.1. YOU ACKNOWLEDGE THAT ACCESS TO THE SYSTEM WILL BE PROVIDED OVER VARIOUS
FACILITIES AND COMMUNICATION LINES, AND INFORMATION WILL BE TRANSMITTED OVER
LOCAL EXCHANGE AND INTERNET BACKBONE CARRIER LINES AND THROUGH ROUTERS,
SWITCHES, AND OTHER DEVICES (COLLECTIVELY, &quot;CARRIER LINES&quot;) OWNED, MAINTAINED,
AND SERVICED BY THIRD-PARTY CARRIERS, UTILITIES, AND INTERNET SERVICE PROVIDERS,
ALL OF WHICH ARE BEYOND OUR CONTROL. WE ASSUME NO LIABILITY FOR OR RELATING TO
THE INTEGRITY, PRIVACY, SECURITY, CONFIDENTIALITY, OR USE OF ANY INFORMATION
WHILE IT IS TRANSMITTED ON THE CARRIER LINES, OR ANY DELAY, FAILURE, INTERRUPTION,
INTERCEPTION, LOSS, TRANSMISSION, OR CORRUPTION OF ANY DATA OR OTHER
INFORMATION ATTRIBUTABLE TO TRANSMISSION ON THE CARRIER LINES. USE OF THE
CARRIER LINES IS SOLELY AT YOUR RISK AND IS SUBJECT TO ALL APPLICABLE LOCAL, STATE,
NATIONAL, AND INTERNATIONAL LAWS. <br></br><br></br>
14.2. THE SERVICES, THE WEBSITE, THE SYSTEM, ACCESS TO THE SYSTEM AND THE INFORMATION
CONTAINED ON THE SYSTEM IS PROVIDED &quot;AS IS&quot; AND &quot;AS AVAILABLE&quot; BASIS WITHOUT ANY
WARRANTY OF ANY KIND, EXPRESSED OR IMPLIED, INCLUDING BUT NOT LIMITED TO THE
IMPLIED WARRANTIES OF MERCHANTABILITY, FITNESS FOR A PARTICULAR PURPOSE, AND
NON-INFRINGEMENT. YOU ARE SOLELY RESPONSIBLE FOR ANY AND ALL ACTS OR OMISSIONS

TAKEN OR MADE IN RELIANCE ON THE SYSTEM OR THE INFORMATION IN THE SYSTEM,
INCLUDING INACCURATE OR INCOMPLETE INFORMATION. IT IS EXPRESSLY AGREED THAT IN
NO EVENT SHALL WE BE LIABLE FOR ANY SPECIAL, INDIRECT, CONSEQUENTIAL, REMOTE OR
EXEMPLARY DAMAGES, INCLUDING BUT NOT LIMITED TO, LOSS OF PROFITS OR REVENUES,
LOSS OF USE, OR LOSS OF INFORMATION OR DATA, WHETHER A CLAIM FOR ANY SUCH
LIABILITY OR DAMAGES IS PREMISED UPON BREACH OF CONTRACT, BREACH OF WARRANTY,
NEGLIGENCE, STRICT LIABILITY, OR ANY OTHER THEORY OF LIABILITY, EVEN IF WE HAVE
BEEN APPRISED OF THE POSSIBILITY OR LIKELIHOOD OF SUCH DAMAGES OCCURRING. WE
DISCLAIM ANY AND ALL LIABILITY FOR ERRONEOUS TRANSMISSIONS AND LOSS OF SERVICE
RESULTING FROM COMMUNICATION FAILURES BY TELECOMMUNICATION SERVICE
PROVIDERS OR THE SYSTEM.<br></br><br></br> 
14.3. YOU ACKNOWLEDGE THAT OTHER USERS HAVE ACCESS TO THE SYSTEM AND ARE RECEIVING
OUR SERVICES. SUCH OTHER USERS HAVE COMMITTED TO COMPLY WITH THESE TERMS &amp;
CONDITIONS AND OUR POLICIES AND PROCEDURES CONCERNING USE OF THE SYSTEM;
HOWEVER, THE ACTIONS OF SUCH OTHER USERS ARE BEYOND OUR CONTROL.
ACCORDINGLY, WE DO NOT ASSUME ANY LIABILITY FOR OR RELATING TO ANY IMPAIRMENT
OF THE PRIVACY, SECURITY, CONFIDENTIALITY, INTEGRITY, AVAILABILITY, OR RESTRICTED
USE OF ANY INFORMATION ON THE SYSTEM RESULTING FROM ANY USERS&#39; ACTIONS OR
FAILURES TO ACT.<br></br><br></br> 
14.4. WE ARE NOT RESPONSIBLE FOR UNAUTHORIZED ACCESS TO YOUR, DATA, FACILITIES OR
EQUIPMENT BY INDIVIDUALS OR ENTITIES USING THE SYSTEM OR FOR UNAUTHORIZED
ACCESS TO, ALTERATION, THEFT. CORRUPTION, LOSS OR DESTRUCTION OF YOUR, DATA
FILES, PROGRAMS, PROCEDURES, OR INFORMATION THROUGH THE SYSTEM, WHETHER BY
ACCIDENT, FRAUDULENT MEANS OR DEVICES, OR ANY OTHER MEANS. YOU ARE SOLELY
RESPONSIBLE FOR VALIDATING THE ACCURACY OF ALL OUTPUT AND REPORTS, AND FOR
PROTECTING YOUR DATA AND PROGRAMS FROM LOSS BY IMPLEMENTING APPROPRIATE
SECURITY MEASURES, INCLUDING ROUTINE BACKUP PROCEDURES. YOU HEREBY WAIVE ANY
DAMAGES OCCASIONED BY LOST OR CORRUPT DATA, INCORRECT REPORTS, OR INCORRECT
DATA FILES RESULTING FROM PROGRAMMING ERROR, OPERATOR ERROR, EQUIPMENT OR
SOFTWARE MALFUNCTION, SECURITY VIOLATIONS, OR THE USE OF THIRD-PARTY
SOFTWARE. WE ARE NOT RESPONSIBLE FOR THE CONTENT OF ANY INFORMATION
TRANSMITTED OR RECEIVED THROUGH OUR PROVISION OF THE SERVICES.<br></br><br></br> 
14.5. WE EXPRESSLY DISCLAIM ANY LIABILITY FOR THE CONSEQUENCES TO YOU ARISING BECAUSE
OF YOUR USE OF THE SYSTEM OR THE SERVICES.<br></br><br></br>
14.6. WE DO NOT WARRANT THAT YOUR USE OF THE SYSTEM AND THE SERVICES UNDER THESE
TERMS WILL NOT VIOLATE ANY LAW OR REGULATION APPLICABLE TO YOU..</p>
</div>
<div className="tt__secondpara">
                 <h5 style={{display:"flex",alignItems:"start"}}>15. Limitation Of Liability</h5>
                 
               
                  <p>15.1. NOTWITHSTANDING THE OTHER TERMS OF THESE HP TERMS, IN THE EVENT RXONE SHOULD
HAVE ANY LIABILITY TO YOU OR ANY THIRD PARTY FOR ANY LOSS, HARM OR DAMAGE, YOU
AND RXONE AGREE THAT SUCH LIABILITY SHALL UNDER NO CIRCUMSTANCES EXCEED THE
VALUE OF ANY FEES RECEIVED BY RXONE FROM YOU IN THE PRECEDING TWELVE MONTHS
OR INR 5000 WHICHEVER IS LOWER.<br></br> <br></br>

15.2. YOU AND RXONE AGREE THAT THE FOREGOING LIMITATION OF LIABILITY IS AN AGREED
ALLOCATION OF RISK BETWEEN YOU AND RXONE. YOU ACKNOWLEDGE THAT WITHOUT
YOUR ASSENT TO THIS SECTION 14, RXONE WOULD NOT PROVIDE ACCESS TO THE SYSTEM,
TO YOU.</p>
</div>
<div className="tt__secondpara">
                 <h5 style={{display:"flex",alignItems:"start"}}>16. Indemnification</h5>
                 
               
                  <p>16.1. You agree to indemnify, defend, and hold harmless RxOne, Our and their affiliates, officers,
directors, and agents, from and against any claim, cost or liability, including reasonable
attorneys&#39; fees, arising out of: (a) the use of the Services; (b) any breach by You of any
representations, warranties or agreements contained in these HP Terms ; (c) the actions of
any person gaining access to the System under a User ID assigned to You; (d) the actions of
anyone using a User ID, password or other unique identifier assigned to You that adversely
affects the System or any information accessed through the System;</p>
</div>
<div className="tt__secondpara">
                 <h5 style={{display:"flex",alignItems:"start"}}>17. Termination; Modification; Suspension; Termination</h5>
                 
               
                  <p>17.1. We or You may terminate our Services at any time without cause upon thirty (30) days prior
written notice to You.<br></br> <br></br>
17.2. We may update or change the Services and/or the HP Terms and/ or the Service Fee set
forth in these HP Terms from time to time and recommend that You review these HP Terms
on a regular basis. You understand and agree that Your continued use of the Services after
the HP Terms has been updated or changed constitutes Your acceptance of the revised HP
Terms. Without limiting the foregoing, if We make a change to these HP Terms that
materially affects Your use of the Services, We may post notice on the Website or notify You
via email of any such change.<br></br> <br></br>
17.3. Termination, Suspension or Amendment as a result of applicable laws - Notwithstanding
anything to the contrary in these HP Terms , We have the right, on providing notice to You,
immediately to terminate, suspend, or amend the provision of the Services without liability:
(a) to comply with any order issued or proposed to be issued by any governmental agency;
(b) to comply with any provision of law, any standard of participation in any reimbursement
program, or any accreditation standard; or (c) if performance of any term of these HP Terms
by either Party would cause it to be in violation of law.<br></br> <br></br> 
17.4. We may terminate the provision of Services to You through the System immediately upon
notice to You: (i) if You are named as a defendant in a criminal proceeding for a violation of
federal or state law; (ii) if a finding or stipulation is made or entered into that You have
violated any standard or requirement of federal or state law relating to the privacy or
security of health information is made in any administrative or civil proceeding; or (iii) You
cease to be qualified to provide services as a health care professional, or We are unable to
verify Your qualifications as notified to Us under these HP Terms.<br></br> <br></br>
17.5. We may suspend Your Services immediately pending Your cure of any breach of these HP
Terms, or in the event We determine in Our sole discretion that access to or use of the
System by You may jeopardize the System or the confidentiality, privacy, security, integrity
or availability of information within the System, or that You have violated or may violate
these HP Terms or Other Terms, or has jeopardized or may jeopardize the rights of any third
party, or that any person is or may be making unauthorized use of the System with any User

ID assigned to You. Our election to suspend the Services shall not waive or affect Our rights
to terminate these HP Terms as applicable to You as permitted under these HP Terms.<br></br> <br></br> 
17.6. Upon termination, You will cease to use the System and We will terminate Your access to
the System. Upon termination for any reason, You will remove all software provided under
HP Terms from Your computer systems, You will cease to have access to the System, and
You will return to Us all hardware, software and documentation provided by or on behalf of
Us.</p>
</div>
<div className="tt__secondpara">
                 <h5 style={{display:"flex",alignItems:"start"}}>18. General</h5>
                 
               
                  <p>18.1. Force Majeure - RxOne will not be deemed in default hereunder or held responsible for any
cessation, interruption or delay in the performance of its obligations hereunder due to
earthquake, flood, fire, storm, natural disaster, pandemic, act of God, war, terrorism, armed
conflict, labor strike, lockout, or boycott.<br></br> <br></br> 
18.2. Cessation of Operation - RxOne may at any time, in its sole discretion and without advance
notice to You, cease operation of the Platform and distribution of the Services<br></br> <br></br> 
18.3. Entire Agreement - This Agreement comprises the entire agreement between You and
RxOne and supersedes any prior agreements pertaining to the subject matter contained
herein.<br></br> <br></br> 
18.4. Effect of Waiver - The failure of RxOne to exercise or enforce any right or provision of this
Agreement will not constitute a waiver of such right or provision. If any provision of this
Agreement is found by a court of competent jurisdiction to be invalid, the parties
nevertheless agree that the court should endeavor to give effect to the parties&#39; intentions as
reflected in the provision, and the other provisions of this Agreement remain in full force
and effect.<br></br> <br></br> 
18.5. Governing Law; Jurisdiction - This Agreement will be governed by the laws of the NCT of
Delhi, India without regard to its conflict of law principles to the contrary. Neither You nor
RxOne will commence or prosecute any suit, proceeding or claim to enforce the provisions
of this Agreement, to recover damages for breach of or default of this Agreement, or
otherwise arising under or by reason of this Agreement, other than in courts located in NCT
of Delhi, India. By using this Platform or ordering Services, You consent to the jurisdiction
and venue of such courts in connection with any action, suit, proceeding or claim arising
under or by reason of this Agreement. You hereby waive any right to trial by any other court
arising out of this Agreement and any related documents.<br></br> <br></br> 
18.6. Statute of Limitation - You agree that regardless of any statute or law to the contrary, any
claim or cause of action arising out of or related to use of the Platform or Services or this
Agreement must be filed within one (1) month after such claim or cause of action arose or
be forever barred.<br></br> <br></br> 
18.7. Waiver of Class Action Rights - By entering into this Agreement, You hereby irrevocably
waive any right You may have to join claims with those of other in the form of a class action

or similar procedural device. Any claims arising out of, relating to, or connection with this
Agreement must be asserted individually.<br></br> <br></br> 
18.8. Domestic Use- RxOne makes no representation that the Platform or Services are
appropriate or available for use in locations outside India. Users who access the Website
from outside India do so at their own risk and initiative and must bear all responsibility for
compliance with any applicable local laws.<br></br> <br></br> 
18.9. Assignment- You may not assign Your rights and obligations under this Agreement to
anyone. RxOne may assign its rights and obligations under this Agreement in its sole
discretion and without advance notice to You.</p>
</div>
{/* 
                     <li><strong> CONDITIONS OF USE </strong>
                     <br />
                     <br />
                     <p>You must be 18 years of age or older to register, use the Services, or visit or use the platform in any manner. By registering, visiting and using the platform or accepting this Agreement, you represent and warrant to the Rx One Care Pvt Ltd that you are 18 years of age or older, and that you have the right, authority and capacity to use the platform and the Services available through the platform, and agree to and abide by this Agreement.</p>
                     <br />
                     <p><strong>Terms and conditions for tele-consultation</strong></p>
                     <br />

                     
                     

                     
                     
                     <li><strong> LICENCE FOR PLATFORM ACCESS</strong>
                     <br />
                     <br />
                     <p>Rx One Care Pvt Ltd grants you a licence to use the platform, but not to download or modify it, or any portion of it, except with express written consent of Rx One Care Pvt Ltd and / or its affiliates, as may be applicable. This licence does not include any resale or commercial use of this platform or its contents; any collection and use of any service listings, descriptions, or prices; any derivative use of this platform or its contents; any downloading or copying of account information for the benefit of another seller; or any use of data mining, robots, or similar data gathering and extraction tools.</p>
                     <br />
                     <p>This platform or content of it (including but not limited to any copyrighted material, trademarks, or other proprietary information) may not be reproduced, duplicated, copied, sold, resold, visited, distributed or otherwise exploited for any commercial purpose without express written consent of Rx One Care Pvt Ltd and / or its affiliates, as applicable.</p>
                     <br />
                     <p>You cannot frame or use framing techniques to enclose any trademark, logo, or other proprietary information (including images, text, page layout, or form) of Rx One Care Pvt Ltd and its affiliates without express written consent. You may not use any Meta tags or any other "hidden text" utilising Rx One Care Pvt Ltd or its affiliates' names or trademarks without the express written consent of Rx One Care Pvt Ltd and / or its affiliates, as applicable. Any unauthorized use terminates the permission or license granted by Rx One Care Pvt Ltd and / or its affiliates, as applicable.</p>
                     <br />
                     <p>You are granted a limited, revocable, and non-exclusive right to create a hyperlink to the Welcome page of www.rxone.app  or www.rxonecare.com  as long as the link does not portray Rx One Care Pvt Ltd, their affiliates, or their Services or services in a false, misleading, derogatory, or otherwise offensive matter. You may not use any Rx One logo or other proprietary graphic or trademark as part of the link without express written consent of Rx One Care Pvt Ltd and / or its affiliates, as may be applicable.</p>
                     <br />
                     <p>Rx One Care Pvt Ltd reserves the right to charge the usage fee or download fee of platform any time as it deems fit.</p>
                     <br />
                     </li>
                     <li><strong>COMMUNICATIONS </strong>
                     <br />
                     <br />
                     <p>When you use the platform or communicate to us via emails or share us the data, information, you consent to receive communications via electronic records from us periodically whenever required. We may communicate with you by email or by such other mode of communication, electronic or otherwise.</p>
                     <br />
                     </li>



                     <li><strong> TERMS AND CONDITIONS</strong>
                     <br />
                     <br />
                     <p>The terms and conditions provided here govern the use of this platform, including its all online services, for e.g. to book and cancel appointments, e-prescription (prescription made by doctor using Rx Digital), get medicine reminders, use of information, articles , news, blogs and text, graphics, images and information obtained from service providers and any other material contained on the platform. These terms and conditions are compliant with IT (intermediaries guidelines) Rules, 2011 passed under IT Act, 2000.</p>
                     <br />
                     <p>Rx One Care Pvt Ltd is only a technical facilitator that provides a platform to access the information on healthcare, prevention, symptoms and cure of common diseases and ailments and directory of healthcare service providers, merchant directory of vendors of medical equipment and other supplies. It is a software to book appointment with health care service providers registered on the platform. It allows a registered service provider /vendor to publish on the platform its contact information its locations, key doctor's profile, main departments, advertise their services, health packages, upload photos, audios, videos, events, seminar information, and fee schedule and timings for doctor consultations with its user id and password allocated at time of registration. Rx One Care Pvt Ltd cannot assure nor guarantee the ability or intent of the Practitioner(s) to fulfill their obligations towards you. We advise you to perform your own investigation prior to selecting a Practitioner.</p>
                     <br />
                     <p>Rx One Care Pvt Ltd collects the information regarding the Practitioner's profiles, contact details, and practice. Rx One Care Pvt Ltd reserves the right to make any amendments in any Practitioner's profile as well as the right to display the profile of the Practitioners, with or without notice to the concerned Practitioner. This information is collected for the purpose of facilitating interaction with the End-Users and other Users. If any information displayed on the platform in connection with you and your profile is found to be incorrect, you are required to inform Rx One Care Pvt Ltd immediately.</p>
                     <br />
                     <p>Registered Service providers/Vendors would be solely responsible for any medical, legal or financial consequences or implications arising out of use of the platform, including any Online Services, or Materials. Rx One Care Pvt Ltd accepts no responsibility of the same. It disclaims any warranties express or implied connected with use of the platform including its quality, availability or accuracy.</p>
                     <br />
                     <p>Rx One Care Pvt Ltd reserves the right to moderate the suggestions made by the Practitioners through feedback and the right to remove any abusive or inappropriate or promotional content added on the platform. However, Rx One Care Pvt Ltd shall not be liable if any inactive, inaccurate, fraudulent, or non- existent profiles of Practitioners are added to the platform</p>
                     <br />
                     <p>Rx One Care Pvt Ltd accepts no liability for any contravention of applicable law as a result of use of these Services. Rx One Care Pvt Ltd shall not be responsible for any breach of service or service deficiency by any Practitioner.</p>
                     <br />
                     <p><strong> ON PART OF PRACTITIONER, RX DIGITAL LABS PVT LTD SHALL NOT BE RESPONSIBLE FOR</strong></p>
                     <ol  className='list__gap'>
                         <li>Any information disclosed by the Practitioners in the platform regarding their personal profile or Clinic address or addresses displayed in the platform.</li>
                         <li>If the Practitioner invites other doctors to join his clinic.</li>
                         <li>The schedules and appointments defined including holidays.</li>
                         <li>Accepting walk in patients as well as pre booked appointments.</li>
                         <li> The content in the e-prescriptions stored in the system, its view & use by the patient and doctor both.</li>  
                         <li>If the Doctor re-edit the e-prescriptions later.</li>  
                         <li>The records maintained in the system.</li>  
                         <li>The invitation/ request made by the doctors to his/her patients</li>  
                         <li>Providing on-demand e-consultancy or Broadcast messages on the platform to the clients.</li>  
                         <li>For prescribing pre-defined alerts to patients and setting recipients as patients contact no.</li>  
                         <li>For referring/sharing the case to his colleagues to discuss the treatment plan.</li>  
                         <li>For displaying/managing multiple clinics with one or more doctors.</li>  
                         <li>Any wrong medication or treatment given by practitioners or any medical negligence on part of practitioners.</li>  
                         <li>Any type of inconvenience suffered by user due to a failure on the part of practitioners.</li>
                         <li>Any misconduct or inappropriate behaviour by the practitioner or his/her staff.</li>
                         <li>Cancellation or rescheduling of booked appointment or any variance in the fees charged.</li>
                         <li>Any medical eventualities that might occur subsequent to using the services of a practitioner, whom the user has selected on the basis of the information available on the platform or with whom the user has booked an appointment through the platform.</li>
                         <li> The ranking of the Practitioners on external websites and search engines.</li>
                         <li>Any losses, damage, injuries or expenses incurred by the Users as a result of any disclosures or publications made by Rx One Care Pvt Ltd.</li>

                     </ol>
                     <br />
                     <p><strong>ON PART OF PATIENTS, RX DIGITAL LABS PVT LTD SHALL NOT BE RESPONSIBLE FOR</strong></p>
                     <ol  className='list__gap'>
                         <li>The information disclosed in the platform regarding their personal information or information regarding their family members.</li>
                         <li> The bookings made by the patients for his/her own or for others.</li>
                         <li>E-consulting made by the patients for self and others.</li>
                         <li>The medical records maintained in the system in audio, video or documents mode and its use, view and print.</li>
                         <li>If Clients receive/edit/modify the alerts for medicines, tests, next appointments and symptoms tracking.</li>
                         <li>If client delete or add medicine names and consequence of that revised alerts are generated as changed.</li>
                         <li>Result of any test, as recorded in the platform.</li>
                         <li>Body vitals' records like BP, Sugar levels etc.</li>
                         <li>The result/information displayed upon search & view of Pharmacy, Doctor, Hospital, Blood Bank, Ambulance and Govt. Facilities like Hospital, Janaushadhi Kendra & PHC/CHC or News articles on Healthcare topics.</li>
                         <li>The outcome of search of doctors or appointments, appointment details, follow up, broadcast messages by doctors, e-consulting history etc.</li>
                     </ol>
                     </li>
                     <li><strong> PAYMENT AND CANCELLATION POLICY</strong>
                     <br />
                     <br />
                     <p>In the event, unavailability of the doctor at the time appointment, the patient can (a) cancel the current appointment or (b) consult another doctor with clinic's/hospital's consent. Cancellation of the appointment by the patient must be done at least 3 hours prior to the time booked for appointment. In such an event, the entire amount will be refunded to the patient within 1 (one) week from the date of cancellation as per the mode of refund proposed by the patient. If the booking confirmation gets delayed due to technical reasons or as a result of incorrect e-mail ID provided by the user etc, an appointment would be considered as 'booked'. If the patient does not show up at the time of appointment, Rx One Care Pvt Ltd will have right to forfeit refund the entire amount made by the patient. Rx One Care Pvt Ltd shall not be liable for any refunds to the customer in the event the customer's booked slot/ time for an appointment has been delayed.</p>
                     <br />
                     <p>Any grievances and claims related to the appointment/ refund should be reported to Rx One Care Pvt Ltd support team support@rxone.app  within two (2) days of appointment date with the doctor.</p>
                     <br />
                     <p>Further, Rx One Care Pvt Ltd shall not be liable, under any event, for any comments or feedback given by any of the Users in relation to the services provided by another user. All such feedback should be made in accordance with applicable law. The option of Users to give feedback remains at Rx One Care Pvt Ltd's sole discretion and may be modified or withdrawn at its sole discretion. Rx One Care Pvt Ltd shall not be obliged to act in any manner to give effect to the content of Users' feedback, such as suggestions for delisting of a particular Practitioner from the Website.</p>
                     <br />
                     <p>We Only have cancellation mechanism right now in the system, no payments provision is built now.</p>
                     </li>
                     <li><strong>  NO GUARANTEE ON QUALIFICATIONS/INFORMATION; NOT FOR EMERGENCY USE</strong>
                     <br />
                     <p>Please note that some of the content, text, data, graphics, images, information, suggestions, guidance, and other material (collectively, "Information" that may be available on the platform (including information provided in direct response to your questions or postings) may be provided by individuals in the medical profession. The provision of such Information does not create a licensed medical professional/patient relationship, between Rx One Care Pvt Ltd and you and does not constitute an opinion, medical advice, or diagnosis or treatment of any particular condition, but is only provided to assist you with locating appropriate medical care from a qualified practitioner.</p>
                     <br />
                     <p>It is hereby expressly clarified that, the Information that you obtain or receive from Rx One Care Pvt Ltd, and its employees, contractors, partners, sponsors, advertisers, licensors or otherwise on the platform is for informational purposes only. Rx One Care Pvt Ltd makes no guarantees, representations or warranties, whether expressed or implied, with respect to professional qualifications, quality of work, expertise or other information provided on the platform. In no event Rx One Care Pvt Ltd shall we be liable to you or anyone else for any decision made or action taken by you in reliance on such information.</p>
                     <br />
                     <p>The Services are not intended to be a substitute for getting in touch with emergency healthcare. If you are an End-User facing a medical emergency (either on your or a another person's behalf), please contact an ambulance service or hospital directly.</p>

                     </li>
                     <li><strong>TERMS FOR PRACTITIONERS:</strong>
                     <br />
                     <br />
                     <p> The Practitioner shall reply to the patient after receiving patient's communication. In case of non-compliance to the applicable laws/rules/regulations/guidelines by the Practitioner, Rx One Care Pvt Ltd reserves the right to show other available Practitioners to the patient or remove Practitioners from the platform/Rx One Care Pvt Ltd application/site.</p>
                     <br />
                     <p>The Practitioner shall be aware that, Rx One Care Pvt Ltd shall at its sole discretion, at any time be entitled to, show other Practitioners available for consultation.</p>
                     <br />
                     <p>The Practitioner understands that, there is a responsibility on the Practitioner to treat the patients on this model, as the Practitioner would have otherwise treated the patient on a physical one-on-one consultation model.</p>
                     <br />
                     <p>- The Practitioner shall have the discretion to cancel any consultation at any point in time where the Practitioner feels, his/her expertise or his/her capacity is insufficient to treat the patient. In such cases, patient shall have the option of choosing other Practitioners.</p>
                     <br />
                     <p>The Practitioner shall at all times follow all the applicable laws that govern the Practitioner.</p>
                     <br />
                     <p> The Practitioner acknowledges that if Rx One Care Pvt Ltd finds the Practitioner to be in violation of any of the applicable laws/rules/ regulations/guidelines set out by the authorities then Rx One Care Pvt Ltd shall be entitled to cancel the consultation with such patient or take such other legal action as may be required.</p>
                     <br />
                     <p> It is further understood by the Practitioner that the information that is disclosed by the patient at the time of consultation, shall be confidential in nature and subject to patient and Practitioner privilege.</p>
                     <br />
                     <p>The Practitioner shall ensure patient's consent is obtained prior to uploading the e-prescription/health records of the patient on the account of the patient during such consultation.</p>
                     <br />
                     <p>In case of violation of above-mentioned terms by Practitioner or their associates, the Practitioner or their associates shall be solely liable and responsible and Rx One Care Pvt Ltd shall not be responsible for the same in any way.</p>
                     <br />
                     
                     </li> */}


{/* 
                     <li><strong> TERMS FOR PATIENTS:</strong>
                     <br />
                     <br />
                     <p>The User hereby grants consent to Rx One Care Pvt Ltd and agrees that any queries or information provided by the User will be subject to Rx One Care Pvt Ltd Privacy Policy.</p>
                     <br />
                     <p>Rx One Care Pvt Ltd Consultation is intended for general purposes only and is not to be used in emergencies/serious illnesses requiring physical consultation. Further, if the Practitioner adjudges that a physical examination would be required and advises "in-person consultation" it is the sole responsibility of the patient, to book an appointment for physical examination and in-person consultation whether the same is with the Practitioner listed hereunder or otherwise. In case of any negligence on the part of the patient in acting on the same and the condition of the patient deteriorates, Rx One Care Pvt Ltd shall not be held liable.</p>
                     <br />
                     <p>The user understands and agrees that Rx One Care Pvt Ltd, is merely a consulting model, any interactions and associated issues with the Practitioner on Rx One Care Pvt Ltd including but not limited to the User's health issues and/or the User's experiences is strictly between the User and the Practitioner. The User shall not hold Rx One Care Pvt Ltd responsible for any such interactions and associated issues.</p>
                     <br />
                     <p> The user understands that, Rx One Care Pvt Ltd is not a medical service provider, nor is it involved in providing any healthcare or medical advice or diagnosis, it shall not in any case be responsible for any outcome from the consultation between the User and the Practitioner.</p>
                     <br />
                     <p>The user understands that, conversations with the Practitioner may be retained in our database, for legal and/or administrative requirements for future use.</p>
                     <br />
                     <p> If you decide to engage with a Practitioner to procure medical services to you, you shall do so at your own risk. Rx One Care Pvt Ltd shall not be responsible for any breach of service or service deficiency by any Practitioner.</p>
                     <br />
                     </li>
                     <li><strong> PROFILE OWNERSHIP AND EDITING RIGHTS</strong>
                     <br />
                     <br />
                     <p>Rx One Care Pvt Ltd ensures easy access to the Practitioners and Patients by providing a tool to update your profile information. Rx One Care Pvt Ltd reserves the right of ownership of all the Practitioner's & Patient's profile and photographs and to moderate the changes or updates requested by Practitioners and Patients. Rx One Care Pvt Ltd is on sole discretion to publish or reject the requests submitted for the respective changes or updates. You shall be authorized under law to upload all content uploaded by you as part of your profile or otherwise while using Rx One Care Pvt Ltd's services, and that no such content breaches any third party rights, including intellectual property rights.</p>
                     <br />
                     <p>On breach of the foregoing representation, Rx One Care Pvt Ltd can modify or delete parts of your profile information at its sole discretion with or without notice to you.</p>
                     <br />
                     </li>
                     <li><strong> USE OF THE PLATFORM</strong>
                     <br />
                     <br />
                     <p>You agree, undertake and confirm that your use of platform shall be strictly governed by the following binding principles:</p>
                     <br />
                     <ol  className='list__gap' >
                         <li> You shall not host, display, upload, modify, publish, transmit, update or share any information which
                         <br />
                         <ol type="a"  className='list__gap'>
                            <li> belongs to another person and to which You does not have any right to;</li> 
                            <li>is grossly harmful, harassing, blasphemous, defamatory, obscene, pornographic, paedophilic, libellous, invasive of another's privacy, hateful, or racially, ethnically objectionable, disparaging, relating or encouraging money laundering or gambling, or otherwise unlawful in any manner whatever; or unlawfully threatening or unlawfully harassing including but not limited to "indecent representation of women" within the meaning of the Indecent Representation of Women (Prohibition) Act, 1986;</li> 
                            <li>is misleading in any way;</li> 
                            <li>is patently offensive to the online community, such as sexually explicit content, or content that promotes obscenity, pedophilia, racism, bigotry, hatred or physical harm of any kind against any group or individual;</li> 
                            <li>harasses or advocates harassment of another person;</li>
                            <li>involves the transmission of "junk mail", "chain letters", or unsolicited mass mailing or "spamming";</li>
                            <li>promotes illegal activities or conduct that is abusive, threatening, obscene, defamatory or libellous;</li>
                            <li> infringes upon or violates any third party's rights [including, but not limited to, intellectual property rights, rights of privacy (including without limitation unauthorized disclosure of a person's name, email address, physical address or phone number) or rights of publicity];</li>
                            <li> contains restricted or password-only access pages, or hidden pages or images (those not linked to or from another accessible page);</li>
                            <li> provides material that exploits people in a sexual, violent or otherwise inappropriate manner or solicits personal information from anyone;</li>
                            <li>provides instructional information about illegal activities such as making or buying illegal weapons, violating someone's privacy, or providing or creating computer viruses;</li>
                            <li>contains video, photographs, or images of another person (with a minor or an adult)</li>
                            <li>tries to gain unauthorized access or exceeds the scope of authorized access to the Website or to profiles, blogs, communities, account information, bulletins, friend request, or other areas of the Website or solicits passwords or personal identifying information for commercial or unlawful purposes from other users;</li>
                            <li> engages in commercial activities and/or sales without Our prior written consent such as contests, sweepstakes, barter, advertising and pyramid schemes, or the buying or selling of "virtual" Services related to the Website. Throughout this Terms of Use, Rx One Care Pvt Ltd's prior written consent means a communication coming from Rx One Care Pvt Ltd's Legal Department, specifically in response to your request, and specifically addressing the activity or conduct for which You seek authorization;</li>
                            <li> solicits gambling or engages in any gambling activity which We, in Our sole discretion, believes is or could be construed as being illegal;</li>
                            <li> interferes with another USER's use and enjoyment of the Website or any other individual's User and enjoyment of similar services;</li>
                            <li>refers to any website or URL that, in Our sole discretion, contains material that is inappropriate for the Website or any other website, contains content that would be prohibited or violates the letter or spirit of these Terms of Use.</li>
                            <li> harm minors in any way;</li>
                            <li>infringes any patent, trademark, copyright or other proprietary rights or third party's trade secrets or rights of publicity or privacy or shall not be fraudulent or involve the sale of counterfeit or stolen Services;</li>
                            <li>violates any law for the time being in force;</li>
                            <li>deceives or misleads the addressee/ users about the origin of such messages or communicates any information which is grossly offensive or menacing in nature;</li>
                            <li>impersonate another person;</li>
                            <li>contains software viruses or any other computer code, files or programs designed to interrupt, destroy or limit the functionality of any computer resource; or contains any trojan horses, worms, time bombs, cancelbots, easter eggs or other computer programming routines that may damage, detrimentally interfere with, diminish value of, surreptitiously intercept or expropriate any system, data or personal information;</li>
                            <li> threatens the unity, integrity, defence, security or sovereignty of India, friendly relations with foreign states, or public order or causes incitement to the commission of any cognizable offence or prevents investigation of any offence or is insulting any other nation.</li>
                            <li> shall not be false, inaccurate or misleading;</li>
                            <li>shall not, directly or indirectly, offer, attempt to offer, trade or attempt to trade in any item, the dealing of which is prohibited or restricted in any manner under the provisions of any applicable law, rule, regulation or guideline for the time being in force.</li>
                            <li> shall not create liability for Us or cause Us to lose (in whole or in part) the services of Our internet service provider ("ISPs") or other suppliers</li>
                         </ol>
                         <br />
                         </li>
                         <li>You shall not use any "deep-link", "page-scrape", "robot", "spider" or other automatic device, program, algorithm or methodology, or any similar or equivalent manual process, to access, acquire, copy or monitor any portion of the platform or any Content, or in any way reproduce or circumvent the navigational structure or presentation of the platform or any Content, to obtain or attempt to obtain any materials, documents or information through any means not purposely made available through the platform. We reserve our right to bar any such activity.</li>
                         <li>You shall not attempt to gain unauthorized access to any portion or feature of the platform, or any other systems or networks connected to the platform or to any server, computer, network, or to any of the services offered on or through the platform by hacking, password "mining" or any other illegitimate means.</li>
                         <li>You shall not probe, scan or test the vulnerability of the platform or any network connected to the platform nor breach the security or authentication measures on the platform or any network connected to the platform. You may not reverse look-up, trace or seek to trace any information on any other User of or visitor to platform, or any other customer, including any account on the platform not owned by You, to its source, or exploit the platform or any service or information made available or offered by or through the platform, in any way where the purpose is to reveal any information, including but not limited to personal identification or information, other than Your own information, as provided for by the platform.</li>
                         <li>You shall not make any negative, denigrating or defamatory statement(s) or comment(s) about Us or the brand name or domain name used by Us including the terms or otherwise engage in any conduct or action that might tarnish the image or reputation of any users of Rx One Care Pvt Ltd or sellers on platform or otherwise tarnish or dilute any trade or service marks, trade name and/or goodwill associated with such trade or service marks, trade name as may be owned or used by us. You agree that you will not take any action that imposes an unreasonable or disproportionately large load on the infrastructure of the platform or systems or networks, or any systems or networks connected to platform.</li>
                         <li>You agree not to use any device, software or routine to interfere or attempt to interfere with the proper working of the platform or any transaction being conducted on the platform, or with any other person's use of the platform.</li>
                         <li>You may not forge headers or otherwise manipulate identifiers in order to disguise the origin of any message or transmittal you send to us on or through the platform or any service offered on or through the platform. You may not pretend that you are, or that You represent, someone else, or impersonate any other individual or entity.</li>
                         <li> You may not use the platform or any content for any purpose that is unlawful or prohibited by these Terms of Use, or to solicit the performance of any illegal activity or other activity which infringes the rights of Rx One Care Pvt Ltd, its users and / or others.</li>
                         <li>You shall at all times ensure full compliance with the applicable provisions of the Information Technology Act, 2000 and rules thereunder as applicable and as amended from time to time and also all applicable Domestic laws, rules and regulations (including the provisions of any applicable Exchange Control Laws or Regulations in Force) and International Laws, Foreign Exchange Laws, Statutes, Ordinances and Regulations regarding Your use of Our service and Your listing, purchase, solicitation of offers to purchase, and sale of Services or services. You shall not engage in any transaction in an item or service, which is prohibited by the provisions of any applicable law including exchange control laws or regulations for the time being in force.</li>
                         <li>10. Solely to enable Us to use the information You supply Us with, so that we are not violating any rights You might have in Your Information, You agree to grant us an exclusive, worldwide, perpetual, irrevocable, royalty-free, sub-licensable (through multiple tiers) right to exercise the copyright, publicity, database rights or any other rights You have in Your Information, in any media now known or not currently known, with respect to Your Information. We will only use your information in accordance with the Terms of Use and Privacy Policy applicable to use of the platform.</li>
                         <li>From time to time, you shall be responsible for providing information relating to the Services or services proposed to be sold by you. In this connection, you undertake that all such information shall be accurate in all respects. You shall not exaggerate or over emphasize the attributes of such Services or services so as to mislead other Users in any manner.</li>
                         <li><p>You shall not engage in advertising to, or solicitation of, other Users of the platform to buy or sell any Services or services, including, but not limited to, Services or services related to that being displayed on the platform or related to us. You may not transmit any chain letters or unsolicited commercial or junk mail to other Users via the platform. It shall be a violation of these Terms of Use to use any information obtained from the platform in order to harass, abuse, or harm another person, or in order to contact, advertise to, solicit, or sell to another person other than Us without Our prior explicit consent. In order to protect Our Users from such advertising or solicitation, We reserve the right to restrict the number of messages or emails which a user may send to other Users in any 24-hour period which We deem appropriate in its sole discretion. You understand that we have the right at all times to disclose any information (including the identity of the persons providing information or materials on the platform) as necessary to satisfy any law, regulation or valid governmental request. This may include, without limitation, disclosure of the information in connection with investigation of alleged illegal activity or solicitation of illegal activity or in response to a lawful court order or subpoena. In addition, We can (and You hereby expressly authorize Us to) disclose any information about You to law enforcement or other government officials, as we, in Our sole discretion, believe necessary or appropriate in connection with the investigation and/or resolution of possible crimes, especially those that may involve personal injury.</p>
                         <br />
                         <p>We reserve the right, but have no obligation, to monitor the materials posted on the platform. Rx One Care Pvt Ltd shall have the right to remove or edit any content that in its sole discretion violates, or is alleged to violate, any applicable law or either the spirit or letter of these Terms of Use.</p>
                         <br />
                         <p>Notwithstanding this right, YOU REMAIN SOLELY RESPONSIBLE FOR THE CONTENT OF THE MATERIALS YOU POST ON THE PLATFORM AND IN YOUR PRIVATE MESSAGES. PLEASE BE ADVISED THAT SUCH CONTENT POSTED DOES NOT NECESSARILY REFLECT RX DIGITAL LABS PVT LTD'S VIEWS. IN NO EVENT SHALL RX DIGITAL LABS PVT LTD ASSUME OR HAVE ANY RESPONSIBILITY OR LIABILITY FOR ANY CONTENT POSTED OR FOR ANY CLAIMS, DAMAGES OR LOSSES RESULTING FROM USE OF CONTENT AND/OR APPEARANCE OF CONTENT ON THE PLATFORM.</p>
                         </li>
                         <li> Your correspondence or business dealings with, or participation in promotions of, advertisers found on or through the Platform, including payment and delivery of related Services or services, and any other terms, conditions, warranties or representations associated with such dealings, are solely between You and such advertiser. We shall not be responsible or liable for any loss or damage of any sort incurred as the result of any such dealings or as the result of the presence of such advertisers on the Platform.</li>
                         <li>It is possible that other users (including unauthorized users or "hackers") may post or transmit offensive or obscene materials on the Platform and that You may be involuntarily exposed to such offensive and obscene materials. It also is possible for others to obtain personal information about you due to your use of the Platform, and that the recipient may use such information to harass or injure you. We do not approve of such unauthorized uses, but by using the Platform, you acknowledge and agree that we are not responsible for the use of any personal information that You publicly disclose or share with others on the Platform. Please carefully select the type of information that You publicly disclose or share with others on the Platform.</li>
                         <li> Rx One Care Pvt Ltd shall have all the rights to take necessary action and claim damages that may occur due to your involvement/participation in any way on your owner through group/s of people, intentionally or unintentionally in DoS/DDoS (Distributed Denial of Services).</li>
                     </ol>
                     <br />
                     </li>
                     <li><strong> SERVICES</strong>
                     <br />
                     <br />
                     <p>Terms of Offer. This Platform offers certain Services (the "Services"). By accepting an offer for Services through this Platform, you agree to the terms set forth in this Agreement.</p>
                     <br />
                     </li>
                     <li><strong>  CUSTOMER SOLICITATION</strong>
                     <br />
                     <p>Unless you notify of your desire to opt out from further direct Rx One Care Pvt Ltd communications and solicitations, you are agreeing to continue to receive further emails and call solicitations Rx One Care Pvt Ltd and its designated in house or third party call team(s).</p>
                     <br />
                     </li>
                     <li><strong>OPT OUT PROCEDURE</strong>
                     <br />
                     <br />
                     <p>We provide 3 easy ways to opt out of from future solicitations.</p>
                     <br />
                     <ol>
                         <li>You may use the opt out link found in any email solicitation that you may receive.</li>
                         <li> You may also choose to opt out, via sending your email address to: support@rxone.app</li>
                     </ol>
                     <br />
                     </li>
                     <li><strong> PROPRIETARY RIGHTS.</strong>
                     <br />
                     <br />
                     <p>Rx One Care Pvt Ltd has proprietary rights in the Services. You may not copy, reproduce, resell or redistribute any services by Rx One Care Pvt Ltd. Rx One Care Pvt Ltd also has rights to all trademarks and trade dress and specific layouts of this webpage, including calls to action, text placement, images and other information.</p>
                     <br />
                     </li>
                     <li><strong> DISCLAIMER OF WARRANTIES</strong>
                     <br />
                     <br />
                     <p>Your use of this Platform and/or services are at your sole risk. The Platform and services are offered on an "as is" and "as available" basis. Rx One Care Pvt Ltd expressly disclaims all warranties of any kind, whether express or implied, including, but not limited to, implied warranties of merchantability, fitness for a particular purpose and non-infringement with respect to the services or Platform content, or any reliance upon or use of the Platform content or services. ("services" include trial services.)</p>
                     <br />
                     <p>Without limiting the generality of the foregoing, Rx One Care Pvt Ltd makes no warranty:</p>
                     <br />
                     <ul>
                         <li>That the information provided on this Platform is accurate, reliable, complete, or timely.</li>
                         <li>That the links to third-party websites are to information that is accurate, reliable, complete, or timely.</li>
                         <li>No advice or information, whether oral or written, obtained by you from this Platform will create any warranty not expressly stated herein.</li>
                         <li>As to the results that may be obtained from the use of the services or that defects in services will be corrected.</li>
                         <li> Regarding any services purchased or obtained through the Platform.</li>
                         <li>Some jurisdictions do not allow the exclusion of CERTAIN WARRANTIES, SO SOME OF THE ABOVE EXCLUSIONS MAY NOT APPLY TO YOU.</li>
                     </ul>
                     <br />
                     </li>
                     <li><strong> LIMITATION OF LIABILITY</strong>
                     <br />
                     <br />
                     <p>ENTIRE LIABILITY, AND YOUR EXCLUSIVE REMEDY, IN LAW, IN EQUITY, OR OTHERWISE, WITH RESPECT TO THE PLATFORM CONTENT AND SERVICES AND/OR FOR ANY BREACH OF THIS AGREEMENT IS SOLELY LIMITED TO THE AMOUNT YOU PAID, FOR SERVICES PURCHASED VIA THE PLATFORM.</p>
                     <br />
                     <p>RX DIGITAL LABS PVT LTD WILL NOT BE LIABLE FOR ANY DIRECT, INDIRECT, INCIDENTAL, SPECIAL OR CONSEQUENTIAL DAMAGES IN CONNECTION WITH THIS AGREEMENT OR THE SERVICES IN ANY MANNER, INCLUDING LIABILITIES RESULTING FROM.</p>
                     <br />
                     <ol>
                         <li> THE USE OR THE INABILITY TO USE THE PLATFORM CONTENT OR SERVICES;</li>
                         <li> THE COST OF PROCURING SUBSTITUTE SERVICES OR CONTENT;</li>
                         <li>ANY SERVICES PURCHASED OR OBTAINED OR TRANSACTIONS ENTERED INTO THROUGH THE PLATFORM; OR</li>
                         <li>ANY LOST PROFITS YOU ALLEGE.SOME JURISDICTIONS DO NOT ALLOW THE LIMITATION OR EXCLUSION OF LIABILITY FOR INCIDENTAL OR CONSEQUENTIAL DAMAGES SO SOME OF THE ABOVE LIMITATIONS MAY NOT APPLY TO YOU.</li>
                     </ol>
                     <br />
                     <p>This (software service provider) and any applicable Service Levels do not apply to any performance or availability issues:</p>
                     <br />
                     <ol>
                         <li>Due to factors outside our reasonable control (for example, natural disaster, war, acts of terrorism, riots, government action, or a network or device failure external to our data centers, including at your site or between your site and our data center);</li>
                         <li>That result from the use of services, hardware, or software not provided by us, including, but not limited to, issues resulting from inadequate bandwidth or related to third-party software or services;</li>
                         <li>Caused by your use of a Service after we advised you to modify your use of the Service, if you did not modify your use as advised;</li>
                         <li> During or with respect to preview, pre-release, beta or trial versions of a Service, feature or software (as determined by us) or to purchases made using Microsoft subscription credits;</li>
                         <li>That result from your unauthorized action or lack of action when required, or from your employees, agents, contractors, or vendors, or anyone gaining access to our network by means of your passwords or equipment, or otherwise resulting from your failure to follow appropriate security practices;</li>
                         <li>That result from your failure to adhere to any required configurations, use supported platforms, follow any policies for acceptable use, or your use of the Service in a manner inconsistent with the features and functionality of the Service (for example, attempts to perform operations that are not supported) or inconsistent with our published guidance;</li>
                         <li>That result from faulty input, instructions, or arguments (for example, requests to access files that do not exist);</li>
                         <li>That result from your attempts to perform operations that exceed prescribed quotas or that resulted from our throttling of suspected abusive behavior;</li>
                         <li> Due to your use of Service features that are outside of associated Support Windows; or</li>
                         <li> For licenses reserved, but not paid for, at the time of the Incident.</li>
                     </ol>
                     <br />
                     </li>
                     <li><strong>  INDEMNIFICATION</strong>
                     <br />
                     <br />
                     <p>You will release, indemnify, defend and hold harmless Rx One Care Pvt Ltd and any of its contractors, agents, employees, officers, directors, shareholders, affiliates and assigns from all liabilities, claims, damages, costs and expenses, including reasonable attorneys' fees and expenses, of third parties relating to or arising out of (1) this Agreement or the breach of your warranties, representations and obligations under this Agreement; (2) the Website content or your use of the Website content; (3) the Services or your use of the Services (including Trial Services); (4) any intellectual property or other proprietary right of any person or entity; (5) your violation of any provision of this Agreement; or (6) any information or data you supplied to Rx One Care Pvt Ltd When Rx One Care Pvt Ltd is threatened with suit or sued by a third party, Rx One Care Pvt Ltd may seek written assurances from you concerning your promise to indemnify Rx One Care Pvt Ltd; your failure to provide such assurances may be considered by Rx One Care Pvt Ltd to be a material breach of this Agreement. Rx One Care Pvt Ltd will have the right to participate in any defense by you of a third-party claim related to your use of any of the Website content or Services, with counsel of Rx One Care Pvt Ltd choice at its expense. Rx One Care Pvt Ltd will reasonably cooperate in any defense by you of a third-party claim at your request and expense. You will have sole responsibility to defend Rx One Care Pvt Ltd against any claim, but you must receive Rx One Care Pvt Ltd prior written consent regarding any related settlement. The terms of this provision will survive any termination or cancellation of this Agreement or your use of the platform or Services.</p>
                     <br />
                     </li>



                     <li><strong> PRIVACY</strong>
                     <br />
                     <br />
                     <p>We view protection of your privacy as a very important principle. We understand clearly that You and Your Personal Information is one of our most important assets. We store and process Your Information including any sensitive financial information collected (as defined under the Information Technology Act, 2000), if any, on computers that may be protected by physical as well as reasonable technological security measures and procedures in accordance with Information Technology Act 2000 and Rules there under.</p>
                     <br />
                     <p>We may disclose personal information to third parties. This disclosure may be required for us to provide you access to our Services, to comply with our legal obligations, to enforce our User Agreement, to facilitate our marketing and advertising activities, or to prevent, detect, mitigate, and investigate fraudulent or illegal activities related to our Services. We do not disclose your personal information to third parties for their marketing and advertising purposes without your explicit consent.</p>
                     <br />
                     <p>We may disclose personal information if required to do so by law or in the good faith belief that such disclosure is reasonably necessary to respond to subpoenas, court orders, or other legal process. We may disclose personal information to law enforcement offices, third party rights owners, or others in the good faith belief that such disclosure is reasonably necessary to: enforce our Terms or Privacy Policy; respond to claims that an advertisement, posting or other content violates the rights of a third party; or protect the rights, property or personal safety of our users or the general public.</p>
                     <br />
                     <p>We and our affiliates will share / sell some or all of your personal information with another business entity should we (or our assets) plan to merge with, or be acquired by that business entity, or re-organization, amalgamation, restructuring of business. Should such a transaction occur that other business entity (or the new combined entity) will be required to follow this privacy policy with respect to your personal information.</p>
                     <br />
                     </li>
                     <li><strong>INTELLECTUAL PROPERTY RIGHTS</strong>
                     <br />
                     <br />
                     <p>It is hereby specifically recorded that the copyright and other Intellectual Property on the platform is the sole and exclusive property of Rx One Care Pvt Ltd. All users of the platform represent and warrant that Rx One Care Pvt Ltd is authorized by such third parties to upload the content or information about any products or services on the platform for license of use to the end customers. Intellectual Property Rights for the purpose of this Terms of Use shall always mean and include copyrights whether registered or not, patents including rights of filing patents, trademarks, trade names, trade dresses, house marks, collective marks, associate marks and the right to register them, designs both industrial and layout, geographical indicators, moral rights, broadcasting rights, displaying rights, distribution rights, selling rights, abridged rights, translating rights, reproducing rights, performing rights, communicating rights, adapting rights, circulating rights, protected rights, joint rights, reciprocating rights, infringement rights. All those Intellectual Property rights arising as a result of domain names, internet or any other right available under applicable law shall vest in the domain of Rx One Care Pvt Ltd, as the owner of such domain name. The Parties hereto agree and confirm that no part of any Intellectual Property rights mentioned hereinabove is transferred in the name of User and any intellectual property rights arising as a result of these presents shall also be in the absolute ownership, possession and our control or control of its licensors, as the case may be.</p>
                     <br />
                     </li>
                     <li><strong> PARTNERS</strong>
                     <br />
                     <br />
                     <p>Rx One Care Pvt Ltd's esteemed Partners are doctors, clinic managers, and other medical practitioners, who are listed and have a profile on https://www.rxone.app  and comply with the Terms of Use of Rx One Care Pvt Ltd.</p>
                     <br />
                     </li>
                     <li><strong>  ONLINE APPOINTMENT BOOKING FACILITY</strong>
                     <br />
                     <br />
                     <p>Online Appointment Booking is a facility offered by https://care.rxone.app  to patients for booking appointments online with our partners. Patients can seamlessly book appointments 24x7 on https://www.care.rxone.app or by using the mobile App.</p>
                     <br />
                     </li>
                     <li><strong> OBJECTIVES OF ONLINE APPOINTMENT BOOKING FACILITY</strong>
                     <br />
                     <br />
                     <p>The three-fold objective of https://rxone.app is to ensure:</p>
                     <br />
                     <ul>
                         <li>Excellent experience for our patients by minimizing their waiting time.</li>
                         <li> Provide unified experience to patient wherever they go to seek care services</li>
                         <li>Excellent experience for our partners by maximizing their utilization of time.</li>
                     </ul>
                     <br />
                     </li>
                     <li><strong>  MANDATORY TERMS OF SERVICE FOR PARTNERS</strong>
                     <br />
                     <br />
                      <ul>
                          <li>Every doctor should honour all appointments booked via https://www.rxone.app </li>
                          <li>It is mandatory that the patient meets the same doctor with whom the appointment has been confirmed.</li>
                          <li>The doctor should communicate about any expected delay/cancellation at least 2 hours prior to the scheduled time of appointment.</li>
                          <li> Non-compliance to the above will lead to poor patient experience and may attract penalties from Rx One Care Pvt Ltd including, but not limited to, withdrawal of the "Doctor Portal" access and might have an adverse effect on the relevance score of the establishment. Rx One Care Pvt Ltd reserves the right to withdraw the “Doctor Portal” and “RxOne Admin Portal” .</li>
                      </ul>
                      <br />
                     </li>
                     <li><strong> MANDATORY TERMS OF SERVICE FOR PATIENTS</strong>
                     <br />
                     <br />
                     <p>"Book Appointment" button on https://www.care.rxone.app  is a privilege given to the Patient, Rx One Care Pvt Ltd reserves the right to withdraw this in case of repeated instances of "Patient No Show"</p>
                     <br />
                     </li>
                     <li><strong> PRIVILEGES FOR PROVIDERS/PARTNERS</strong>
                     <br />
                     <br />
                     <p>For complying with the terms of service on https://www.rxone.app the partners will be offered following privileges:</p>
                     <br />
                     <ul>
                         <li> RxOne Doctor’s Portal</li>
                         <li> RxOne Facility Admin Portal</li>
                     </ul>
                     <br />
                     </li>
                     <li><strong>  DISCLAIMER FOR PROVIDERS/PARTNERS</strong>
                     <br />
                     <br />
                     <p>Rx One Care Pvt Ltd provides the services on "as is" and "as available" basis. Rx One Care Pvt Ltd does not represent, warrant or guarantee that your access to or use of https://www.provider.rxone.app : (a) will be uninterrupted or error free; or (b)will result in any requests for appointments. Notwithstanding anything contained herein, Rx One Care Pvt Ltd disclaims all liability for any act or omission of you, any Patient or other third party.</p>
                     <br />
                     </li>
                     <li><strong> AGREEMENT TO BE BOUND</strong>
                     <br />
                     <br />
                     <p>By using this platform or ordering Services, you acknowledge that you have read and agree to be bound by this Agreement and all terms and conditions on this Website.</p>
                     <br />
                     </li>
                     <li><strong> GENERAL</strong>
                     <br />
                     <br />
                     <p><strong>Force Majeure- </strong>Rx One Care Pvt Ltd will not be deemed in default hereunder or held responsible for any cessation, interruption or delay in the performance of its obligations hereunder due to earthquake, flood, fire, storm, natural disaster, act of God, war, terrorism, armed conflict, labor strike, lockout, or boycott.</p>
                     <p><strong>Cessation of Operation- </strong>Rx One Care Pvt Ltd may at any time, in its sole discretion and without advance notice to you, cease operation of the platform and distribution of the Services</p>
                     <p><strong>Entire Agreement- </strong>This Agreement comprises the entire agreement between you and Rx One Care Pvt Ltd and supersedes any prior agreements pertaining to the subject matter contained herein.</p>
                     <p><strong>Use of Data- </strong> Rx One Care Pvt Ltd has a right to use the information related to users of the platform for research and development, scientific, sales, marketing analysis and study purposes. Rx One Care Pvt Ltd has right to use the data for commercial purposes however at the time of using the data for said purpose, Rx One Care Pvt Ltd shall not disclose the personal information of users of Platform.</p>
                     <p><strong>Effect of Waiver- </strong>The failure of Rx One Care Pvt Ltd to exercise or enforce any right or provision of this Agreement will not constitute a waiver of such right or provision. If any provision of this Agreement is found by a court of competent jurisdiction to be invalid, the parties nevertheless agree that the court should endeavor to give effect to the parties' intentions as reflected in the provision, and the other provisions of this Agreement remain in full force and effect.</p>
                     <p><strong>Governing Law; Jurisdiction- </strong>This Agreement will be governed by the laws of the State of Delhi, India without regard to its conflict of law principles to the contrary. Neither you nor Rx One Care Pvt Ltd will commence or prosecute any suit, proceeding or claim to enforce the provisions of this Agreement, to recover damages for breach of or default of this Agreement, or otherwise arising under or by reason of this Agreement, other than in courts located in State of Delhi, India. By using this platform or ordering Services, you consent to the jurisdiction and venue of such courts in connection with any action, suit, proceeding or claim arising under or by reason of this Agreement. You hereby waive any right to trial by any other court arising out of this Agreement and any related documents.</p>
                     <p><strong>Statute of Limitation- </strong>You agree that regardless of any statute or law to the contrary, any claim or cause of action arising out of or related to use of the platform or Services or this Agreement must be filed within one (1) month after such claim or cause of action arose or be forever barred.</p>
                     <p><strong>Waiver of Class Action Rights- </strong> BY ENTERING INTO THIS AGREEMENT, YOU HEREBY IRREVOCABLY WAIVE ANY RIGHT YOU MAY HAVE TO JOIN CLAIMS WITH THOSE OF OTHER IN THE FORM OF A CLASS ACTION OR SIMILAR PROCEDURAL DEVICE. ANY CLAIMS ARISING OUT OF, RELATING TO, OR CONNECTION WITH THIS AGREEMENT MUST BE ASSERTED INDIVIDUALLY.</p>
                     <p><strong>Termination. - </strong> Rx One Care Pvt Ltd reserves the right to terminate your access to the platform if it reasonably believes, in its sole discretion, that you have breached any of the terms and conditions of this Agreement. Following termination, you will not be permitted to use the platform and Rx One Care Pvt Ltd may, in its sole discretion and without advance notice to you, cancel any outstanding orders for Services</p>
                     <p><strong>Domestic Use- </strong> Rx One Care Pvt Ltd makes no representation that the platform or Services are appropriate or available for use in locations outside India. Users who access the Website from outside India do so at their own risk and initiative and must bear all responsibility for compliance with any applicable local laws.</p>
                     <p><strong>Assignment- </strong>You may not assign your rights and obligations under this Agreement to anyone. Rx One Care Pvt Ltd may assign its rights and obligations under this Agreement in its sole discretion and without advance notice to you.</p>
                     <br />
                     </li> */}
                     <div className="tt__last">
                         <h1 style={{fontWeight:"700",color:"#005C74"}}>CONTACT US</h1>
                         <p>Please contact us for any questions or comments (including all inquiries unrelated to copyright infringement) regarding this platform.</p>
                         <br />
                         <p>In accordance with Information Technology Act 2000 and rules made there under, you can write your grievances to support@rxone.app </p>
                         <br />
                         <p><strong>Address:</strong>  B-59,Second Floor, Ramdatt Enclave, Uttam Nagar, New Delhi, West Delhi, Delhi, India, 110059</p>
                         <br />
                         <p><strong>Contact No: </strong>:+91 7703934446</p>
                         <p><strong>Email: </strong>support@rxone.app</p>
                         <p><strong>Website: </strong> https://www.rxone.app </p>

                     </div>
                 
             
         </div>
      </>
  );
}

export default Terms;
