// import React, { useState, useEffect } from "react";
// import { NavLink, useNavigate, useParams } from "react-router-dom";
// import "../Css/Header.css";
// import header__logo from "../Images/logo3.png";
// import header_logo2 from '../Images/rxOne.png'; // Scrolled logo
// import MenuIcon from "@mui/icons-material/Menu";
// import CloseIcon from "@mui/icons-material/Close";
// import Login from "./user/Login";
// import ServiceCall from "../ServiceCall";

// function Header() {
//   const navigate = useNavigate();
//   const [islogin, setlogin] = useState(false);
//   const [isMobile, setMobile] = useState(false);
//   const [nav, isNav] = useState(false);
//   const [activeLogo, setActiveLogo] = useState(header__logo); // Default logo
//   const [specialties, setSpecialties] = useState([]); // Initialize specialties
//   const [showDropdown, setShowDropdown] = useState(false);
//   const [showDropdown1, setShowDropdown1] = useState(false);
//   const [showMobileProductsDropdown, setShowMobileProductsDropdown] = useState(false);
//   const [showMobileSolutionsDropdown, setShowMobileSolutionsDropdown] = useState(false);
//   const [showMobileAboutDropdown, setShowMobileAboutDropdown] = useState(false);
//   const [swap, setSwap] = useState("Book A Demo"); // Initialize swap

//   const [showAboutDropdown, setShowAboutDropdown] = useState(false); 
//   const transitionNav = () => {
//     if (window.scrollY > 100) {
//       isNav(true);
//       setActiveLogo(header_logo2); // Change logo when scrolled
//     } else {
//       isNav(false);
//       setActiveLogo(header__logo); // Default logo
//     }
//   };

//   useEffect(() => {
//     if (!islogin) {
//       document.body.style.overflowY = "scroll";
//     }
//     window.addEventListener("scroll", transitionNav);
//     return () => window.removeEventListener("scroll", transitionNav);
//   }, [islogin]);

//   useEffect(() => {
//     const fetchSpecialties = async () => {
//       try {
//         const response = await ServiceCall.getSpecialties(); // Replace with your service call
//         setSpecialties(response.data); // Update specialties with fetched data
//       } catch (error) {
//         console.error('Error fetching specialties:', error);
//       }
//     };

//     fetchSpecialties(); // Fetch specialties on component mount
//   }, []);

//   const toggleMobileMenu = () => {
//     setMobile(!isMobile);
//   };
//   const handleMobileLinkClick = () => {
//     setMobile(false); // Close mobile menu when any link is clicked
//   };
//   const toggleMobileProductsDropdown = () => {
//     setShowMobileProductsDropdown(prevState => !prevState);
//     setShowMobileSolutionsDropdown(false); // Close the other dropdown
//   };

//   const toggleMobileSolutionsDropdown = () => {
//     setShowMobileSolutionsDropdown(prevState => !prevState);
//     setShowMobileProductsDropdown(false); // Close the other dropdown
//   };
//   const toggleMobileAboutDropdown = () => {
//     setShowMobileAboutDropdown(prevState => !prevState);
    
//   };
  

//   return (
//     <>
//       <header
//         className={` header ${nav && "header__color"} ${
//           isMobile && "header__color"
//         } ${islogin && "login__container"}  ms-main-header`}
//       >
//         {islogin && <Login popup={setlogin} login={islogin} />}
//         <div className="container">
//           <div className="row">
//             <div className="col-md-12">
//               <nav className="navbar navbar-expand-lg px-0">
//                 {/* Logo */}
//                 <NavLink className="navbar-brand" to="/">
//                   <img src={activeLogo} alt="Logo" />
//                 </NavLink>
//                 {/* Navbar */}
//                 <div
//                   className="collapse navbar-collapse justify-content-end"
//                   id="hamburgernavmenucontent"
//                 >
//                   <ul className="navbar-nav align-items-lg-center">
//                     <li className="nav-item ms-line-slide">
//                       <a href="https://rxone.app/" className="nav-link" 
//                           style={{
//                             color: nav || isMobile ? "#000" : "#fff",
//                           }}>
//                         For Patients
//                       </a>
//                     </li>
//                     <li>
//                       <div
//                         className="dropdown-menu-v2"
//                         onMouseEnter={() => setShowDropdown(true)}
//                         onMouseLeave={() => setShowDropdown(false)}
//                         style={{ position: "relative" }}
//                       >
//                         <div
//                           className="dropdown-toggle"
//                           style={{
//                             fontSize: "17px",
//                             fontWeight: "600",
//                             marginRight: "1.3rem",
//                             cursor: "pointer",
//                            color: nav || isMobile ? "#000" : "#fff"
//                           }}
//                         >
//                           Products
//                         </div>
//                         {showDropdown && (
//                           <ul
//                             className="dropdown-list"
//                             style={{
//                               position: "absolute",
//                               top: "100%",
//                               left: 0,
//                               backgroundColor: "#fff",
//                               listStyle: "none",
//                               padding: "10px 0",
//                               boxShadow: "0px 8px 16px rgba(0, 0, 0, 0.1)",
//                               borderRadius: "8px",
//                               zIndex: 100,
//                             }}
//                           >
//                             <li className="dropdown-item" style={{ padding: "10px 20px" }}>
//                               <NavLink
//                                 to="/next-gen-ai-powered-emr"
//                                 style={{ textDecoration: "none", color: "#000" }}
//                                 activeStyle={{ color: "#007bff" }}
//                               >
//                                 EMR
//                               </NavLink>
//                             </li>
//                             <li className="dropdown-item" style={{ padding: "10px 20px" }}>
//                               <NavLink
//                                 to="/healthcare-iot-with-edge"
//                                 style={{ textDecoration: "none", color: "#000" }}
//                                 activeStyle={{ color: "#007bff" }}
//                               >
//                                 EDGE
//                               </NavLink>
//                             </li>
//                             <li className="dropdown-item" style={{ padding: "10px 20px" }}>
//                               <NavLink
//                                 to="/unified-health-exchange"
//                                 style={{ textDecoration: "none", color: "#000" }}
//                                 activeStyle={{ color: "#007bff" }}
//                               >
//                                 UHX
//                               </NavLink>
//                             </li>
//                             <li className="dropdown-item" style={{ padding: "10px 20px" }}>
//                               <NavLink
//                                 to="/digital-with-market360"
//                                 style={{ textDecoration: "none", color: "#000" }}
//                                 activeStyle={{ color: "#007bff" }}
//                               >
//                                 Market 360*
//                               </NavLink>
//                             </li>
//                           </ul>
//                         )}
//                       </div>
//                     </li>
//                     <li>
//                       <div
//                         className="dropdown-menu-v2"
//                         onMouseEnter={() => setShowDropdown1(true)}
//                         onMouseLeave={() => setShowDropdown1(false)}
//                         style={{ position: "relative" }}
//                       >
//                         <div
//                           className="dropdown-toggle"
//                           style={{
//                             fontSize: "17px",
//                             fontWeight: "600",
//                             marginRight: "10px",
//                             cursor: "pointer",
//                             color: nav || isMobile ? "#000" : "#fff",
//                           }}
//                         >
//                           Solutions
//                         </div>
//                         {showDropdown1 && (
//                           <ul
//                             className="dropdown-list"
//                             style={{
//                               position: "absolute",
//                               top: "100%",
//                               left: 0,
//                               backgroundColor: "#fff",
//                               listStyle: "none",
//                               padding: "10px 0",
//                               boxShadow: "0px 8px 16px rgba(0, 0, 0, 0.1)",
//                               borderRadius: "8px",
//                               zIndex: 100,
//                             }}
//                           >
//                             <li className="dropdown-item" style={{ padding: "10px 20px" }}>
//                               <NavLink to="/specialized-emr-for-gps">  For General Physician</NavLink>
//                             </li>
//                             <li className="dropdown-item" style={{ padding: "10px 20px" }}>
//                               <NavLink to="/specialized-cardiology-emr">For Cardiologists</NavLink>
//                             </li>
//                             <li className="dropdown-item" style={{ padding: "10px 20px" }}>
//                               <NavLink to="/specialized-dental-emr">For Dentists</NavLink>
//                             </li>
//                             <li className="dropdown-item" style={{ padding: "10px 20px" }}>
//                               <NavLink to="/specialized-pediatric-emr">For Pediatricians</NavLink>
//                             </li>
//                             <li className="dropdown-item" style={{ padding: "10px 20px" }}>
//                               <NavLink to="/medical-value-tourism-enablement">For Medical Value Tourism </NavLink>
//                             </li>
//                           </ul>
//                         )}
//                       </div>
//                     </li>
//                      {/* About Dropdown */}
//             <li>
//               <div
//                 className="dropdown-menu-v2"
//                 onMouseEnter={() => {
//                   setShowDropdown(false); // Close Products dropdown
//                   setShowDropdown1(false); // Close Solutions dropdown
//                   setShowAboutDropdown(true); // Open About dropdown
//                 }}
//                 onMouseLeave={() => setShowAboutDropdown(false)}
//                 style={{ position: "relative" }}
//               >
//                 <div className="dropdown-toggle"style={{
//                             fontSize: "17px",
//                             fontWeight: "600",
//                             marginRight: "1.3rem",
//                             cursor: "pointer",marginLeft:"10%",
                           
//                            color: nav || isMobile ? "#000" : "#fff"
//                           }}>About</div>
//                 {showAboutDropdown && (
//                      <ul
//                      className="dropdown-list"
//                      style={{
//                        position: "absolute",
//                        top: "100%",
//                        left: 0,
//                        backgroundColor: "#fff",
//                        listStyle: "none",
//                        padding: "10px 0",
//                        boxShadow: "0px 8px 16px rgba(0, 0, 0, 0.1)",
//                        borderRadius: "8px",
//                        zIndex: 100,
//                        marginBottom:"10%"
//                      }}
//                    >
//                  <li className="dropdown-item" style={{ marginBottom: "15px" }}>
//     <NavLink to="/blogs/:slug" style={{ padding: "10px 20px" }}>Blogs</NavLink>
// </li>
// <li className="dropdown-item" style={{ marginBottom: "10px" }}>
//     <NavLink to="/aboutus" style={{ padding: "10px 20px" }}>About Us</NavLink>
// </li>


                    
//                   </ul>
//                 )}
//               </div>
//             </li>
//                     {/* <li className="nav-item ms-line-slide" >
//                       <NavLink activeclassname="active" to="/aboutus" style={{color: nav || isMobile ? "#000" : "#fff"}}>
//                         About Us
//                       </NavLink>
//                     </li> */}
                    
//                     <li className="nav-item" >
//                       <a className="ms-secondary-btn ms-header-btn" href="https://calendly.com/careconsult_online">
//                         {swap}
//                       </a>
//                     </li>
//                   </ul>
//                 </div>
//                 {/* Mobile Menu */}
//               {/* Mobile Menu */}
// <div
//   className={`menu__toggle__content ${
//     !isMobile && "res__display"
//   }`}
// >
//   <ul>
//     <li style={{ marginLeft: "-1px" }}>
//       <a href="https://rxone.app/" className="nav-link" onClick={() => setMobile(false)}>
//         For Patients
//       </a>
//     </li>
//     <li>
//       <div
//         onClick={() => {
//           toggleMobileProductsDropdown();
//           setMobile(true); // Keep the menu open only for dropdown toggle
//         }}
//         className="dropdown-toggle"
//         style={{
//           fontSize: "17px",
//           fontWeight: "600",
//           marginRight: "20px",
//           marginLeft: "18px",
//           cursor: "pointer",
//         }}
//       >
//         Products
//       </div>
//       {showMobileProductsDropdown && (
//         <ul className="mobile-dropdown-list">
//           <li className="mobile-dropdown-item" onClick={() => setMobile(false)}>
//             <NavLink to="/next-gen-ai-powered-emr">
//               EMR
//             </NavLink>
//           </li>
//           <li className="mobile-dropdown-item" onClick={() => setMobile(false)}>
//             <NavLink to="/healthcare-iot-with-edge">
//               EDGE
//             </NavLink>
//           </li>
//           <li className="mobile-dropdown-item" onClick={() => setMobile(false)}>
//             <NavLink to="/unified-health-exchange">
//               UHX
//             </NavLink>
//           </li>
//           <li className="mobile-dropdown-item" onClick={() => setMobile(false)}>
//             <NavLink to="/digital-with-market360">
//               MARKET 360
//             </NavLink>
//           </li>
//         </ul>
//       )}
//     </li>
//     <li>
//       <div
//         onClick={() => {
//           toggleMobileSolutionsDropdown();
//           setMobile(true); // Keep the menu open only for dropdown toggle
//         }}
//         className="dropdown-toggle"
//         style={{
//           fontSize: "17px",
//           fontWeight: "600",
//           marginRight: "20px",
//           marginLeft: "18px",
//           cursor: "pointer",
//         }}
//       >
//         Solutions
//       </div>
//       {showMobileSolutionsDropdown && (
//         <ul className="mobile-dropdown-list">
//           <li className="mobile-dropdown-item" onClick={() => setMobile(false)}>
//             <NavLink to="/specialized-emr-for-gps">
//               For General Physician
//             </NavLink>
//           </li>
//           <li className="mobile-dropdown-item" onClick={() => setMobile(false)}>
//             <NavLink to="/specialized-cardiology-emr">
//               For Cardiologists
//             </NavLink>
//           </li>
//           <li className="mobile-dropdown-item" onClick={() => setMobile(false)}>
//             <NavLink to="/specialized-dental-emr">
//               For Dentists
//             </NavLink>
//           </li>
//           <li className="mobile-dropdown-item" onClick={() => setMobile(false)}>
//             <NavLink to="/specialized-pediatric-emr">
//               For Pediatricians
//             </NavLink>
//           </li>
//           <li className="mobile-dropdown-item" onClick={() => setMobile(false)}>
//             <NavLink to="/medical-value-tourism-enablement">
//               For Medical Value Tourism 
//             </NavLink>
//           </li>
         
//         </ul>
//       )}
//     </li>

//     <li>
//   <div
//     onClick={() => {
//       toggleMobileAboutDropdown();
//       setMobile(true); // Keep the menu open only for dropdown toggle
//     }}
//     className="dropdown-toggle"
//     style={{
//       fontSize: "17px",
//       fontWeight: "600",
//       marginRight: "20px",
//       marginLeft: "18px",
//       cursor: "pointer",
//     }}
//   >
//     About
//   </div>
//   {showMobileAboutDropdown && (
//     <ul className="mobile-dropdown-list">
//       <li className="mobile-dropdown-item" onClick={() => setMobile(false)}>
//         <NavLink to="/blogs/:slug">
//           Blogs
//         </NavLink>
//       </li>
//       <li className="mobile-dropdown-item" onClick={() => setMobile(false)}>
//         <NavLink to="/aboutus">
//           About Us
//         </NavLink>
//       </li>
    
//     </ul>
//   )}
// </li>

//     <li onClick={() => setMobile(false)}>
//       <a
//         style={{ padding: "6px 40px", fontSize: "14px" }}
//         className="ms-secondary-btn ms-header-btn"
//         href="https://calendly.com/careconsult_online"
//       >
//         {swap}
//       </a>
//     </li>
//   </ul>
// </div>

//                 <div
//                   className="menu_toggle"
//                   onClick={toggleMobileMenu}
//                 >
//                   {isMobile ? (
//                     <CloseIcon fontSize="medium" />
//                   ) : (
//                     <MenuIcon fontSize="medium" />
//                   )}
//                 </div>
//               </nav>
//             </div>
//           </div>
//         </div>
//       </header>
//     </>
//   );
// }

// export default Header;
import React, { useState, useEffect } from "react";
import { Link, NavLink, useNavigate, useParams } from "react-router-dom"; // Import useNavigate
import "../Css/Header.css";
import header__logo from "../Images/rxOne.png";
import header__logo1 from "../Images/logo3.png";
import MenuIcon from "@mui/icons-material/Menu";
import CloseIcon from "@mui/icons-material/Close";
import Login from "./user/Login";
import ServiceCall from "../ServiceCall";
import ArrowRightIcon from "@material-ui/icons/ArrowRight";
import { Margin } from "@mui/icons-material";

function Header() {
  const style = {
    // Adding media querry..
    "@media (max-width: 700px)": {
      display: "none",
    },
  };

  const navigate = useNavigate();

  const [islogin, setlogin] = useState(false);
  const [isMobile, setMobile] = useState(false);
  const { som } = useParams();

  const [nav, isNav] = useState(false);
  const [swap, setSwap] = useState("Book A Demo");
  const media = window.matchMedia("(max-width: 768px)").matches;
  const [specialties, setSpecialties] = useState([]);
  const [showDropdown, setShowDropdown] = useState(false);
  const [showDropdown2, setShowDropdown2] = useState(false);
  const [showDropdown3, setShowDropdown3] = useState(false);
  const [doctordata, setDoctordata] = useState("");
  const [selectedSpecialty, setSelectedSpecialty] = useState(null);
   const [showMobileProductsDropdown, setShowMobileProductsDropdown] = useState(false);
 const [showMobileSolutionsDropdown, setShowMobileSolutionsDropdown] = useState(false);
   const [showMobileAboutDropdown, setShowMobileAboutDropdown] = useState(false);
  // const [selectedDoctors, setSelectedDoctors] = useState([]);
  const [lookup, setlookup] = useState("");

  const transitionNav = () => {
    if (window.scrollY > 100) {
      isNav(true);
    } else {
      isNav(false);
    }
  };

  useEffect(() => {
    if (!islogin) {
      document.body.style.overflowY = "scroll";
    }
    window.addEventListener("scroll", transitionNav);
    return () => window.addEventListener("scroll", transitionNav);
  });

  useEffect(() => {
    const lookups = async () => {
      try {
        const lookupdata = await ServiceCall.get(
          "/v2/facility/som/open/lookup"
        );
        if (lookupdata) {
          setlookup(lookupdata.data.records);
        }
      } catch (err) {}
    };
    lookups();
  }, []);

  const filterDoctorsBySpecialty = (specialty) => {
    navigate(`/specialists/${specialty.code}`, {}); // Note the use of backticks ``
    // setShowDropdown(false);
  };
  const [showMobileDropdown, setShowMobileDropdown] = useState(false);
  const [showMobileDropdown2, setShowMobileDropdown2] = useState(false);
  const [showMobileDropdown3, setShowMobileDropdown3] = useState(false);

  // Modify the toggleDropdown function to handle mobile dropdown
  const toggleDropdown = (isMobile) => {
    if (isMobile) {
      // Toggle mobile dropdown visibility
      setShowMobileDropdown((prevState) => !prevState);
    } else {
      // Toggle desktop dropdown visibility
      setShowDropdown((prevState) => !prevState);
    }
  };

  const handleItemClick = () => {
    setShowDropdown(false);
  };

  const handleMobileItemClick = () => {
    setShowMobileDropdown(false);
    setMobile(false);
  };
  const toggleDropdown2 = (isMobile) => {
    if (isMobile) {
      // Toggle mobile dropdown visibility
      setShowMobileDropdown2((prevState) => !prevState);
    } else {
      // Toggle desktop dropdown visibility
      setShowDropdown((prevState) => !prevState);
    }
  };

  const handleItemClick2 = () => {
    setShowDropdown(false);
  };

  const handleMobileItemClick2 = () => {
    setShowMobileDropdown2(false);
    setMobile(false);
  };
  const toggleDropdown3 = (isMobile) => {
    if (isMobile) {
      // Toggle mobile dropdown visibility
      setShowMobileDropdown3((prevState) => !prevState);
    } else {
      // Toggle desktop dropdown visibility
      setShowDropdown((prevState) => !prevState);
    }
  };

  const handleItemClick3 = () => {
    setShowDropdown(false);
  };

  const handleMobileItemClick3 = () => {
    setShowMobileDropdown3(false);
    setMobile(false);
  };


  const isSpecialBackground = nav || isMobile;

  return (
    <>
      <header
        className={` header ${nav && "header__color"} ${
          isMobile && "header__color"
        } ${islogin && "login__container"}  ms-main-header`}
      >
        {islogin && <Login  />}
        <div className="container">
          <div className="row">
            <div className="col-md-12">
              <nav className="navbar navbar-expand-lg px-0">
                <Link className="navbar-brand" to="/">
                  <img
                    src={isSpecialBackground ? header__logo: header__logo1}
                    alt="Logo"
                  />
                </Link>
                {/* /*===== Navbar===== */}
                <div
                  className="collapse navbar-collapse justify-content-end"
                  id="hamburgernavmenucontent"
                >
                  <ul className="navbar-nav align-items-lg-center">
                    <li className="nav-item ms-line-slide">
                      <a href="https://rxone.app/" className="nav-link" 
                          style={{
                            color: nav || isMobile ? "#000" : "#fff", // White if the background is #018c92, otherwise black
                          }}>
                       For Patients
                      </a>
                    </li>

                    <li>
      <div
        className="dropdown-menu-v2"
        onMouseEnter={() => setShowDropdown(true)}
        onMouseLeave={() => setShowDropdown(false)}
        style={{ position: "relative" }}
      >
        <div
          className="dropdown-toggle"
          style={{
            fontSize: "17px",
            fontWeight: "600",
            marginRight: "1.3rem",
            cursor: "pointer",
            color: nav || isMobile ? "#000" : "#fff",
          }}
        >
         Products
        </div>
        {showDropdown && (
          <ul
            className="dropdown-list"
            style={{
              position: "absolute",
              top: "100%",
              left: 0,
              backgroundColor: "#fff",
              listStyle: "none",
              padding: "10px 0",
              boxShadow: "0px 8px 16px rgba(0, 0, 0, 0.1)",
              borderRadius: "8px",
              zIndex: 100,
            }}
          >
            <li
              className="dropdown-item"
              style={{ padding: "10px 20px" }}
              onClick={handleItemClick}
            >
              <NavLink
                to="/next-gen-ai-powered-emr"
                style={{
                  textDecoration: "none",
                  color: "#000",
                }}
                activeStyle={{ color: "#007bff" }}
              >
              EMR
              </NavLink>
            </li>
            <li
              className="dropdown-item"
              style={{ padding: "10px 20px" }}
              onClick={handleItemClick}
            >
              <NavLink
                to="/healthcare-iot-with-edge"
                style={{
                  textDecoration: "none",
                  color: "#000",
                }}
                activeStyle={{ color: "#007bff" }}
              >
                EDGE
              </NavLink>
            </li>
            <li
              className="dropdown-item"
              style={{ padding: "10px 20px" }}
              onClick={handleItemClick}
            >
              <NavLink
                to="/unified-health-exchange"
                style={{
                  textDecoration: "none",
                  color: "#000",
                }}
                activeStyle={{ color: "#007bff" }}
              >
                UHX
              </NavLink>
            </li>
            <li
              className="dropdown-item"
              style={{ padding: "10px 20px" }}
              onClick={handleItemClick}
            >
              <NavLink
                to="/digital-with-market360"
                style={{
                  textDecoration: "none",
                  color: "#000",
                }}
                activeStyle={{ color: "#007bff" }}
              >
              Market 360*
              </NavLink>
            </li>
          
          </ul>
        )}
      </div>
    </li>
    <li>
      <div
        className="dropdown-menu-v2"
        onMouseEnter={() => setShowDropdown2(true)}
        onMouseLeave={() => setShowDropdown2(false)}
        style={{ position: "relative" }}
      >
        <div
          className="dropdown-toggle"
          style={{
            fontSize: "17px",
            fontWeight: "600",
            marginRight: "1rem",
            cursor: "pointer",
            color: nav || isMobile ? "#000" : "#fff",

          }}
        >
          Solutions
        </div>
        {showDropdown2 && (
          <ul
            className="dropdown-list"
            style={{
              position: "absolute",
              top: "100%",
              left: 0,
              backgroundColor: "#fff",
              listStyle: "none",
              padding: "10px 0",
              boxShadow: "0px 8px 16px rgba(0, 0, 0, 0.1)",
              borderRadius: "8px",
              zIndex: 100,
            }}
          >
            <li
              className="dropdown-item"
              style={{ padding: "10px 20px" }}
              onClick={handleItemClick2}
            >
              <NavLink
                to="/specialized-emr-for-gps"
                style={{
                  textDecoration: "none",
                  color: "#000",
                }}
                activeStyle={{ color: "#007bff" }}
              >
               For General Physician
              </NavLink>
            </li>
            <li
              className="dropdown-item"
              style={{ padding: "10px 20px" }}
              onClick={handleItemClick2}
            >
              <NavLink
                to="/specialized-cardiology-emr"
                style={{
                  textDecoration: "none",
                  color: "#000",
                }}
                activeStyle={{ color: "#007bff" }}
              >
                For Cardiologists
              </NavLink>
            </li>
            <li
              className="dropdown-item"
              style={{ padding: "10px 20px" }}
              onClick={handleItemClick2}
            >
              <NavLink
                to="/specialized-dental-emr"
                style={{
                  textDecoration: "none",
                  color: "#000",
                }}
                activeStyle={{ color: "#007bff" }}
              >
                For Dentists
              </NavLink>
            </li>
            <li
              className="dropdown-item"
              style={{ padding: "10px 20px" }}
              onClick={handleItemClick2}
            >
              <NavLink
                to="/specialized-pediatric-emr"
                style={{
                  textDecoration: "none",
                  color: "#000",
                }}
                activeStyle={{ color: "#007bff" }}
              >
                For Pediatricians
              </NavLink>
            </li>
            <li
              className="dropdown-item"
              style={{ padding: "10px 20px" }}
              onClick={handleItemClick2}
            >
              <NavLink
                to="/medical-value-tourism-enablement"
                style={{
                  textDecoration: "none",
                  color: "#000",
                }}
                activeStyle={{ color: "#007bff" }}
              >
              For Medical Value Tourism 
              </NavLink>
            </li>
           
          </ul>
        )}
      </div>
    </li>
    <li className="nav-item ms-line-slide">
                      <NavLink activeclassname="active" to="/aboutus"  
                        style={{
                          color: nav || isMobile ? "#000" : "#fff", // White if the background is #018c92, otherwise black
                        }}
                      >
                        About Us
                      </NavLink>
                    </li>

    
           
          
                {/* <li className="nav-item ms-line-slide">
                      <NavLink activeclassname="active" to="/aboutus"  
                        style={{
                          color: nav || isMobile ? "#000" : "#fff", // White if the background is #018c92, otherwise black
                        }}
                      >
                        About Us
                      </NavLink>
                    </li>
                    <li className="nav-item ms-line-slide">
                      <NavLink activeclassname="active" to="/blogs"     style={{
                          color: nav || isMobile ? "#000" : "#fff", // White if the background is #018c92, otherwise black
                        }}> 
                        Blog
                      </NavLink>
                    </li> */}
                    {/* <li className="nav-item ms-line-slide">
                      <NavLink activeclassname="active" to="/providers">
                        For Providers
                      </NavLink>
                    </li> */}
                    <li className="nav-item " >
                      <a className="ms-secondary-btn ms-header-btn" href="https://calendly.com/careconsult_online">
                        Book A Demo
                      </a>
                    </li>
                  </ul>
                </div>
                {/* /*========= Mobile Version ========= */}
                <div
                  className={`menu_toggle_content ${
                    !isMobile && "res__display"
                  }`}
                >
                  <ul>
                  <li style={{marginLeft: "-17px"}}>
                      <a href="https://rxone.app/" className="nav-link">
                      For Patients
                      </a>
                    </li>
                    <li>
            <div
              onClick={() => toggleDropdown(true)}
              className="dropdown-toggle"
              style={{
                fontSize: "17px",
                fontWeight: "600",
                marginRight: "200px",
                // marginLeft: "18px",
                cursor: "pointer",
              }}
            >
             Products
            </div>
            {showMobileDropdown && (
              <ul className="mobile-dropdown-list">
               
                <li className="mobile-dropdown-item" onClick={handleMobileItemClick}>
                  <NavLink to="/next-gen-ai-powered-emr">
                  EMR
                  </NavLink>
                </li>
                <li className="mobile-dropdown-item" onClick={handleMobileItemClick}>
                  <NavLink to="/healthcare-iot-with-edge">
                  EDGE
                  </NavLink>
                </li>
                <li className="mobile-dropdown-item" onClick={handleMobileItemClick}>
                  <NavLink to="/unified-health-exchange">
                  UHX
                  </NavLink>
                </li>
                <li className="mobile-dropdown-item" onClick={handleMobileItemClick}>
                  <NavLink to="/digital-with-market360">
                  Market 360*
                  </NavLink>
                </li>
               
              </ul>
            )}
          </li>
          <li>
            <div
              onClick={() => toggleDropdown2(true)}
              className="dropdown-toggle"
              style={{
                fontSize: "17px",
                fontWeight: "600",
                marginRight: "200px",
                // marginLeft: "18px",
                cursor: "pointer",
              }}
            >
               Solutions
            </div>
            {showMobileDropdown2 && (
              <ul className="mobile-dropdown-list">
                <li className="mobile-dropdown-item" onClick={handleMobileItemClick2}>
                  <NavLink to="/specialized-emr-for-gps">
                  For General Physician
                  </NavLink>
                </li>
                <li className="mobile-dropdown-item" onClick={handleMobileItemClick2}>
                  <NavLink to="/specialized-cardiology-emr">
                  For Cardiologists
                  </NavLink>
                </li>
                <li className="mobile-dropdown-item" onClick={handleMobileItemClick2}>
                  <NavLink to="/specialized-dental-emr">
                  For Dentists
                  </NavLink>
                </li>
                <li className="mobile-dropdown-item" onClick={handleMobileItemClick2}>
                  <NavLink to="/specialized-pediatric-emr">
                  For Pediatricians
                  </NavLink>
                </li>
                <li className="mobile-dropdown-item" onClick={handleMobileItemClick2}>
                  <NavLink to="/medical-value-tourism-enablement">
                  For Medical Value Tourism 
                  </NavLink>
                </li>
               
              </ul>
            )}
          </li>
           <li onClick={handleMobileItemClick}style={{marginLeft:"-17px"}}>
                      <NavLink activeclassname="active" to="/aboutus">
                        About Us
                      </NavLink>
                    </li>

                    <li >
                      <a
                        style={{ padding: "6px 40px", fontSize: "14px",marginLeft:"-17px"}}
                        className="ms-secondary-btn ms-header-btn"
                        href="https://calendly.com/careconsult_online"
                      >
                        {swap}
                      </a>
                    </li>
                  </ul>
                </div>
                <div
                  className="menu_toggle"
                  onClick={() => setMobile(!isMobile)}
                >
                  {isMobile ? (
                    <CloseIcon fontSize="medium" />
                  ) : (
                    <MenuIcon fontSize="medium" />
                  )}
                </div>

              </nav>
            </div>
          </div>
        </div>
      </header>
    </>
  );
}

export default Header;