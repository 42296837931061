import React from 'react';
import '../Css/footer2.css'; // Import the CSS for styling
import logo from '../Images/logo3.png'; // Path for your logo
import Terms from './CompanyPage/Terms';
import { Link, NavLink, useNavigate, useParams } from "react-router-dom";
 // Importing social media icons

 
import FaTwitter from "@mui/icons-material/Twitter";
import FaFacebook from "@mui/icons-material/Facebook";
import FaInstagram  from "@mui/icons-material/Instagram";
import FaLinkedin from "@mui/icons-material/LinkedIn";
const Footer = () => {
  return (
    <footer className="footer-container">
      <div className="footer-content">
        {/* Left side logo */}
        <div className="footer-logo">
          <img src={logo} alt="Logo" />
        </div>

        {/* Center text */}
        <div className="footer-center-text">
          <p>Building Simple, Easy to Use and Effective Digital Health solutions for users, to solve care accessibility challenge in India and Worldwide.</p>
        </div>

        {/* Right side social media icons */}
        <div className="footer-social">
          <a href="https://www.facebook.com/profile.php?id=100089472039715" target="_blank" rel="noopener noreferrer">
            <FaFacebook />
          </a>
          <a href="https://twitter.com/RxOneCare"target="_blank" rel="noopener noreferrer">
            <FaTwitter />
          </a>
          <a href="https://www.instagram.com/rxone_care?igsh=bzdsdTBzNjB3aTVr" target="_blank" rel="noopener noreferrer">
            <FaInstagram />
          </a>
          <a href="https://www.linkedin.com/company/rx-one" target="_blank" rel="noopener noreferrer">
            <FaLinkedin />
          </a>
        </div>

        {/* Privacy Policy and Terms of Usage */}
        <div className="footer-links">
          <a href="https://rxone.app/privacypolicy">Privacy Policy</a>
          <NavLink activeclassname="active" to="/terms">
          Terms of Service
                      </NavLink>
        </div>
      </div>

      {/* Thin line and copyright section */}
      <div className="footer-copyright">
        <hr />
        <p>&copy; Rx One Care Pvt. Ltd.  </p>
      </div>
    </footer>
  );
};

export default Footer;