import React, { useEffect, useRef } from 'react';
import 'bootstrap/dist/css/bootstrap.min.css';
import '../Css/pediatrEmr.css'; // Import the CSS file for the product section
import '../Css/counter.css'; // Import the CSS file for the counter section
import serviceGif22 from '../Images/Picture27.png';
import serviceGif23 from '../Images/Picture28.png';
import serviceGif24 from '../Images/Picture29.png'; 
import phoneImg from '../Images/file.png'; // Path for the static phone image
import BeforeFooter from './BeforeFooter';
import { Helmet } from "react-helmet";
// Array of services for the UHX component
const services10= [
  {
    
    imgSrc: serviceGif22,
    heading: 'Smart Calendar',
    description: 'In 2023, approximately 14.5 million children worldwide missed out on essential vaccinations, becoming what are known as “zero-dose children.” RxOne is addressing this critical issue with its Smart Calendar feature, which allows specialists to set up automated reminders for vaccinations, treatments, and more. These reminders ensure that parents are notified in a timely manner, helping to guarantee that their children receive necessary vaccinations on schedule.',
  },
  {
    imgSrc: serviceGif23,
    heading: 'Smart Prescriptions',
    description: "Tired of creating prescription for every patient? RxOne here to help, save template prescriptions in advance, so that you don’t need to fill all the attributes for every patient. With RxOne, you get contextual autosuggest, advice schedule tables, vitals chart, multilingual prescription and such 9 different features. What's more, RxOne automatically creates reminders for all medications advised, so your patients never miss a dose.",
  },
  {
    imgSrc: serviceGif24,
    heading: 'Smart Engagement',
    description: 'Don’t want to use your personal phone number for OPD interactions? Not-to-worry, use RxOne’s Patient Messaging to interact with patients securely and only for the time you have allowed them to send messages. RxOne, also offers Smart QR Kiosk and Booking Widget, which allows patients to register, book appointment, payments and handles cancellations & refunds autonomously.',
  },
];

// Component to render UHX services in a structured layout
const AllServices10InRectangle = () => {
  return (
    <div className="service10-rectangle">
      <div className='racHead'><h3 className='racH'>Engage Users – Empower Patients – Enhance Experience</h3></div> 
      {services10.map((service10, index) => (
        <div className={`service-section ${index === 1 ? 'second-section' : ''}`} key={index}>
          <div className={`service10-img-container ${index % 2 !== 0 ? 'order-last' : ''}`}>
            <img src={service10.imgSrc} alt={service10.heading} className="img-fluid service10-img" />
          </div>
          <div className="service10-text">
            <h3 className="mb-3">{service10.heading}</h3>
            <p>{service10.description}</p>
            <a href="https://calendly.com/rxonecare" className="btn btn-light">Book A Demo</a>
          </div>
        </div>
      ))}
    </div>
  );
};

// Counter Component for UHX with 'cr' and 'k' units
const Counter12 = () => {
  const countersRef = useRef([]);

  useEffect(() => {
    countersRef.current.forEach((counter) => {
      const target = +counter.getAttribute('data-count');
      let count = 0;
      const increment = target / 200;
      const unit = '%'; // Use percentage for all counters

      const updateCount = () => {
        if (count < target) {
          count += increment;
          counter.innerHTML = Math.ceil(count) + '&nbsp;' + unit;
          setTimeout(updateCount, 10);
        } else {
          counter.innerHTML = target + '&nbsp;' + unit;
        }
      };

      updateCount();
    });
  }, []);

  return (
    <div className="Counter12-section">
      <div className="Counter12-box">
        <div className="Counter12" data-count="44" ref={(el) => (countersRef.current[0] = el)}>
          0%
        </div>
        <p className="Counter12-text">Time saved in OPD Consultations</p>
      </div>
      <div className="Counter12-box">
        <div className="Counter12" data-count="200" ref={(el) => (countersRef.current[1] = el)}>
          0%
        </div>
        <p className="Counter12-text">Increase in follow-ups</p>
      </div>
      <div className="Counter12-box">
        <div className="Counter12" data-count="85" ref={(el) => (countersRef.current[2] = el)}>
          0%
        </div>
        <p className="Counter12-text">Improvements in patient outcomes
        </p>
      </div>
      <div className="Counter12-box">
        <div className="Counter12" data-count="50" ref={(el) => (countersRef.current[3] = el)}>
          0%
        </div>
        <p className="Counter12-text">Operational costs reduction</p>
      </div>
    </div>
  );
};
// UHX Component combining services and counter
const PediatrEmr = () => {
  return (
    <div className="headLine">
      <Helmet>
        <title>RxOne: Smart Calendar & Prescription Solutions for Vaccination Reminders & Patient Engagement

        </title></Helmet>
        <Helmet> <meta name="description" content="RxOne is transforming healthcare with its Smart Calendar, addressing the global issue of missed vaccinations by sending timely, automated reminders to parents, ensuring children receive essential vaccines on schedule. For busy specialists, RxOne’s Smart Prescription feature saves time by offering customizable templates, contextual autosuggestions, multilingual options, and automatic medication reminders. Patients can securely communicate through RxOne’s Patient Messaging without using personal phone numbers, while the Smart QR Kiosk and Booking Widget handle appointments, payments, and cancellations autonomously. RxOne enhances workflow efficiency, engagement, and care continuity for both practitioners and patients." /></Helmet>
       <Helmet> <meta name="keywords" content="RxOne, smart calendar for vaccinations, automated vaccination reminders, zero-dose children solutions, smart prescriptions, prescription templates, automated medication reminders, secure patient messaging, healthcare appointment scheduling, QR code booking system, autonomous patient booking, multilingual prescriptions, vitals tracking healthcare" />
      </Helmet>
      {/* New Header Section */}
      <div className="uppHead5">
        <h1 className="UpTex5">Specialized Pediatric EMR        </h1>
      </div>
      
      {/* Wide Strip with Gradient Background */}
   

      <div style={{ background: "linear-gradient(45deg, #005C74, #018C92, #01A09E)", padding: "20px", marginTop: "-100px" }}>
        <AllServices10InRectangle />
        <Counter12 />
      </div>

      <div style={{ marginBottom: "-45px" }}>
        <BeforeFooter />
      </div>
    </div>
   
  );
};
export default PediatrEmr;
