import React, { useEffect, useRef, useState } from 'react';
import ScrollTrigger from 'react-scroll-trigger';
import CountUp from 'react-countup'; // Import CountUp
import '../Css/counter3.css';

const Counter3 = () => {
  const countersRef = useRef([]);
  const [triggered, setTriggered] = useState(false);

  useEffect(() => {
    if (triggered) {
      countersRef.current.forEach((counter) => {
        // Optionally add any side effects if needed when the counter starts
      });
    }
  }, [triggered]);

  return (
    <ScrollTrigger
      onEnter={() => setTriggered(true)}
      onExit={() => setTriggered(false)}
      throttleScroll={100}
      threshold={0.3} // Start when 30% of the element is visible
    >
      <div className="counters-section">
        <div className="counter-box" style={{ fontWeight: 'bold' }}>
          <div className="counter5" ref={(el) => (countersRef.current[0] = el)}>
            {triggered && (
              <CountUp
                start={0}
                end={1.5}
                duration={5} // Slow down the animation (5 seconds)
                decimals={1} // Show one decimal place
                suffix=" X"
              />
            )}
          </div>
          <p className="counter-text">Increase in doctor’s efficiency using RxOne</p>
        </div>
        <div className="counter-box" style={{ fontWeight: 'bold' }}>
          <div className="counter5" ref={(el) => (countersRef.current[1] = el)}>
            {triggered && (
              <CountUp
                start={0}
                end={2}
                duration={5} // Slow down the animation
                decimals={1}
                suffix=" X"
              />
            )}
          </div>
          <p className="counter-text">Follow-ups increase using RxOne</p>
        </div>
        <div className="counter-box" style={{ fontWeight: 'bold' }}>
          <div className="counter5" ref={(el) => (countersRef.current[2] = el)}>
            {triggered && (
              <CountUp
                start={0}
                end={3}
                duration={5} // Slow down the animation
                decimals={1}
                suffix=" X"
              />
            )}
          </div>
          <p className="counter-text">New revenue opportunities using RxOne</p>
        </div>
        <div className="counter-box" style={{ fontWeight: 'bold' }}>
          <div className="counter5" ref={(el) => (countersRef.current[3] = el)}>
            {triggered && (
              <CountUp
                start={0}
                end={1.8}
                duration={5} // Slow down the animation
                decimals={1}
                suffix=" X"
              />
            )}
          </div>
          <p className="counter-text">Improvement in care outcomes using RxOne</p>
        </div>
      </div>
    </ScrollTrigger>
  );
};

export default Counter3;
