import { useState, useEffect, useRef } from "react";
import backgroundimg from "../Images/banner-bg.jpg";
import emailjs from "emailjs-com";
import { countriesList } from "../utility/constants";
import { styled, useMediaQuery } from "@material-ui/core";
import { Helmet } from "react-helmet";
import CenteredAlert from "./CenteredAlert";
import CircularProgress from "@material-ui/core/CircularProgress";
import WhatsAppIcon from "@mui/icons-material/WhatsApp";
import whatsapp from "../Images/whatsapp.png";


import {
  TextField,
  Button,
  Container,
  makeStyles,
  FormControl,
  InputLabel,
  InputAdornment,
  Input,
  MenuItem,
  FormHelperText,
  Select,
  Grid,
} from "@material-ui/core";

const useStyles = makeStyles((theme) => ({
  root: {
    backgroundColor: "#fff",
  },
  menuList: {
    width: "200px",
  },

  menuItem: {
    backgroundColor: "#fff",
    width: "100%",
  },

  button: {
    margin: theme.spacing(2, 0),
    backgroundColor: "#199a8e",
    "&:hover": {
      // Remove hover effect
      backgroundColor: "#199a8e",
    },
  },
  salutationFormControl: {
    width: "27%", // Adjust the width of the salutation select
  },
  gender: {
    width: "30%",
  },
  nameContainer: {
    display: "flex",
    justifyContent: "space-between",
    width: "100%",
  },
  nameField: {
    background: "white",
  },
  select: {
    border: "none", // Remove border
    width: "50px", // Adjust the width as needed
  },
}));

const Patientregis = () => {
  const classes = useStyles();
  const [loader, setLoader] = useState(false);
  const [phoneError, setPhoneError] = useState(false);
  const [phoneCode, setPhoneCode] = useState("+91");
  const[countryname, setCountryname] = useState("India")
  const [message, setMessage] = useState("");
  const [name, setName] = useState("");
  const [age, setAge] = useState("");
  const [email, setEmail] = useState("");
  const [phone, setPhone] = useState("");
  const [gender, setGender] = useState("");
  const [country, setCountry] = useState("India")
  const [city, setCity] = useState("");
  const isMobile = useMediaQuery("(max-width:710px)");
  const [showAlert, setShowAlert] = useState(false);
  const [loading, setLoading] = useState(false); // State for managing loading state

  const isValidPhoneNumber = (phoneNumber) => {
    const numericPhoneNumber = phoneNumber.replace(/\D/g, "");
    return numericPhoneNumber.length === 10 && /^\d+$/.test(numericPhoneNumber);
  };
  const showAlerts = () => {
    setShowAlert(true);
    resetForm();
  };

  const resetForm = () => {
    setName("");
    setEmail("");
    setMessage("");
    setAge("");
    setGender("");
    setPhone("");
    setCountry("");
    setCity("");
  };

  const handlesubmit = (e) => {
    setLoading(true);
    e.preventDefault();
    const serviceid = "service_7yxtqsn";
    const templateid = "template_x3wusd7";
    const publickey = "4UpyJJj3P5JGeMZFz";
    const messageContent = `
    Patient Name: ${name}
    Age: ${age}
    Gender: ${gender}
    Phone Number: ${phoneCode}${phone}
    Email: ${email}
    Country: ${country}
    City: ${city}
    Chief Complaints: ${message}
  `;

    const templateparams = {
      from_name: name,
      from_email: email,
      to_name: "web",
      message: messageContent,
    };

    emailjs
      .send(serviceid, templateid, templateparams, publickey)
      .then((response) => {
        setLoading(false);
        console.log("email set successfully", response);
        showAlerts();
      })
      .catch((error) => {
        setLoading(false);
        console.log("error sending email", error);
      });
  };

  const titlle =
    "FREE Treatment Advice - Consult for Medical Treatment Advice - Allopathy, Homeopathy, Ayurveda, Unani, Sowa-Rigpa, Siddha, Yoga, Reki, Naturopathy, Meditation, Physiotherapy, Mental Health | RxOne";
  const desc =
    "Get free treatment advice and consult for medical treatments including Allopathy, Homeopathy, Ayurveda, Unani, Sowa-Rigpa, Siddha, Yoga, Reki, Naturopathy, Meditation, Physiotherapy, and Mental Health at RxOne.";
  return (
    <>
      <Helmet>{<title>{titlle}</title>}</Helmet>
      <Helmet>{<meta name="description" content={desc} />}</Helmet>

      <div className="patient">
        <img
          src={backgroundimg}
          style={{
            width: "100%",
            height: "300px",
            position: "absolute",
            top: 0,
            left: 0,
            zIndex: -1,
            backgroundRepeat: "no-repeat",
            backgroundPosition: "center",
            backgroundSize: "cover",
          }}
          alt="Background"
        />
        <div
          style={{
            padding: "20px",
            display: "flex",
            alignItems: "center",
            justifyContent: "center",
            flexDirection: isMobile ? "column" : "row", // Change flex direction based on screen size
            marginTop: isMobile ? "-77px" : "0",
          }}
        >
          <div
            style={{
              maxWidth: isMobile ? "100%" : "45%", // Set maxWidth to 100% for mobile
              // textAlign: "center", // Center align text on mobile
              marginTop: isMobile ? "0" : "58px", // Remove negative margin on mobile
            }}
          >
            <h1
              className="ms-font-weight-600 mb-4 ms-primary-color"
              style={{
                fontSize: "40px",
                marginTop: isMobile ? "96px" : "-398px",
              }}
            >
              Discover Care Path
            </h1>
            <h2
              className="ms-font-weight-600 mb-4 ms-primary-color"
              style={{
                fontSize: "30px",
                marginTop: isMobile ? "46px" : "40px",
              }}
            >
              Couldn’t decide about the right treatment for you?
            </h2>
            <div className=" mb-3">
              <p
                className=" fw-normal"
                style={{ color: "gray", fontWeight: "bold", fontSize: "21px" }}
              >
                Allopathy, Homeopathy, Ayurveda, Unani – many treatment types,
                but which one for you?
              </p>
              <span
                className=" fw-normal"
                style={{ color: "#199a8e", fontSize: "21px" }}
              >
                Consult with RxOne Care Advisor today to find which treatment
                suits your condition.
              </span>
            </div>
            <h6 style={{ color: "red", fontWeight: "600", fontSize: "21px" }}>
              LIMITED TIME OFFER!
            </h6>
            <p style={{ fontWeight: "bold", color: "gray", fontSize: "21px" }}>
              Consultation Fee:{" "}
              <span style={{ textDecoration: "line-through" }}>₹ 500</span>{" "}
              <span style={{ color: "green" }}> FREE</span>
            </p>
          </div>
          <div className="whatsapp-block">
            <a
              href="https://api.whatsapp.com/send?phone=+917703934446&text=Hello%20RxOne,%20Could%20you%20please%20call%20back."
              target="_blank"
              rel="noopener noreferrer"
            >
              <img src={whatsapp} alt="whtsp" />
            </a>{" "}
          </div>
          <div style={{ float: "inline-end" }}>
            <Container component="main" maxWidth="xs">
              <div
                className="provider__revolutionize__item"
                style={{
                  backgroundColor: "#fff",
                  width: "100%",
                  borderRadius: "55px",
                  boxShadow: "rgba(0, 0, 0, 0.5) 0px 8px 16px",
                }}
              >
                <h4
                  className="ms-font-weight-600 mb-4 ms-primary-color"
                  style={{ textAlign: "center" }}
                >
                  Get call from Care Advisor
                </h4>
                <form style={{ textAlign: "center" }} onSubmit={handlesubmit}>
                  <TextField
                    id="firstName"
                    name="first_name"
                    label="Patient Name"
                    variant="filled"
                    style={{ marginBottom: "20px", backgroundColor: "white" }} // Add backgroundColor
                    required
                    value={name}
                    onChange={(e) => setName(e.target.value)}
                    autoFocus
                    fullWidth
                    className={classes.nameField}
                    InputProps={{
                      style: {
                        backgroundColor: "#fff", // Set the background color here
                      },
                      inputProps: {
                        style: { marginTop: "7px", marginBottom: "-3px" }, // Add padding to the left side of the input text
                      },
                    }}
                  />
                  <TextField
                    id="age"
                    name="age"
                    label="Patient Age"
                    variant="filled"
                    value={age}
                    onChange={(e) => setAge(e.target.value)}
                    style={{ marginBottom: "20px", backgroundColor: "white" }}
                    fullWidth
                    className={classes.nameField}
                    onKeyPress={(e) => {
                      // Allow only digits (0-9)
                      const allowedChars = /^[0-9]*$/;
                      if (!allowedChars.test(e.key)) {
                        e.preventDefault();
                      }
                    }}
                    inputProps={{
                      maxLength: 3, // Optional: Limits the maximum length to 3 digits
                    }}
                    InputProps={{
                      style: {
                        backgroundColor: "#fff",marginTop: "7px", marginBottom: "-3px" // Set the background color here
                    
                    }}}
                  />
                  <FormControl
                    variant="filled"
                    style={{ marginBottom: "20px" }}
                    fullWidth
                    className={classes.nameField}
                    classes={{ root: classes.root }} // Apply the custom styles
                  >
                    <InputLabel id="gender">Gender</InputLabel>
                    <Select
                      labelId="gender"
                      id="gender"
                      name="gender"
                      value={gender}
                      onChange={(e) => setGender(e.target.value)}
                      classes={{ root: classes.root }} // Apply the custom styles
                    >
                      <MenuItem value="male">Male</MenuItem>
                      <MenuItem value="female">Female</MenuItem>
                      <MenuItem value="other">Other</MenuItem>
                    </Select>
                  </FormControl>

                  <TextField
                    multiline
                    maxRows={4}
                    id="chiefComplaints"
                    name="message"
                    label="Describe current medical problem"
                    variant="filled"
                    value={message}
                    onChange={(e) => setMessage(e.target.value)}
                    required
                    fullWidth
                    style={{ marginBottom: "20px", backgroundColor: "white" }} // Add backgroundColor
                    className={classes.nameField}
                    InputProps={{
                      style: {
                        backgroundColor: "#fff", // Set the background color here
                      },
                      inputProps: {
                        style: { marginTop: "7px", marginBottom: "-3px" }, // Add padding to the left side of the input text
                      },
                    }}
                  />
                  <FormControl
                    variant="filled"
                    fullWidth
                    className={classes.nameField}
                  >
                    <div style={{ display: "flex", alignItems: "center" }}>
                      <Select
                        labelId="demo-simple-select-label"
                        id="demo-simple-select"
                        className="w-23"
                        value={phoneCode} // Concatenate phoneCode and phone here
                        classes={{ root: classes.root }}
                        style={{ marginTop: "16px" }}
                      >
                        {countriesList &&
                          countriesList.map((item, index) => (
                            <MenuItem
                              value={item.code}
                              key={item.code + "-" + index}
                              classes={{ root: classes.menuItem }} // Apply the custom styles to the MenuItem
                            >
                              {item.code}
                            </MenuItem>
                          ))}
                      </Select>

                      <InputLabel
                        htmlFor="phoneNumber"
                        style={{ marginLeft: "101px" }}
                      >
                        Phone Number*
                      </InputLabel>
                      <Input
                        id="phoneNumber"
                        name="phone"
                        label="Phone Number"
                        required
                        value={phone}
                        onChange={(e) => {
                          const value = e.target.value;
                          const allowedChars = /^[0-9]*$/; // Allow only digits

                          if (allowedChars.test(value)) {
                            setPhone(value.slice(0, 10)); // Limit the input to 10 digits
                            setPhoneError(false); // Reset the error state
                          } else {
                            setPhoneError(true); // Set the error state if non-digit characters are entered
                          }
                        }}
                        error={phoneError}
                        style={{ flex: 1, marginLeft: 6, width: "100px" }}
                        className={classes.nameField}
                        InputProps={{
                          style: {
                            backgroundColor: "#fff",
                          },
                        }}
                      />
                    </div>
                    {phoneError && (
                      <FormHelperText error>
                        Please enter a valid phone number.
                      </FormHelperText>
                    )}
                  </FormControl>
                  <TextField
                    id="email"
                    name="email"
                    label="Email"
                    variant="filled"
                    type="email"
                    value={email}
                    onChange={(e) => setEmail(e.target.value)}
                    fullWidth
                    required
                    style={{ marginBottom: "20px", backgroundColor: "white" }}
                    className={classes.nameField}
                    inputProps={{
                      pattern: /[a-z0-9._%+-]+@[a-z0-9.-]+\.[a-z]{2,}$/,
                      title: "Please enter a valid email address",
                    }}
                    InputProps={{
                      style: {
                        backgroundColor: "#fff", // Set the background color here
                      },
                      inputProps: {
                        style: { marginTop: "7px", marginBottom: "-3px" }, // Add padding to the left side of the input text
                      },
                    }}
                  />
                  <FormControl
                    id="country"
                    name="country"
                    variant="filled"
                    required
                    fullWidth
                    style={{ marginBottom: "20px", backgroundColor: "white" }}
                    className={classes.nameField}
                  >
                    <InputLabel id="gender">Select your country</InputLabel>
                    <Select
                      labelId="country"
                      id="country"
                      className="w-23"
                      value={country}
                      onChange={(e) => setCountry(e.target.value)}
                      classes={{ root: classes.root }}
                      InputProps={{
                        style: { backgroundColor: "#fff" },
                        inputProps: { style: { maxWidth: "100px" } },
                      }}
                      MenuProps={{
                        components: {
                          MenuItem: (props) => (
                            <MenuItem
                              {...props}
                              classes={{ root: classes.menuItem }}
                              style={{ maxWidth: "100px" }}
                            >
                              {props.children}
                            </MenuItem>
                          ),
                        },
                      }}
                    >
                      {countriesList &&
                        countriesList.map((item, index) => (
                          <MenuItem
                            value={item.name}
                            key={`${item.name}-${index}`}
                          >
                            {item.name}
                          </MenuItem>
                        ))}
                    </Select>
                  </FormControl>
                  <TextField
                    id="city"
                    name="city"
                    label="Enter your city"
                    value={city}
                    onChange={(e) => setCity(e.target.value)}
                    variant="filled"
                    required
                    fullWidth
                    style={{ marginBottom: "20px", backgroundColor: "white" }} // Add backgroundColor
                    className={classes.nameField}
                    InputProps={{
                      style: {
                        backgroundColor: "#fff", // Set the background color here
                      },
                      inputProps: {
                        style: { marginTop: "7px", marginBottom: "-3px" }, // Add padding to the left side of the input text
                      },
                    }}
                  />
                  <span
                    style={{
                      fontSize: "11px",
                      fontWeight: "bold",
                      textAlign: "center",
                    }}
                  >
                    *We value your privacy, your details will be kept
                    confidential. Refer our{" "}
                    <a href="https://rxone.app/privacypolicy">Privacy Policy</a>
                  </span>
                  <Button
                    className={classes.button}
                    variant="contained"
                    color="primary"
                    type="submit"
                    fullWidth
                    disabled={loading} // Disable button when loading is true
                  >
                    {loading ? (
                      <CircularProgress size={24} color="inherit" />
                    ) : (
                      "Request Callback"
                    )}
                  </Button>
                </form>
              </div>
            </Container>
          </div>
        </div>
      </div>
      {showAlert && (
        <CenteredAlert
          message="We have received your request, please allow us up-to 24 Hrs. to come back to you."
          onClose={() => setShowAlert(false)}
        />
      )}
    </>
  );
};
export default Patientregis;
