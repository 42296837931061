import React from 'react'
import FreshChat from "react-freshchat"


  export const chatBot = (faq = false) => {

    const userData ={
      
      name: "Rx-one",
      }
    return (

      <div className=''>
          <FreshChat
         token={"d69fd409-d0b4-4880-a7cf-77cdb6e8e4ff"}
          host={"https://wchat.in.freshchat.com"}
          config={{
            headerProperty: {
              backgroundColor: "#16434d",
              foregroundColor: "#ffffff"
            }
          }}
          onInit={widget => {
  
              widget.user.setProperties({
                // email: userData.email,
                firstName: userData.name,
                 
                
              })
              widget.on("user:created", function (resp) {
                // console.log(11, resp);
  
              })
          
          }}
        />
      </div>
    )
    // window.fcWidget.hide();
  };


export default chatBot