import React, { useEffect, useRef } from 'react';
import 'bootstrap/dist/css/bootstrap.min.css';
import '../Css/product.css'; // Import the CSS file for the product section
import '../Css/counter.css'; // Import the CSS file for the counter section
import serviceGif7 from '../Images/Picture6.png';
import serviceGif8 from '../Images/Picture7-removebg-preview.png';
import serviceGif9 from '../Images/Picture8-removebg-preview.png'; 
import phoneImg from '../Images/file.png'; // Path for the static phone image
import BeforeFooter from './BeforeFooter';
import { Helmet } from "react-helmet";
// Array of services for the UHX component
const services = [
  {

    imgSrc: serviceGif7,
    heading: 'Smart OPD',
    description: 'Manage one or many facility through single dashboard, powered with features like Smart Calendar, Dynamic Queue Management, Billing & Online Payments. From patient self check-ins, token generation to custom workflows, from automated settlements to easy case files management, streamline your operations effortlessly. Simplify your workflow and maximize productivity with RxOne.',
  },
  {
    imgSrc: serviceGif8,
    heading: 'Smart EMR',
    description: 'RxOne offers user-centric tools, allowing care providers to focus on delivering quality care without technical hassles. Our smart prescription feature with multi-lingual support ensures efficient prescription generation, while Patient 360* provides a holistic view of patient history (Electronic Medical Records) and vitals for accurate diagnoses. With RxOne, streamline your workflow and prioritize patient care.',
  },
  {
    imgSrc: serviceGif9,
    heading: 'Smart CRM',
    description: "Experience unparalleled patient engagement with RxOne's revolutionary autonomous patient engagement engine. From follow-up reminders to prescription messages, medication reminders, and personalized vitals tracking guidelines, our platform automates every step of the patient journey. Stay connected with your patients effortlessly and empower them with valuable health tips.",
  },
];

// Component to render UHX services in a structured layout
const AllservicesInRectangle = () => {

  return (
    <div className="service-rectangle" style={{marginTop:"-5px"}}>
         <div className='racHead1'><h3 className='racH1'>Smart EMR  &  Smart OPD  &  Smart CRM</h3></div> 
      {services.map((service, index) => (
        <div className={`service-section ${index === 1 ? 'second-section' : ''}`} key={index}>
          <div className={`service-img-container ${index % 2 !== 0 ? 'order-last' : ''}`}>
            <img src={service.imgSrc} alt={service.heading} className="img-fluid service-img" />
          </div>
          <div className="service-text">
            <h3 className="mb-3">{service.heading}</h3>
            <p>{service.description}</p>
            <a href="https://calendly.com/careconsult_online" className="btn btn-light">Book A Demo</a>
          </div>
        </div>
      ))}
    </div>
  );
};


// Counter Component for UHX with 'cr' and 'k' units
const Counter0 = () => {
  const countersRef = useRef([]);

  useEffect(() => {
    countersRef.current.forEach((counter) => {
      const target = +counter.getAttribute('data-count');
      let count = 0;
      const increment = target / 200;
      const unit = '%'; // Use percentage for all counters

      const updateCount = () => {
        if (count < target) {
          count += increment;
          counter.innerHTML = Math.ceil(count) + '&nbsp;' + unit;
          setTimeout(updateCount, 10);
        } else {
          counter.innerHTML = target + '&nbsp;' + unit;
        }
      };

      updateCount();
    });
  }, []);

  return (
    <div className="counters0-section">
      <div className="Counter0-box">
        <div className="Counter0" data-count="44" ref={(el) => (countersRef.current[0] = el)}>
          0%
        </div>
        <p className="Counter0-text">Time saved in OPD Consultations</p>
      </div>
      <div className="Counter0-box">
        <div className="Counter0" data-count="200" ref={(el) => (countersRef.current[1] = el)}>
          0%
        </div>
        <p className="Counter0-text">Increase in follow-ups</p>
      </div>
      <div className="Counter0-box">
        <div className="Counter0" data-count="65" ref={(el) => (countersRef.current[2] = el)}>
          0%
        </div>
        <p className="Counter0-text">Medication adherence improvements</p>
      </div>
      <div className="Counter0-box">
        <div className="Counter0" data-count="50" ref={(el) => (countersRef.current[3] = el)}>
          0%
        </div>
        <p className="Counter0-text">Operational costs reduction</p>
      </div>
    </div>
  );
};


// UHX Component combining services and counter
const Product = () => {
  return (
    <div className="headLine" >
       <Helmet>
        <title>RxOnecare: Smart OPD (Outpatient Department), EMR & CRM (Customer relationship management)Solutions for Streamlined Healthcare Management</title></Helmet>
        <Helmet> <meta name="description" content="RxOne - Streamline healthcare operations with our Smart OPD, EMR, and CRM solutions. Manage facilities through a single dashboard with features like smart calendar, dynamic queue management, billing, online payments, and patient engagement. Prioritize patient care with efficient prescription tools and automated workflows for improved productivity." /></Helmet>
      <Helmet>  <meta name="keywords" content="Best queue management system ,  Best emr systems and Best medical software , Patient engagement software companies , RxOne, Smart OPD management, dynamic queue management, healthcare billing software, online payments for OPD, patient self check-in software, custom healthcare workflows, automated case management, Smart EMR software, multilingual prescription generation, patient 360 view, electronic medical records, healthcare CRM, autonomous patient engagement engine, follow-up reminders, medication reminders, vitals tracking for patients, healthcare automation tools" />
      </Helmet>
      {/* New Header Section */}
      <div className="uppHead" >
        <h1 className="UpTex">Next-Gen, AI powered EMR </h1>
      </div>
    <div className="outsd">
      <AllservicesInRectangle />
      <Counter0/>
    </div>
    <div style={{marginBottom:"-45px",}}>
    <BeforeFooter/></div>
    </div>
  );
};

export default Product;
