import React from 'react';
import '../Css/careFacility.css'; // Import the corresponding CSS
import careFacilityImage from '../Images/provider/portrait-doctor__1_-removebg-preview.png';
import careFacilityImage2 from '../Images/provider/young-doctor-getting-ready-work-removebg-preview.png'; 
import { Link, useNavigate } from "react-router-dom";
import useMediaQuery from '@mui/material/useMediaQuery';
import { Style } from '@material-ui/icons';
import { Helmet } from "react-helmet";
const CareFacility = () => {
  
  const navigate = useNavigate('');

  const handleclick = () =>{
    navigate('/next-gen-ai-powered-emr')

  }
  const handleclick2 = () =>{
    navigate('/healthcare-iot-with-edge')

  }
  const handleclick3 = () =>{
    navigate('/unified-health-exchange')

  }
  const handleclick4 = () =>{
    navigate('/digital-with-market360')

  }

  const isMobile = useMediaQuery("(max-width:710px)");

    return (
        <>
         <Helmet>
         <meta name="description" content="RxOnecare offers cutting-edge Care Facility Management and EMR Solutions that enhance Patient Engagement and streamline OPD Management. Our Smart Calendar automates appointment reminders, while Smart Prescriptions simplify medication management. Utilize our Telehealth and Remote Monitoring capabilities to connect with patients effectively. RxOne’s AI-Powered Smart Reports provide actionable insights for improved patient outcomes. We facilitate Health Data Exchange through the Unified Health Interchange (UHX) and support ABHA Registration. Boost your digital presence with our Healthcare SEO, Lead Generation, and Social Media Management services. Choose RxOne for a patient-centered approach to healthcare." /></Helmet>
         <Helmet>  <meta name="keywords" content="Care Facility Management Software, Electronic Medical Records (EMR) Solutions, Patient Engagement Tools, Outpatient Department (OPD) Management, Smart Calendar for Healthcare, Digital Prescription Management, Telehealth Solutions, Remote Patient Monitoring, Medical Records Summarization, AI-Powered Smart Reports, Health Data Exchange Solutions, Unified Health Interchange (UHX), Ayushman Bharat Health Account (ABHA) Registration, Digital Health Incentive Schemes, Comprehensive Digital Marketing for Healthcare, SEO for Healthcare Providers, Healthcare Lead Generation, Social Media Management for Clinics, Branded Healthcare Website Solutions, Improving Patient Outcomes in Healthcare." />
      </Helmet>
        <div className="care-facility-container">
            <div className="header-section" style={{padding:"1%",marginBottom:"1%",marginLeft:"6%"}}>
                <h1 className="header-title">Care Facility Management, Simplified: <span className="emr-highlight">EMR</span></h1>
            </div>
            <div className="box-container">
                <div className="box">
                    <h3 className="box-title">Patient Engagement</h3>
                </div>
                <div className="box">
                    <h3 className="box-title">OPD Management</h3>
                </div>
                <div className="box">
                    <h3 className="box-title">Smart Calendar</h3>
                </div>
                <div className="box">
                    <h3 className="box-title">Smart Prescriptions</h3>
                </div>
                <div className="box">
                    <h3 className="box-title">Telehealth & Remote Monitoring</h3>
                </div>
                <div className="box">
                    <h3 className="box-title">Medicines & Lab Tests</h3>
                </div>
            </div>

            <div className="button-container" style={{marginTop:"-2%"}}>
            <a href="https://calendly.com/careconsult_online" target="_blank" rel="noopener noreferrer">
    <button className="btn-book-demo">Book A Demo</button>
</a>
                <button className="btn-know-more" onClick={handleclick}>Know More</button>
             
            </div>
            {/* <div className="banner-container"> */}
  {/* <div >
    <h1 className="text-heading5">
      Patient Outcomes, Improvised: <span className="emr-highlight">EDGE</span>
    </h1>
  </div>

  <div className="text-image-section"style={{padding:"5%"}}>
    <div className="text-content">
      <p className="text-description2">
        Use AI powered Smart Reports, Medical Records Summarization and Remote Monitoring to gain actionable insights and improve diagnostic accuracy.
      </p>
      <div className="button-container3"style={{paddingLeft:"1%"}}>
    <a href="https://calendly.com/rxonecare" target="_blank" rel="noopener noreferrer">
      <button className="btn-book-demo">Book A Demo</button>
    </a>
    <button className="btn-know-more" onClick={handleclick2}>Know More</button>
  </div>
    </div>
    <div className="image-content">
      <img src={careFacilityImage} alt="Care Facility" className="care-facility-image" />
    </div>
  </div> */}
<div className="grey-box">
    <div className="text-content">
        <div className="text-header">
            <h1 className="text-heading5">
                Patient Outcomes, Improvised: <span className="emr-highlight">EDGE</span>
            </h1>
        </div>
        <div className="text-description-container">
            <p className="text-description2">
                Use AI powered Smart Reports, Medical Records Summarization and Remote Monitoring to gain actionable insights and improve diagnostic accuracy.
            </p>
        </div>
        <div className="button-container3">
            <a href="https://calendly.com/careconsult_online" target="_blank" rel="noopener noreferrer">
                <button className="btn-book-demo">Book A Demo</button>
            </a>
            <button className="btn-know-more" onClick={handleclick2}>
                Know More
            </button>
        </div>
    </div>
    <div className="image-content">
    <div className="circle-container">
    
    <img
        src={careFacilityImage}
        alt="Care Facility"
        className="circle-image"
    />
</div>
    </div>
</div>




            <div className="header-section3">
                <h1 className="header-title3"style={{padding:"2.5%"}}>Health Data Exchange, Streamlined: <span className="emr-highlight">UHX</span></h1>
            </div>
            <div className="box-container2">
                <div className="box2">
                    <h3 className="box-title2">ABHA ID Creation  </h3>
                </div>
                <div className="box2">
                    <h3 className="box-title">Facility ABDM Registration </h3>
                </div>
                <div className="box2">
                    <h3 className="box-title">Unified Health Interchange </h3>
                </div>
                <div className="box2">
                    <h3 className="box-title">Digital Health Incentive Scheme </h3>
                </div>
               
            </div>
            <div className="box-container2 ">
            <a href="https://calendly.com/careconsult_online" target="_blank" rel="noopener noreferrer">
    <button className="btn-book-demo">Book A Demo</button>
</a>
<button className="btn-know-more" onClick={handleclick3}>Know More</button>
            </div>
            <div className="grey-box">
    <div className="text-content">
        <div className="text-header">
            <h1 className="text-heading5">
            Digital Marketing, Unified: 
            <span className="emr-highlight"> Market360*</span>
            </h1>
        </div>
        <div className="text-description-container">
            <p className="text-description2">
            Scale your care services with end-to-end digital marketing and digital presence management. Branded website, SEO, Lead Generation, Social Media management and much more.
            </p>
        </div>
        <div className="button-container3">
            <a href="https://calendly.com/careconsult_online" target="_blank" rel="noopener noreferrer">
                <button className="btn-book-demo">Book A Demo</button>
            </a>
            <button className="btn-know-more" onClick={handleclick4}>
                Know More
            </button>
        </div>
    </div>
    <div className="image-content">
    <div className="circle-container">
    
    <img
        src={careFacilityImage2}
        alt="Care Facility"
        className="circle-image2"
    />
</div>
    </div>
</div>
{/* 
            <div className="banner-container">
  <div >
    <h1 className="text-heading5">
    Digital Marketing, Unified: 
    <span className="emr-highlight">Market360*</span>
    </h1>
  </div>

  <div className="text-image-section"style={{padding:"5%"}}>
    <div className="text-content">
      <p className="text-description3">
      Scale your care services with end-to-end digital marketing and digital presence management. Branded website, SEO, Lead Generation, Social Media management and much more.

      </p>
      <div className="button-container5">
    <a href="https://calendly.com/rxonecare" target="_blank" rel="noopener noreferrer">
      <button className="btn-book-demo">Book A Demo</button>
    </a>
    <button className="btn-know-more" onClick={handleclick4}>Know More</button>
  </div>
    </div>
    <div className="image-content2">
      <img src={careFacilityImage2} alt="Care Facility" className="care-facility-image2" />
    </div> */}
  {/* </div> */}

 
</div>
        {/* </div> */}
        </>
    );
}

export default CareFacility;
