import React, { useState } from 'react';
import CountUp from 'react-countup';
import ScrollTrigger from 'react-scroll-trigger';
import '../Css/counter.css'; // Ensure this path is correct


const Counter = () => {
  const [triggered, setTriggered] = useState(false);

  return (
    <ScrollTrigger 
      onEnter={() => setTriggered(true)} 
      onExit={() => setTriggered(false)} 
      throttleScroll={100} // Optional: adjust throttle for performance
      threshold={0.3} // Adjust threshold for when to trigger (30% visible)
    >
      <div className="counters-section" style={{ backgroundColor: "white", margin: "-75px auto 0 auto" }}>
        <div className="counter-box" style={{ background: 'linear-gradient(45deg, #005C74, #018C92, #01A09E)', width: "20%" }}>
          <div className="counter">
            {triggered && <CountUp start={0} end={200} duration={5} suffix="+" />}
          </div>
          <p className="counter-text">Care Facilities</p>
        </div>
        <div className="counter-box" style={{ background: 'linear-gradient(45deg, #005C74, #018C92, #01A09E)', width: "20%" }}>
          <div className="counter">
            {triggered && <CountUp start={0} end={9000} duration={5} suffix="+" />}
          </div>
          <p className="counter-text">Happy Patients</p>
        </div>
        <div className="counter-box" style={{ background: 'linear-gradient(45deg, #005C74, #018C92, #01A09E)', width: "20%" }}>
          <div className="counter">
            {triggered && <CountUp start={0} end={11500} duration={5} suffix="+" />}
          </div>
          <p className="counter-text">Consultations</p>
        </div>
        <div className="counter-box" style={{ background: 'linear-gradient(45deg, #005C74, #018C92, #01A09E)', width: "20%" }}>
          <div className="counter">
            {triggered && <CountUp start={0} end={15000} duration={5} suffix="+" />}
          </div>
          <p className="counter-text">Digital Records</p>
        </div>
      </div>
    </ScrollTrigger>
  );
};

export default Counter;
