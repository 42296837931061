import React, { useEffect, useRef } from 'react';
import 'bootstrap/dist/css/bootstrap.min.css';
import '../Css/dentalEmr.css'; // Import the CSS file for the product section
import '../Css/counter.css'; // Import the CSS file for the counter section
import serviceGif19 from '../Images/Picture24.png';
import serviceGif20 from '../Images/Picture25.png';
import serviceGif21 from '../Images/Picture26-removebg-preview.png'; 
import phoneImg from '../Images/file.png'; // Path for the static phone image
import BeforeFooter from './BeforeFooter';
import { Helmet } from "react-helmet";
// Array of services for the UHX component
const services9= [

  {
    imgSrc: serviceGif19,
    heading: 'Continuous Engagement',
    description: 'The delay in seeking dental treatment is a universal health problem, with a reported prevalence as high as 98%. RxOne helps by educating and encouraging patients to take timely dental treatments. Automated follow-up reminders, medication reminders, health tips and easy to use app to engage patients and to build a trusted relationship with the doctor. ',
  },
  {
    imgSrc: serviceGif20,
    heading: 'Dynamic Workflow',
    description: 'The Dental facilities having more than one department (or chairs), might have different workflows than other OPDs. Hence, RxOne offers Dynamic workflows, which allows several specialists to work in collaboration on a case, create a running Prescription & manage case files together. ',
  },
  {
    imgSrc: serviceGif21,
    heading: 'Specialized EMR',
    description: 'RxOne specialized Dental EMR, provides easy to use Dental charts with Quadrant View and Continuous case sheet features for all appointments for single case. Doctors can easily manage various case files (X-rays, Lab Reports etc) for each appointment and share with patient with a single click. Doctor can also add order items and raise invoice with online/cash payment options',
  },
];

// Component to render UHX services in a structured layout
const AllServices9InRectangle = () => {
  return (
    <div className="service9-rectangle">
    <div className='racHead7'><h3 className='racH7'>Engage Users – Empower Patients – Enhance Experience</h3></div> 

      {services9.map((service9, index) => (
     <div className={`service-section ${index === 1 ? 'second-section' : ''}`} key={index}>
          <div className={`service9-img-container ${index % 2 !== 0 ? 'order-last' : ''}`}>
            <img src={service9.imgSrc} alt={service9.heading} className="img-fluid service9-img" />
          </div>
          <div className="service9-text">
            <h3 className="mb-3">{service9.heading}</h3>
            <p>{service9.description}</p>
            <a href="https://calendly.com/careconsult_online" className="btn btn-light">Book A Demo</a>
          </div>
        </div>
      ))}
    </div>
  );
};

// Counter Component for UHX with 'cr' and 'k' units
const Counter12 = () => {
  const countersRef = useRef([]);

  useEffect(() => {
    countersRef.current.forEach((counter) => {
      const target = +counter.getAttribute('data-count');
      let count = 0;
      const increment = target / 200;
      const unit = '%'; // Use percentage for all counters

      const updateCount = () => {
        if (count < target) {
          count += increment;
          counter.innerHTML = Math.ceil(count) + '&nbsp;' + unit;
          setTimeout(updateCount, 10);
        } else {
          counter.innerHTML = target + '&nbsp;' + unit;
        }
      };

      updateCount();
    });
  }, []);

  return (
    <div className="Counter12-section">
      <div className="Counter12-box">
        <div className="Counter12" data-count="44" ref={(el) => (countersRef.current[0] = el)}>
          0%
        </div>
        <p className="Counter12-text">Time saved in OPD Consultations</p>
      </div>
      <div className="Counter12-box">
        <div className="Counter12" data-count="200" ref={(el) => (countersRef.current[1] = el)}>
          0%
        </div>
        <p className="Counter12-text">Increase in follow-ups</p>
      </div>
      <div className="Counter12-box">
        <div className="Counter12" data-count="85" ref={(el) => (countersRef.current[2] = el)}>
          0%
        </div>
        <p className="Counter12-text">Improvements in patient outcomes
        </p>
      </div>
      <div className="Counter12-box">
        <div className="Counter12" data-count="50" ref={(el) => (countersRef.current[3] = el)}>
          0%
        </div>
        <p className="Counter12-text">Operational costs reduction</p>
      </div>
    </div>
  );
};


// UHX Component combining services and counter
const DentalEmr= () => {
  return (
    <div className="headLine" >
        <Helmet>
        <title>Comprehensive Dental EMR with Patient Engagement & Dynamic Workflow Solutions - RxOne 
        </title></Helmet>
        <Helmet> <meta name="description" content="RxOne revolutionizes dental practices by enhancing patient engagement and streamlining workflow management. With automated follow-up reminders, medication alerts, and an easy-to-use app, RxOne helps dentists build lasting patient relationships and encourage timely dental treatments. The specialized Dental EMR offers Dynamic Workflows, enabling multiple specialists to collaborate on cases, manage case files, and create running prescriptions seamlessly. Features like Dental Charts with Quadrant View, continuous case sheets, X-ray and lab report management, and easy file sharing improve operational efficiency. Doctors can also raise invoices with both online and cash payment options, making RxOne a comprehensive solution for modern dental practices." /></Helmet>
        <Helmet> <meta name="keywords" content="Patient engagement in dental care, RxOne, dental EMR software, dynamic dental workflow, dental case management, quadrant view dental charts, continuous engagement for dental patients, automated dental follow-ups, online dental invoicing, case file management for dentists, X-ray and lab report sharing, dental treatment reminders, patient education dental care, dental health tips, dental medication reminders" />
      </Helmet>
      {/* New Header Section */}
      <div className="uppHead5" >
        <h1 className="UpTex5">Specialized Dental EMR  </h1>
      </div>
    <div style={{ background: "linear-gradient(45deg, #005C74, #018C92, #01A09E)", padding: "20px", marginTop:"-100px" }}>
      <AllServices9InRectangle />
      <Counter12 />
      
    </div>
    <div style={{marginBottom:"-45px",}}>
    <BeforeFooter/></div>
    </div>
  );
};

   
export default DentalEmr;
