import React from 'react';
import 'bootstrap/dist/css/bootstrap.min.css';
import '../Css/mvt.css'; // Import the CSS file for the product section
import '../Css/counter.css'; // Import the CSS file for the counter section
import serviceGif25 from '../Images/provider/seniorcouplle.png';
import serviceGif26 from '../Images/Picture31.png';
import serviceGif27 from '../Images/Picture32.png';
import BeforeFooter from './BeforeFooter';
import { Helmet } from "react-helmet";
// Array of services for the UHX component
const services11 = [
  {
    imgSrc: serviceGif25,
    heading: 'Beyond Geographies',
    description: 'Expand Care Services beyond geographical boundaries with RxOne’s unique MVT offerings. The RxOne platform delivers an unparalleled care experience through its All-in-One “RxOne-Care to Cure” App, which includes treatment advisory, appointment scheduling, teleconsultations, health file management, and visa, travel, and logistics assistance. This solution enables care providers to engage with patients via a state-of-the-art interface, ensuring an excellent user experience.',
  },
  {
    imgSrc: serviceGif26,
    heading: 'Beyond HIS/HMS',
    description: "Once the intervention is completed for International Patients, the data in HMS/HIS system remains unusable foreseeable future. With RxOne smart integrations, the HMS/HIS can exchange the data continuously with patients, giving them required transparency and building long lasting trustable relationship.",
  },
  {
    imgSrc: serviceGif27,
    heading: 'Care Continuum',
    description: 'Most often the engagement with International Patient is over shortly after the treatment. However, as a patient, the care to cure journey continues even after patient is back to home country. With RxOne, Care providers can offer care continuum for International Patients with Post-Op follow ups, access medical files such as tests results remotely, see body vitals trend and provide Tele consultations, all this enhances patient experience and improve care outcomes.',
  },
];

// Component to render UHX services in a structured layout
const Allservices11InRectangle = () => {
  return (
    <div className="service11-rectangle">
            <div className='racHead8'><h3 className='racH8'>Care Continuum Globally            </h3></div> 

      {services11.map((service11, index) => (
        <div className={`service11-section ${index === 1 ? 'second-section' : ''}`} key={index}>
          <div className={`service11-img-container ${index % 2 !== 0 ? 'order-last' : ''}`}>
            {/* Conditionally adding the 'second-image' class to the second image */}
            <img 
              src={service11.imgSrc} 
              alt={service11.heading} 
              className={`img-fluid service11-img ${index === 1 ? 'second-image' : ''}`} 
            />
          </div>
          <div className={`service11-text ${index === 1 ? 'second-text' : ''}`}>
            <h3 className={`mb-3 ${index === 1 ? 'second-heading' : ''}`}>{service11.heading}</h3>
            <p className={index === 1 ? 'second-description' : ''}>{service11.description}</p>
            <a href="https://rxone.app/contactus" className="btn btn-light">Contact Now</a>
          </div>
        </div>
      ))}
    </div>
  );
};

// UHX Component combining services and counter
const Mvt = () => {
  return (
    <div className="headLine">
        <Helmet>
        <title>RxOne: Expanding Global Healthcare with (Medical value tourism) MVT Solutions & Care Continuum for International Patients
        </title></Helmet>
        <Helmet> <meta name="description" content="RxOne empowers healthcare providers to expand their services beyond geographical limits with its unique MVT (Medical Value Travel) offerings. The all-in-one, RxOne-Care to Cure App streamlines care for international patients, offering teleconsultations, appointment scheduling, health file management, and visa, travel, and logistics support. With seamless HIS/HMS integrations, RxOne ensures continuous data exchange, fostering transparency and long-lasting patient relationships. Even after patients return home, RxOne enables post-op follow-ups, remote access to medical files, body vitals tracking, and teleconsultations, ensuring a comprehensive care continuum and enhancing patient outcomes globally." /></Helmet>
      <Helmet>  <meta name="keywords" content="RxOne, Medical Value Travel services, Global healthcare services, expand healthcare beyond geographies, international patient care, care continuum for international patients, teleconsultations for international patients, post-op follow-up care, HIS/HMS data integration, smart healthcare integration, health file management software, visa and travel assistance for healthcare, remote healthcare management, international healthcare logistics, vitals tracking for remote patients" />
      </Helmet>
      {/* New Header Section */}
      <div className="uppHead">
        <h1 className="UpTex">Medical Value Tourism (MVT) Enablement
        </h1>
      </div>
      <div style={{ background: "linear-gradient(45deg, #005C74, #018C92, #01A09E)", padding: "20px", marginTop: "-100px" }}>
        <Allservices11InRectangle />
      </div>
      <div style={{ marginBottom: "-45px" }}>
        <BeforeFooter />
      </div>
    </div>
  );
};

export default Mvt;
