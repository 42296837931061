import Header from "./components/Header";
import { useEffect, useState } from "react";
import Terms from "./components/CompanyPage/Terms";
import { BrowserRouter, Routes, Router, Route } from "react-router-dom";
import "font-awesome/css/font-awesome.min.css";
import "./App.css";
import Providers from "./components/Providers";
import Booking from "./components/Booking";
import AboutUs from "./components/AboutUs";
import SignUp from "./components/user/SignUp";
// import Home from './components/Home';
import Footer from "./components/Footer";
import ScrollToTop from "./ScrollToTop";
import ContectUs from "./components/ContectUs";
import PrivacyPolicy from "./components/CompanyPage/PrivacyPolicy";
import "bootstrap/dist/css/bootstrap.min.css";
import Blogs from "./components/Blogs";
import { getByDisplayValue } from "@testing-library/react";
import BlogPost from "./components/BlogPost";
import VerifyBot from "./components/chatBot/VerifyBot";
import ErrorBoundary from "./GlobelContext/ErrorBoundary";
import HospitalPath from "./HospitalPath";
import ErrorPage from "./ErrorPage";
import ServiceCall from "./ServiceCall";
import Callexist from "./components/Callexist";
import Doctorprofile from "./components/Doctorprofile";
import Cureways from "./components/Cureways";
import CookiConsent from "react-cookie-consent";
import Patientregis from "./components/Patientregis";
import Product from "./components/Product";
import Counter from "./components/Counter";
import BeforeFooter from "./components/BeforeFooter";
import HealthcareIotPage from "./components/HealthcareIot";
import UHX from "./components/Uhx";
import DigitalMarketing from "./components/DigitalMarketing";
import ForGp from "./components/ForGp";
import DentalEmr from "./components/DentalEmr";
import PediatrEmr from "./components/PediatrEmr";
import Mvt from "./components/Mvt";
import OurPartner from "./components/OurPartner";
import Cardiology from "./components/Cardiology";
import Home2 from "./components/home2";
import Footer2 from "./components/Footer2";
import CareFacility from "./components/CareFacility";
import Testimonials from "./components/Testimonials";
import Counter3 from "./components/Counter3";
import VideoSection from "./components/VideoSection";
import { Helmet } from "react-helmet";
// import Association from './components/Association';
function App() {
  const [patientPortal, setPatientPortal] = useState([]);
  const [doctorPortal, setDoctorPortal] = useState([]);
  const [headerFooter, setHeaderFooter] = useState(true);
  const [acceptedCookies, setAcceptedCookies] = useState(false);

  // useEffect(()=>{
  //   redirectPath()
  // },[])

  const redirectPath = async () => {
    try {
      const result = await ServiceCall.get("/rxonecare/hospitals");
      if (result) {
        setPatientPortal(result.data.hospitals);
        setDoctorPortal(result.data.hospitals);
      }
    } catch (err) {
      setPatientPortal("");
      setDoctorPortal("");
      console.log(err);
    }
  };

  function pathUrl(e) {
    let url = "/" + e;
    console.log(url.toString());
    return url;
  }

  const portal = [
    {
      short_name: "himaniclinic",
      url: "/provider",
    },
    {
      short_name: "dentalclinic",
      url: "/aboutus",
    },
  ];
  const shouldRenderHeaderFooter =
    !window.location.pathname.includes("/video/end/call");
  useEffect(() => {
    // Check if the user has previously accepted cookies
    const cookiesAccepted = localStorage.getItem("cookiesAccepted");
    if (cookiesAccepted) {
      setAcceptedCookies(true);
    }
  }, []);

  const handleAcceptCookies = () => {
    // Set flag in local storage to indicate that cookies are accepted
    localStorage.setItem("cookiesAccepted", "true");
    // Update state
    setAcceptedCookies(false);
  };

  return (
    <BrowserRouter>
      <Helmet />
      <Helmet>
        <title>
          RxOne - EMR & Health Informatics | Actionable Insights for Doctors &
          Care Providers
        </title></Helmet>
        <Helmet> <meta
          name="description"
          content="Scale services, optimize costs and improve care outcomes , Our EMR solution leverages the world’s leading AI models and IoT device integrations to seamlessly automate care workflows, provide real-time decision support, and optimize revenue. This allows you to focus on what matters most—your patients—and enhance the quality of care you deliver."
        /></Helmet>
        <Helmet><meta
        name="keywords"
        content="EMR, EDGE, RXONE, "/>
      </Helmet>

      <div className="overflow-hidden">
        <ScrollToTop />
        {shouldRenderHeaderFooter && <Header />}

        <Routes>
          {/* <Route exact path="/" element={<Home/>}/> */}
          {/* <Route  path="/aboutus" element={<AboutUs/>}/>
      <Route  path ='/doctor/:id' element ={<HospitalPath headerfooter={setHeaderFooter} path={doctorPortal} />}/>
      <Route  path="/providers" element={<Providers/>}/>
      <Route path="/profile/:route_key" element={<Doctorprofile />} />

      <Route  path="/specialists/:code" element={<Booking/>}/>
      <Route path="/privacypolicy" element={<PrivacyPolicy/>}/>
      <Route path="/terms" element={<Terms/>}/>
      <Route path="/contactus" element={<ContectUs/>}/>
      <Route path="/blogs/:slug" element={<Blogs/>}/>
      <Route path="/blogs" element={<Blogs/>}/>
      <Route path="/treatment-advice" element={<Patientregis/>}/>

      <Route  path='/video/end/call' element={<Callexist/>} /> 
      <Route  path='/cureways/:code' element={<Cureways/>}/> */}

          {/* <Route path="*" element={<ErrorPage headerfooter={setHeaderFooter} path={patientPortal}/>}/> */}
          <Route path="/" element={<Home2 />} />
          <Route path="/next-gen-ai-powered-emr" element={<Product />} />
          <Route
            path="/healthcare-iot-with-edge"
            element={<HealthcareIotPage />}
          />
          <Route path="/unified-health-exchange" element={<UHX />} />
          <Route
            path="/digital-with-market360"
            element={<DigitalMarketing />}
          />
          <Route path="/specialized-emr-for-gps" element={<ForGp />} />
          <Route path="/specialized-cardiology-emr" element={<Cardiology />} />
          <Route path="/specialized-dental-emr" element={<DentalEmr />} />
          <Route path="/specialized-pediatric-emr" element={<PediatrEmr />} />
          <Route path="/medical-value-tourism-enablement" element={<Mvt />} />
          <Route path="/aboutus" element={<AboutUs />} />
          <Route path="/blogs/:slug" element={<Blogs />} />
          <Route
            path="*"
            element={
              <ErrorPage headerfooter={setHeaderFooter} path={patientPortal} />
            }
          />
          <Route path="/privacypolicy" element={<PrivacyPolicy />} />
          <Route path="/terms" element={<Terms />} />
          <Route path="/contactus" element={<ContectUs />} />
        </Routes>
        <VerifyBot />
        {shouldRenderHeaderFooter && <Footer2 />}
        {/* <Home2/>
      <Counter/>
      <OurPartner/>
      <CareFacility/>
      <Testimonials/>
       <Counter3/> 
       <BeforeFooter/>
       <Footer/> */}
        {/* <Product/>
      <BeforeFooter/>
      <HealthcareIotPage/>
      <BeforeFooter/>
      <UHX/>
      <BeforeFooter/>
      <DigitalMarketing/>
      <BeforeFooter/>
      <ForGp/>
      <BeforeFooter/>
      <Cardiology/>
      <BeforeFooter/>
      <DentalEmr/>
      <BeforeFooter/>
      <PediatrEmr/>
      <BeforeFooter/>
      <Mvt/>
      <BeforeFooter/>
      <OurPartner/>
      <BeforeFooter/>
      <Footer/> */}
      </div>

      {!acceptedCookies && (
        <CookiConsent
          debug={true}
          location="bottom"
          style={{
            backgroundColor: "#005c78",
            textAlign: "left",
            fontSize: "14px",
          }}
          buttonStyle={{ color: "000", background: "#fff", fontSize: "15px" }}
          buttonText="Accept all cookies"
          onAccept={handleAcceptCookies} // Call the function when cookies are accepted
          // disableStyles // Disable default styles to apply custom styles
          cookieName="cookieConsent" // Set a cookie name to prevent the component from reappearing after page refresh
        >
          By clicking the Allow Cookies button you agree to the use of cookies
          as described in our Privacy Policy{" "}
          <a href="https://rxone.app/privacypolicy" target="_blank">
            Learn more..
          </a>
        </CookiConsent>
      )}
    </BrowserRouter>
  );
}

export default App;

// divya.kaushik@atcs.com
