import React,{useState} from 'react'
import Image from"../Images/bnr-img.png"
import "../Css/blog.css"
import ArrowBackIosIcon from '@mui/icons-material/ArrowBackIos';
import { useParams,useNavigate } from 'react-router-dom';


function BlogPost({blog,close,allBlog,setblog}) {
const navigate = useNavigate();
  
  function truncate(string,max){
    return string.length>max ? string.substr(0,max-1)+"...": string;
  }

 function navigation(items){
  navigate(`/blogs/${items.slug}`)
  setblog(items)
 }


    

  return (
    <>
    <div style={{margin:"0px auto", width:"70%"}} className="blog__container">
         <button  className=' rounded-circle blog__btn ' onClick={()=>{close(false)}}>  <ArrowBackIosIcon fontSize="large" sx={{color:'#005c74', fontSize:"30px", cursor:"pointer", marginLeft:"10px"}}/></button>
            <div style={{maxWidth:"600px"}} className='m-auto mt-5 mb-5 '>

              {blog.post_thumbnail==null ?
              <img src={Image} className="img-fluid" alt="..."/>   :
              <img src={blog.post_thumbnail.URL} className="img-fluid" alt="..."/>   
            }

            </div>
            <h1 className='text-center  mt-4 mb-4 ms-font-poppins '>{blog.title}</h1>
            <div id="blog_content" className=' mt-3  blog_paragraph_color ms-font-poppins' dangerouslySetInnerHTML={{__html: blog.content}}></div>
    </div>
    <div style={{margin:"60px auto", width:"80%"}} className=''>
      <h1 className='text-center ms-font-poppins'>Trending</h1>
      <hr />
      {   allBlog.length === 0 ?
                 <div style={{width:"100%"}} className="d-flex justify-content-center align-items-center ">
                      <div className="spinner-border" role="status">
                            <span className="visually-hidden">Loading...</span>
                            </div> 
                      </div>:
           <div style={{gap:"20px", width:"80%"}} className='d-flex flex-wrap justify-content-center m-auto'>
    { allBlog.slice(0,4).map(items => (
      
            <div key={items.ID} >
            <div className="card light blog rounded" style={{width: "22rem", height:"20rem",background:` ${ items.post_thumbnail==null?"../Images/home/footer-bg-img.png" :"url"+"("+ items.post_thumbnail.URL +")"}`, backgroundSize:"cover"}}>
            <div className="card-body" style={{background: "linear-gradient(rgba(0,0,0,0.6),rgba(0,0,0,0.3))"}} >
                <h5 style={{color: "white"}} className="card-title light fw-bolder">{items.title}</h5>
                {/* <p style={{color: "white"}} className="card-text light">{truncate(items.excerpt.replace("<p>", " ").replace("</p>", ""),180)} </p> */}
                
                <div  onClick={()=>navigation(items)} style={{cursor:"pointer"}} className="ms-category-meta"> <a >Read more</a>
 </div> 
            </div>
            </div>
            </div>
     )) 
     
     }
           </div>
    }
    </div>
    </>
  )
}

export default BlogPost