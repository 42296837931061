import React, {
  useEffect,
  useState,
  useContext,
  createContext,
  useRef,
} from "react";
import "../Css/booking.css";
import { useReactToPrint } from 'react-to-print';
import dayjs from "dayjs";
import doctorTemplate from "../Images/doctor-template.png";
import "aos/dist/aos.css";
import AOS from "aos";
import AddLocationOutlinedIcon from "@mui/icons-material/AddLocationOutlined";
import ServiceCall from "../ServiceCall";
// import TimeSelectedScreen from "./TimeSelectedScreen";
import { CircularProgress } from "@material-ui/core";
import { constants, baseUrl } from "../utility/constants";
import CheckoutScreen from "./CheckoutScreen";
import { makeStyles } from "@material-ui/core/styles";
import Brightness2OutlinedIcon from "@mui/icons-material/Brightness2Outlined";
import HighlightOffIcon from "@mui/icons-material/HighlightOff";
import TimeSelectedScreen from "./TimeSelectedScreen";
import PatientDetails from "./PatientDetails";
import Receipt from "../components/Receipt"
import {
  
  Box,
  Paper,
  TextField,
  Grid,
  FormControl,
  Button,
  IconButton,
} from "@mui/material";
import { convertTimeSlot, Ls, razorpayCall } from "../utility/utility.js";
import styles from "./../style/appoinmentScreenStyle";
import WbSunnyOutlinedIcon from "@mui/icons-material/WbSunnyOutlined";
const steps = [
  "Select Date",
  "Select Time",
  "Confirm Details",
  "Verify Phone",
  "Payment",
];
const useStyles = makeStyles(styles, { index: 1 });
export const PatientpageContext = createContext();

function AppointmentScreen({
  close,
  AllDoctorDetails,
  doctor,
  hospital,
  isPerson
}) {
  const [details, setDetails] = useState([]);
  const [showAppointment, setShowAppointment] = useState(false);

  //    booking starts from here
  const patientContext = useContext(PatientpageContext);
  const classes = useStyles();
  const[closeButton, setCloseButton] = useState(false)
  const [appointmentType, setAppointmentType] = useState("");
  const [paymentType, setPaymentType] = useState("online");
  const [doctorLoader, setDoctorLoader] = useState(false);
  const [bookAppLoader, setBookAppLoader] = useState(false);
  const [doctorList, setDoctorList] = useState([]);
  const [apiError, setApiError] = useState("");
  const [doctorId, setDoctorId] = useState("");
  const [selectedDoctor, setSelectedDoctor] = useState([]);
  const [appointments, setAppointment] = useState([]);
  const [patientData, setPatientData] = useState("");
  const [hospitaldata, setHospitalData] = useState("");
  const [appointmentData, setAppointmentData] = useState("")
  const [appointmentLoader, setAppointmentLoader] = useState("");
  const [patientLoader, setPatientLoader] = useState(false)
  const initialLoad = useRef(true);
  const [ptToken, setPtToken] = useState("");
  const [appointMentSlotMsg, setAppointMentSlotMsg] = useState(
    "Select doctor to get available slots"
  );

  const [currentStep, setCurrentStep] = useState(0);
  // const [successDialog, setSuccessDialog] = useState(defaultDialog);
  const [selectedDate, setSelectedDate] = useState(
    dayjs().format("YYYY-MM-DD")
  );
  // const { 'Registered Doctors': RegisteredDoctor } = doctorsList;

  const [selectedTime, setSelectedTime] = React.useState({});

  const componentRef = useRef(null);
  const handlePrint = useReactToPrint({
    content: () => componentRef.current,
  });

  useEffect(()=>{
    if(currentStep==4){
      setCloseButton(false);
      return () => {
        setCloseButton()
      }
    }
  },[currentStep])

  useEffect(() => {
    data();
    //    Images();
    AOS.init({ duration: 500 });
  }, []);

  const checkAppointmentType = () => {
    if (appointmentType === "inperson") {
    } else {
      displayCheckoutScreen(selectedDoctor, paymentType);
    }
  };

  const displayCheckoutScreen = (sd, paymentType) => {
    setCurrentStep((oldValue) => oldValue + 1);
  };

  const confirmPayment = async (order_id, paymentObject, errorObject, ) => {
    console.log(ptToken)
    if (errorObject) {
      if (errorObject.errorType === "ondismiss") {
        setBookAppLoader(false);
        close()
      } else {
        // patientContext.dialogController({
        //   open: true,
        //   content: errorObject.errorMessage,
        //   maxWidth: "sm",
        // });
        setBookAppLoader(false);
        runAfterPayment();
      }
    } else {
      let url = `/order/online/payment/${order_id}`;
      ServiceCall.post(url, paymentObject, {
        pt_token: ptToken,
      })
        .then((res) => {
          // console.log("confirmtoken", ptToken);
          setBookAppLoader(false);
          setPatientLoader(false)
          runAfterPayment(res.data.message);
        })
        .catch((err) => {
          setBookAppLoader(false);
          if (err.response) {
            setApiError("Failed with response " + err.response.data);
          } else if (err.request) {
            setApiError("Failed request " + err);
          } else {
            setApiError("Failed in general " + err);
          }
        });
    }
  };

  const runAfterPayment = function (displayMessage) {
    console.log("run after payment");
    // if (displayMessage) {
    //   patientContext.dialogController({
    //     open: true,
    //     content: <div className="text-center">{displayMessage}</div>,
    //     maxWidth: "sm",
    //   });
    // }
    // patientContext.getAppointment();
    setCurrentStep((oldValue)=> oldValue+1);
    setPatientLoader(false)
    // setSelectedDate(dayjs().format("YYYY-MM-DD"));
    // toggleAppointment();
  };

  

  console.log(selectedTime);
  const bookAppointmentCall = async (typeofPayment) => {
    setCloseButton(true)
     setBookAppLoader(true);
    try {
        
      const url = `/doctor/appointment/${AllDoctorDetails.hos_id}/${AllDoctorDetails.doc_id}`;
      const appointmentRes = await ServiceCall.post(
        url,
        {
          appointment_date: selectedDate,
          time_alloted: selectedTime.compareTime,
          is_inperson: `${isPerson}`,
          pay_cash: "False",
          slot_duration: selectedTime.slot_duration,
          end_time: selectedTime.end_time,
        },
        { pt_token: ptToken }
      );

      console.log(appointmentRes);
      if (typeofPayment === "online") {
        const fetchOrderUrl = `/order/appointment/${appointmentRes.data.appointment_id}`;
        const appointmentFetchRes = await ServiceCall.get(fetchOrderUrl, {
          pt_token: ptToken,
        });
        console.log(ptToken);
        setAppointmentData(appointmentFetchRes.data.message)


        razorpayCall(
          appointmentFetchRes.data.message.razorpay_order_id,
          appointmentRes.data.appointment_id,
          confirmPayment, 
          AllDoctorDetails,
          patientData,  
          hospitaldata,
          close
        );
      } else {
        setPatientLoader(false)
        runAfterPayment(appointmentRes.data.message);
      }
    } catch (err) {
    //   patientContext.getAppointment();
      console.log("you close the razor pay");
      setPatientLoader(false)
    //   toggleAppointment();
    }
  };

  const changeAppointmentType = function (type) {
    setAppointmentType(type);
    setSelectedDate(dayjs().format("YYYY-MM-DD"));
    setCurrentStep((oldValue) => oldValue + 1);
  };

  const dateChange = function () {
    setSelectedDoctor([]);
    setCurrentStep((oldValue) => oldValue + 1);
    // getAvailableDoctor();
  };

  const getAvailableDoctor = () => {
    setDoctorLoader(true);
    initialLoad.current = false;
    ServiceCall.get(
      `/doctor/available/${constants.hospitalId("hosp_id")}/${
        appointmentType === "online" ? "online" : "in-person"
      }/${dayjs(selectedDate).format("YYYY-MM-DD")}`
    )
      .then((res) => {
        console.log(res);
        setDoctorLoader(false);
        setDoctorList(res.data[constants.registeredDoctor]);
        if (res.data[constants.registeredDoctor].length > 0) {
          setCurrentStep((oldValue) => oldValue + 1);
        }
      })
      .catch((err) => {
        setDoctorLoader(false);
        if (err.response) {
          setApiError("Failed with response " + err.response.data);
        } else if (err.request) {
          setApiError("Failed request " + err);
        } else {
          setApiError("Failed in general " + err);
        }
      });
  };

  const resetStates = () => {
    setSelectedTime({});
  };

  const getAvailableSlots = function (e, dId) {
    // /test/doctor/<str: hospital_id>/<str: doctor_id></str>
    let doctorId = doctor;
    let hospitalID = hospital;

    setDoctorId(doctorId);
    let selectedDoc = doctorList.filter((item) => {
      return item.doc_id === doctorId;
    });
    setCurrentStep((oldValue) => oldValue + 1);

    setSelectedDoctor(AllDoctorDetails);
    setAppointmentLoader("loading");
    resetStates();
    let url = `/doctor/${hospitalID}/${doctorId}/online/${dayjs(
      selectedDate
    ).format("YYYY-MM-DD")}`;
    ServiceCall.get(url)
      .then((res) => {
        setDoctorLoader(false);
        // setDoctorList(res.data['Registered Doctors']);
        if (res.data.time_slots) {
          setAppointment(convertTimeSlot(res.data.time_slots));
        } else {
          setAppointment([]);
          setAppointMentSlotMsg("No Availability Found");
        }
        setAppointmentLoader("");
      })
      .catch((err) => {
        setAppointmentLoader("");
        setAppointMentSlotMsg("");
        if (err.response) {
          setApiError("Failed with response " + err.response.data);
        } else if (err.request) {
          setApiError("Failed request " + err);
        } else {
          setApiError("Failed in general " + err);
        }
      });
  };

  useEffect(() => {
    if (currentStep === 0) {
      initialLoad.current = true;
    }
    if (currentStep === 2) {
      setDoctorId("");
    }
  }, [currentStep]);
  const changeAppointmentScreenTab = function (index) {
    if (index === 0) {
      setAppointmentType("");
    }

    setCurrentStep(index);
  };

  const handleChange = (value) => {
    // console.log(value);
    setSelectedTime(value);
    checkAppointmentType();
  };

  const data = async () => {
    const result = await ServiceCall.get("/rxonecare/available/doctors");
    const dataa = result.data.doctors;
    setDetails(dataa);
  };

  return (
    <Grid
      component={Box}
      container
      justifyContent="center"
      className="position-relative w-100 pt-0"
    >
      
      <Box
        component={Paper}
        sx={{
          py: 5,
          px: 3,
          maxWidth: "500px",
          minWidth: "350px",
          minHeight: "400px",
          position: "relative",
        }}
          >
            
        <div className="container-fluid d-flex border border-2 rounded mb-3 p-1">
        <div className="d-flex align-items-center ms-4">
        <img style={{width:"40px", height:"40px"}} src={ServiceCall.images("/doctor/avatar/",AllDoctorDetails.doc_id)} className="rounded-circle" alt="..."/>
        </div>
        <div className="ms-5">
        <p className="fw-bold mb-0">{AllDoctorDetails.doctor_name} <span className="px-1">({isPerson=="False"?"Tele Consult":"Clinic Consult"})</span></p>
        <p className="mb-0">{AllDoctorDetails.speciality}</p>
        </div>
        </div>

        <IconButton
          onClick={close}
          color="error"
          sx={{ position: "absolute", right: "0", top: "4px" }}
          component="span"
          disabled={closeButton}
        >
          <HighlightOffIcon />
        </IconButton>{" "}
        <Grid
          container
          spacing={0}
          className={`justify-content-center w-100 stepper pb-3 selected-${currentStep}`}
        >
          {" "}
          {currentStep != 4 ? steps.map((item, index) => {
            return (
              <Grid
                key={item + index}
                xs={2}
                className="text-center"
                item
                sx={{
                  maxWidth: 100 / steps.length + '%',
                  width: 100 / steps.length + '%',
                  flexGrow: 100 / steps.length + '%',
                  position: 'relative',
                  zIndex: 1,
                }}
              >
                <Button
                  className="px-1 px-2"
                  disabled={currentStep < index || bookAppLoader}
                  onClick={() => changeAppointmentScreenTab(index)}
                  key={index + item}
                  variant="contained"
                  sx={{
                    minWidth: 34,
                    boxShadow: "none",
                    padding: "2px",
                    borderRadius: "50%",
                    border: "2px solid #ffffff",
                  }}
                  label={index + 1}
                >
                  <Box> {index + 1} </Box>
                </Button>
                <Box className="font-11 px-1"> {item} </Box>{" "}
              </Grid>
            );
          }):" "}{" "}
        </Grid>
        {/* onClick part start from here  */}
        {currentStep === 0 && (
          <Grid item xs={12}>
            {" "}
            {/* <Box className='w-100 text-center'>
                                  <h3 className='mb-2 mt-3'>2. Select Appointment Date
                                  </h3>
                                </Box> */}{" "}
            <TextField
              id="date"
              label="Date"
              className="w-100"
              type="date"
              InputProps={{
                inputProps: {
                  min: dayjs().format("YYYY-MM-DD"),
                  max: dayjs().add(7, "day").format("YYYY-MM-DD"),
                  onKeyPress: (e) => {
                    e.preventDefault();
                  },
                },
              }}
              value={selectedDate}
              onChange={(e) => setSelectedDate(e.target.value)}
              InputLabelProps={{
                shrink: true,
              }}
            />{" "}
            <Box sx={{ textAlign: "right" }}>
              <Button onClick={getAvailableSlots}> Next </Button>{" "}
            </Box>
            {doctorLoader ? (
              <Box
                sx={{ textAlign: "center" }}
                className="d-block pl-3 text-center"
              >
                <CircularProgress size={14} />{" "}
              </Box>
            ) : (
              !initialLoad.current &&
              doctorList.length === 0 && (
                <Box className="text-center">
                  {" "}
                  <h4>
                    {" "}
                    No doctors available at this date.Please select another
                    date.{" "}
                  </h4>
                </Box>
              )
            )}
          </Grid>
        )}{" "}

        {/* available doctor screen end */} {/* select time slots start */}{" "}
        {currentStep === 1 && (
          <Grid item xs={12}>
            {/* <Box className='w-100 text-center'>
                                  <h3 className='mb-2 mt-3'>4. Select Doctor’s Time Slot</h3>
                                </Box> */}{" "}
            {appointmentLoader === "loading" ? (
              <Box
                sx={{ textAlign: "center" }}
                className="d-block pl-3 text-center"
              >
                <CircularProgress size={14} />{" "}
              </Box>
            ) : appointments.length > 0 ? (
              <Grid container spacing="1">
                <Grid item xs={12}>
                  <FormControl fullWidth>
                    <TimeSelectedScreen
                      classes={classes}
                      appointments={appointments}
                      handleChange={handleChange}
                      maxRange={11.3}
                      minRange={0}
                      selectedDate={selectedDate}
                      selectedTime={selectedTime}
                      bookAppLoader={bookAppLoader}
                    >
                      <Box
                        variant="subtitle2"
                        gutterBottom
                        component="div"
                        sx={{
                          bgcolor: "grey.300",
                          color: "grey.900",
                          p: 1,
                          mb: 2,
                          display: "flex",
                          alignItems: "center",
                        }}
                      >
                        <div style={{ width: "23px" }}>
                          <img
                            alt=""
                            style={{
                              width: "100%",
                              height: "100%",
                              textAlign: "center",
                              objectFit: "cover",
                              color: "transparent",
                              textIndent: "10000px",
                            }}
                            src={`${process.env.PUBLIC_URL}/img/morning-sun.png`}
                          />{" "}
                        </div>{" "}
                        Morning{" "}
                      </Box>{" "}
                    </TimeSelectedScreen>{" "}
                    <TimeSelectedScreen
                      classes={classes}
                      appointments={appointments}
                      handleChange={handleChange}
                      maxRange={17}
                      minRange={12}
                      selectedDate={selectedDate}
                      selectedTime={selectedTime}
                      bookAppLoader={bookAppLoader}
                    >
                      <Box
                        variant="subtitle2"
                        gutterBottom
                        component="div"
                        sx={{
                          bgcolor: "grey.300",
                          color: "grey.900",
                          p: 1,
                          mb: 2,
                          display: "flex",
                          alignItems: "center",
                        }}
                      >
                        <WbSunnyOutlinedIcon /> Afternoon{" "}
                      </Box>{" "}
                    </TimeSelectedScreen>{" "}
                    <TimeSelectedScreen
                      classes={classes}
                      appointments={appointments}
                      handleChange={handleChange}
                      maxRange={23.3}
                      minRange={17.3}
                      selectedDate={selectedDate}
                      selectedTime={selectedTime}
                      bookAppLoader={bookAppLoader}
                    >
                      <Box
                        variant="subtitle2"
                        gutterBottom
                        component="div"
                        sx={{
                          bgcolor: "grey.300",
                          color: "grey.900",
                          p: 1,
                          mb: 2,
                          display: "flex",
                          alignItems: "center",
                        }}
                      >
                        <Brightness2OutlinedIcon /> Evening{" "}
                      </Box>{" "}
                    </TimeSelectedScreen>{" "}
                  </FormControl>{" "}
                  {/* {selectedTime.compareTime && (
                                                  <Button
                                                    variant='contained'
                                                    color='error'
                                                    onClick={checkAppointmentType}
                                                    size='small'
                                                    disabled={bookAppLoader}
                                                  >
                                                    Book Appointment
                                                    {bookAppLoader && <CircularProgress size={14} />}
                                                  </Button>
                                                )} */}{" "}
                </Grid>{" "}
              </Grid>
            ) : (
              <p className="text-center"> {appointMentSlotMsg} </p>
            )}{" "}
          </Grid>
        )}{" "}
        {/* select time slots end */}{" "}
        {currentStep === 2 && (
          <Grid item>
            {" "}
            {/* <Box className='w-100 text-center'>
                              <h3 className='mb-2 mt-3'>5. Checkout & Pay</h3>
                            </Box> */}{" "}
            <CheckoutScreen
              selectedDoctor={selectedDoctor}
              paymentType={paymentType}
              appointmentDetails={{
                date: selectedDate,
                time: selectedTime.slotformated,
              }}
              // patientProfile={patientContext.profileData}
              nextStep={setCurrentStep}
              classes={classes}
              appointmentType={appointmentType}
              bookAppLoader={bookAppLoader}
              inPerson ={isPerson}
            />
            {/* { console.log(AllDoctorDetails)} */}{" "}
          </Grid>
        )}
        {currentStep === 3 && (
          <>
            <PatientDetails
              doctorDetails={AllDoctorDetails}
              token={setPtToken}
              bookAppointment={bookAppointmentCall}
              otpPatientLoader ={patientLoader}
              hospitalDetail={setHospitalData}
              patientDetail = {setPatientData}
            />
          </>
        )}

      {currentStep === 4 && (
                <>
                {/* {setCloseButton(false)} */}
                <Receipt 
                AllDoctors={AllDoctorDetails}
                Appointment ={appointmentData} 
                Date ={selectedDate}
                reff={componentRef}
                />
                    <div className='mt-4 d-flex justify-content-between'> 
                    <button className='btn btn-danger' onClick={handlePrint}>Print Receipt</button>
                    <button className='btn btn-danger' onClick={()=>close(true)}>Close</button>
                  </div>
                </>
              )}
      </Box>{" "}
    </Grid>
  );
}

export default AppointmentScreen;
