import React, { useContext, useEffect } from 'react'
import UserAuth from "../../GlobelContext/UserAuth"
import ChatBot from "./ChatBot"

const VerifyBot = () => {
    const [isChatBot, setChatBot] = UserAuth();
    
    
    // useEffect(()=>{
    //   console.log("chat",isChatBot)
    // },[isChatBot])
  return (
    <div>
      {/* <button className='btn' onClick={openWidget()}>click</button> */}
            {/* <ChatBot />  */}
    </div>
  )
}

export default VerifyBot