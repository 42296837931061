import React, { useEffect, useRef } from 'react';
import 'bootstrap/dist/css/bootstrap.min.css';
import '../Css/cardiology.css'; // Import the CSS file for the product section
import '../Css/counter.css'; // Import the CSS file for the counter section
import serviceGif16 from '../Images/Picture21.png';
import serviceGif17 from '../Images/provider/cardiologypic2.0.jpg';
import serviceGif18 from '../Images/Doctor_Portal_Mockup.png'; 
import phoneImg from '../Images/file.png'; // Path for the static phone image
import BeforeFooter from './BeforeFooter';
import { Helmet } from "react-helmet";
// Array of services for the UHX component
const services8= [


  {
    imgSrc: serviceGif16,
    heading: 'Continuous Vitals Monitoring & Smart Reporting',
    description: 'RxOne supercharge Cardiology practices with state-of-the-art features like Continuous Vitals Tracking & Smart Alerting, that not only help specialists to monitor patient’s health remotely, but also helps them early warning indicators for timely interventions, which leads to better outcomes for patients. ',
  },
  {
    imgSrc: serviceGif17,
    heading: 'Medical Data Summarization',
    description: 'No more toiling through loads of medical files during consultation for every patient, RxOne AI summarizes the data for quick actionable insights. The tool comes really handy in high OPD flows, it saves those critical minutes during consultations which can help saving patient’s life. ',
  },
  {
    imgSrc: serviceGif18,
    heading: 'Specialized EMR',
    description: 'Manage patient case files with ease, and share the files with patient in a single click. RxOne provides secured HIPAA cloud storage with easy to use interface to access the records both for patients & providers. Features like Contextual Autosuggest, Global Patient Search, Messaging, Follow-up Reminders among others make RxOne EMR a unique solution for specialist like you.',
  },
];

// Component to render UHX services in a structured layout
const AllServices8InRectangle = () => {
  return (
    <div className="service8-rectangle">
     <div className='racHead6'><h3 className='racH6'>Early Detection + Timely Intervention = Better Outcomes</h3></div> 

      {services8.map((service8, index) => (
         <div className={`service-section ${index === 1 ? 'second-section' : ''}`} key={index}>
          <div className={`service8-img-container ${index % 2 !== 0 ? 'order-last' : ''}`}>
            <img src={service8.imgSrc} alt={service8.heading} className="img-fluid service8-img" />
          </div>
          <div className="service8-text">
            <h3 className="mb-3">{service8.heading}</h3>
            <p>{service8.description}</p>
            <a href="https://calendly.com/careconsult_online" className="btn btn-light">Book A Demo</a>
          </div>
        </div>
      ))}
    </div>
  );
};

// Counter Component for UHX with 'cr' and 'k' units
const Counter11 = () => {
  const countersRef = useRef([]);

  useEffect(() => {
    countersRef.current.forEach((counter) => {
      const target = +counter.getAttribute('data-count');
      let count = 0;
      const increment = target / 200;
      const unit = '%'; // Use percentage for all counters

      const updateCount = () => {
        if (count < target) {
          count += increment;
          counter.innerHTML = Math.ceil(count) + '&nbsp;' + unit;
          setTimeout(updateCount, 10);
        } else {
          counter.innerHTML = target + '&nbsp;' + unit;
        }
      };

      updateCount();
    });
  }, []);

  return (
    <div className="Counter11-section">
      <div className="Counter11-box">
        <div className="Counter11" data-count="44" ref={(el) => (countersRef.current[0] = el)}>
          0%
        </div>
        <p className="Counter11-text">Time saved in OPD Consultations</p>
      </div>
      <div className="Counter11-box">
        <div className="Counter11" data-count="200" ref={(el) => (countersRef.current[1] = el)}>
          0%
        </div>
        <p className="Counter11-text">Increase in follow-ups</p>
      </div>
      <div className="Counter11-box">
        <div className="Counter11" data-count="85" ref={(el) => (countersRef.current[2] = el)}>
          0%
        </div>
        <p className="Counter11-text">Improvements in patient outcomes
        </p>
      </div>
      <div className="Counter11-box">
        <div className="Counter11" data-count="50" ref={(el) => (countersRef.current[3] = el)}>
          0%
        </div>
        <p className="Counter11-text">Operational costs reduction</p>
      </div>
    </div>
  );
};



// UHX Component combining services and counter
const Cardiology= () => {
  return (
    <div className="headLine" >
        <Helmet>
        <title>Advanced Cardiology EMR with Continuous Vitals Monitoring & AI-Driven Insights - RxOne
        </title></Helmet>
        <Helmet> <meta name="description" content="Boost your cardiology practice with RxOne’s cutting-edge EMR solutions. Our platform features Continuous Vitals Tracking and Smart Alerting, empowering specialists to monitor patient health remotely and act on early warning indicators, resulting in timely interventions and better outcomes. With AI-powered Medical Data Summarization, RxOne streamlines consultations by providing actionable insights from patient records, saving valuable time in high OPD flows. The HIPAA-compliant platform also offers seamless case file management, cloud storage, and easy access for both patients and providers. Additional features like Contextual Autosuggest, Global Patient Search, and Follow-up Reminders make RxOne the ideal solution for cardiology specialists." /></Helmet>
      <Helmet>  <meta name="keywords" content="RxOne, Continuous Vitals Monitoring, Smart Reporting, cardiology tools, AI medical data summarization, specialized EMR, HIPAA compliant EMR, cloud storage EMR, patient health monitoring, follow-up reminders, medical file management, healthcare data insightsremote health monitoring tools, continuous vitals monitoring for cardiology, AI in healthcare data summarization, smart reporting for doctors, cardiology patient monitoring software, best EMR for specialists, HIPAA-compliant cloud storage for EMR, AI-powered patient insights, medical file management software, automated follow-up reminders healthcare, patient engagement software, digital health monitoring solutions, secure medical records online, global patient search EMR, cloud-based EMR software, early warning health alerts for doctors, cardiology consultation software" />
      </Helmet>
      {/* New Header Section */}
      <div className="uppHead2" >
        <h1 style={{ color: '#fff', margin: 0, fontFamily: "initial" }}>Specialized Cardiology EMR
</h1>
      </div>
    <div style={{ background: "linear-gradient(45deg, #005C74, #018C92, #01A09E)", padding: "20px", marginTop:"-100px"}}>
      <AllServices8InRectangle />
      <Counter11 />
   
    </div>
    <div style={{marginBottom:"-45px",}}>
    <BeforeFooter/></div>
    </div>
    
    
  );

};

export default Cardiology;
