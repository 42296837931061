import React, { useEffect } from "react";
import AOS from "aos";
import "aos/dist/aos.css";
import "../Css/videosection.css";
import { useMediaQuery } from "@material-ui/core"; // Import useMediaQuery from Material-UI

function VideoSection() {
  useEffect(() => {
    AOS.init(); // Initialize AOS for animations
  }, []);

  const isMobile = useMediaQuery("(max-width: 768px)"); // Detect mobile screens with width <= 768px

  return (
    <>
      <section data-aos-duration="3000" className="ms-cureways-section">
        <div className="container" style={{ marginTop: "-8rem" }}>
          <div className="row align-items-center mb-5 g-5 flex-column-reverse margin__first flex-lg-row">
            <div className="col-lg-6">
              <h1
                className="mb-4 ms-font-weight-600"
                style={{ color: "#fff", marginTop:"20px"}}
              >
               AI IS ALLY
              </h1>
            </div>
          </div>
        </div>
        <div className="container" style={{ marginTop: "-10rem" }}>
          <div className="row align-items-center mb-5 g-5 flex-column-reverse margin__first flex-lg-row">
            <div className="col-lg-6">
              <h2
                className="ms-font-weight-500"
                style={{
                  color: "#fff",
                  fontSize: isMobile ? "1.5rem" : "3.5rem", // Adjust font size based on screen size
                }}
              >
               Unleash power of AI and IoT with RxOne Healthcare Solutions.
              </h2>
              <div style={{marginTop:"20px"}}>
              <a href="https://calendly.com/careconsult_online" className="btn btn-light">Book A Demo</a></div>
            </div>
            
            <div className="col-lg-6">
              <div
                style={{
                  position: "relative",
                  paddingBottom: "56.25%", // Maintain aspect ratio of 16:9
                  height: 0,
                  overflow: "hidden",
                  maxWidth: "100%",
                  borderRadius: "15px",
                  boxShadow:
                    "0 25px 25px 0 rgba(0, 0, 0, 0.2), 0 6px 20px 0 rgba(0, 0, 0, 0.19)",
                }}
              >
               <iframe style={{
    position: "absolute",
    top: 0,
    left: 0,
    width: "100%",
    height: "100%",
    borderRadius: "15px",
  }}
  src="https://www.youtube.com/embed/dQbLEntB-q0?autoplay=1&mute=1&loop=1&playlist=dQbLEntB-q0"
  title="YouTube video player"
  frameBorder="0"
  allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
  allowFullScreen
></iframe>

              </div>
            </div>
          </div>
        </div>
      </section>
    </>
  );
}

export default VideoSection;
