import React, { useState, useEffect } from "react";
import AOS from "aos";
import "aos/dist/aos.css";
import { Navigate } from "react-router-dom";
import emailjs from "emailjs-com";
import TwitterIcon from "@mui/icons-material/Twitter";
import FacebookIcon from "@mui/icons-material/Facebook";
import InstagramIcon from "@mui/icons-material/Instagram";
import LinkedInIcon from "@mui/icons-material/LinkedIn";

function ContectUs() {
  
  const [loader, setLoader] = useState(false);

  useEffect(() => {
    AOS.init({ duration: 1000 });
  });

  async function sendMail(e) {
    e.preventDefault();
    setLoader(true);
    let data = await emailjs.sendForm(
      "service_2149vlm",
      "template_cllji1n",
      e.target,
      "user_nG8O481HPP4hLDs3wFbUi"
    );

    if (data.status == 200) {
      alert("thanks for contacting with us, we will get back to you shortly.");
      setLoader(false);
    } else {
    }
  }

  return (
    <section data-aos="fade-up" className="ms-contact-section">
      <div className="container-fluid">
        <div className="row">
          <div data-aos="slide-right" className="col-xl-5 p-0">
            <iframe
              src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d3505.75881820576!2d77.080337!3d28.516903199999998!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x390d195fc2f0810f%3A0x6289f11db364e533!2sHARTRON%2C%20IAMAI%2C%20Nasscom!5e0!3m2!1sen!2sin!4v1679421007302!5m2!1sen!2sin"
              style={{ border: "0px" }}
              allowFullScreen=""
              loading="lazy"
            ></iframe>
          </div>
          <div data-aos="slide-left" className="col-xl-7 p-0">
            <div className="ms-right-content">
              <div className="row">
                <div data-aos="fade-up" className="col-md-7 col-xxl-8">
                  <h4 className="ms-font-weight-600 ms-primary-color">
                    {" "}
                    Rx One Care Pvt. Ltd. (RxOne)
                  </h4>
                  <h5>
                    {" "}
                    Hartron Innovation Centre, Plot No1, Udyog Vihar, Phase I,
                    Dundahera Village, Sector 20, Gurugram, Haryana 122022,
                    India
                  </h5>
                  <br />
                  <h5>Phone: +91 7703934446</h5>
                  <h5 className="mb-5">Mail ID: contact_us@rxone.app</h5>
                </div>
                <div className="col-md-5 col-xxl-4 text-md-end mb-5">
                  <div className="ms-social-links mb-4 mb-lg-0">
                    <a
                      className="ms-secondary-btn"
                      href="https://www.facebook.com/RxDigitalLabs"
                    >
                      {/* <i className="fab fa-facebook-f ms-icon"></i> */}
                      <FacebookIcon />
                    </a>
                    <a
                      className="ms-secondary-btn"
                      href="https://twitter.com/LabsRx"
                    >
                      {/* <i className="fab fa-twitter ms-icon"></i> */}
                      <TwitterIcon />
                    </a>
                    <a
                      className="ms-secondary-btn"
                      href="https://www.linkedin.com/company/rx-digital-labs"
                    >
                      {/* <i className="fab fa-linkedin-in ms-icon"></i> */}
                      <LinkedInIcon />
                    </a>
                    <a
                      className="ms-secondary-btn"
                      href="https://www.instagram.com/rxone_care?igsh=cmN5bDFvcmV5eXk5"
                    >
                      {/* <i className="fab fa-linkedin-in ms-icon"></i> */}
                      <InstagramIcon />
                    </a>
                  </div>
                </div>
              </div>
              <h3 className="ms-font-weight-600 ms-primary-color mb-3">
                Get In Touch
              </h3>
              <form onSubmit={sendMail}>
                <div className="row g-3">
                  <div className="col-md-6">
                    <input
                      type="text"
                      name="name"
                      className="form-control"
                      required
                      placeholder="Name"
                    />
                  </div>
                  <div className="col-md-6">
                    <input
                      type="email"
                      name="user-email"
                      className="form-control"
                      required
                      placeholder="Email"
                    />
                  </div>
                  <div className="col-md-12">
                    <input
                      type="tel"
                      name="user-number"
                      className="form-control"
                      required
                      placeholder="Phone"
                    />
                  </div>
                  <div className="col-md-12">
                    <textarea
                      className="form-control"
                      name="user-message"
                      required
                      placeholder="Message"
                      rows="6"
                    ></textarea>
                  </div>
                  <div className="col-md-12">
                    <button type="submit" className="ms-secondary-btn">
                      {" "}
                      Submit Message{" "}
                      {loader && (
                        <span
                          class="spinner-border spinner-border-sm ms-2"
                          role="status"
                        ></span>
                      )}
                    </button>
                  </div>
                </div>
              </form>
            </div>
          </div>
        </div>
      </div>
    </section>
  );
}

export default ContectUs;
