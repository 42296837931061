import React from 'react';
import 'bootstrap/dist/css/bootstrap.min.css';
import '../Css/ourPartner.css'; // Custom CSS file for additional styling
import partner1 from '../Images/Picture5.png';
import partner2 from '../Images/partner1.png';
import partner3 from '../Images/partner2.png';
import partner4 from '../Images/partner3.png';
import partner5 from '../Images/forties-removebg-preview.png';
import partner6 from '../Images/partner6.png';
import partner7 from '../Images/Birlaivf.png';
import partner8 from '../Images/provider/partner9-removebg-preview.png';

// Array of partner data
const partners = [
  { imgSrc: partner1 },
  { imgSrc: partner2 },
  { imgSrc: partner3 },
  { imgSrc: partner4 },
  { imgSrc: partner5 },
  { imgSrc: partner6 },
  { imgSrc: partner7 },
  { imgSrc: partner8 },
];

// OurPartner Component
const OurPartner = () => {
  // Create a continuous loop by duplicating the partners
  const loopedPartners = [...partners, ...partners];

  return (
    <div className="our-partner-container">
      <h2 className="our-partner-heading text-center">Key Partners</h2>

      <div className="marquee">
        <div className="marquee-content">
          {loopedPartners.map((partner, index) => (
            <div className="partner-card" key={index}>
              <img src={partner.imgSrc} alt="Partner" className="partner-img" />
            </div>
          ))}
        </div>
      </div>
    </div>
  );
};

export default OurPartner;
