import React, { useEffect, useRef } from 'react';
import 'bootstrap/dist/css/bootstrap.min.css';
import '../Css/uhx.css'; // Import the CSS file for the product section
import '../Css/counter.css'; // Import the CSS file for the counter section
import serviceGif7 from '../Images/Picture12-removebg-preview.png';
import serviceGif8 from '../Images/Picture13.png';
import serviceGif9 from '../Images/Picture14.png'; 
import phoneImg from '../Images/file.png'; // Path for the static phone image
import BeforeFooter from './BeforeFooter';
import { Helmet } from "react-helmet";
// Array of services for the UHX component
const services5 = [
  {
    imgSrc: serviceGif7,
    heading: 'Ayushman Bharat Health Account (ABHA)',
    description: 'The ABHA (Ayushman Bharat Health Account) is managed under the Ayushman Bharat Digital Mission (ABDM), which is a digital healthcare initiative of the National Health Authority (NHA). Under this mission, having this health card, the citizens of India are provided with numerous benefits, such as hassle-free raccess to medical treatments and healthcare facilities, easy sign-up options for personal health record applications (like ABDM ABHA app), and trustable identity.',
  },
  {
    imgSrc: serviceGif8,
    heading: 'UHI (Unified Health Interchange)',
    description: 'UHI is one of the core foundations of ABDM. Similar in concept to the Unified Payment Interface (UPI) where you can make payment to a PhonePe account using a UPI ID from either GooglePay or Paytm, UHI will enable you to consult patients looking to consult doctors on any UHI-compliant and participating website or application as well such as Paytm, Arogya Setu and so on.',
  },
  {
    imgSrc: serviceGif9,
    heading: 'Digital Health Incentive Scheme',
    description: 'DHIS is a tool to promote the widespread implementation and adoption of Ayushman Bharat Digital Mission so that a large number of India’s population can get access to quality healthcare and benefit from digital health services. While digital health services across our country have been growing at an unprecedented rate, its adoption is primarily limited to Tier 1 & Tier 2 cities. With DHIS, the government aims to incentivize healthcare professionals to champion this change and further boost digital health transactions within the country. ',
  },
];

// Component to render UHX services in a structured layout
const AllServices5InRectangle = () => {
  return (
    <div className="service5-rectangle"style={{marginTop:"-5px"}}>
       <div className='racHead9'><h3 className='racH9'>Simplified Medical Data Exchange</h3></div> 
      {services5.map((service5, index) => (
       <div className={`service-section ${index === 1 ? 'second-section' : ''}`} key={index}>
          <div className={`service5-img-container ${index % 2 !== 0 ? 'order-last' : ''}`}>
            <img src={service5.imgSrc} alt={service5.heading} className="img-fluid service5-img" />
          </div>
          <div className="service5-text">
            <h3 className="mb-3">{service5.heading}</h3>
            <p>{service5.description}</p>
            <a href="https://calendly.com/careconsult_online" className="btn btn-light">Book A Demo</a>
          </div>
        </div>
      ))}
    </div>
  );
};

// Counter Component for UHX with 'cr' and 'k' units
const Counter8 = () => {
  const countersRef = useRef([]);

  useEffect(() => {
    countersRef.current.forEach((counter, index) => {
      const target = +counter.getAttribute('data-count');
      let count = 0;
      const increment = target / 200;
      
      // Determine the unit: 'Cr' for the first two counters, 'K' for the last two
      const unit = index < 2 ? 'Cr' : 'K';

      const updateCount = () => {
        if (count < target) {
          count += increment;
          counter.innerHTML = Math.ceil(count) + '&nbsp;' + unit;
          setTimeout(updateCount, 10);
        } else {
          counter.innerHTML = target + '&nbsp;' + unit;
        }
      };

      updateCount();
    });
  }, []);

  return (
    <div className="Counter8-section">
      <div className="Counter8-box">
        <div className="Counter8" data-count="4" ref={(el) => (countersRef.current[0] = el)}>
          0Cr
        </div>
        <p className="Counter8-text">Earning opportunities through DHIS</p>
      </div>
      <div className="Counter8-box">
        <div className="Counter8" data-count="65" ref={(el) => (countersRef.current[1] = el)}>
          0Cr
        </div>
        <p className="Counter8-text">ABHA Account registered</p>
      </div>
      <div className="Counter8-box">
        <div className="Counter8" data-count="318" ref={(el) => (countersRef.current[2] = el)}>
          0K
        </div>
        <p className="Counter8-text">Health Facilities Registered</p>
      </div>
      <div className="Counter8-box">
        <div className="Counter8" data-count="455" ref={(el) => (countersRef.current[3] = el)}>
          0K
        </div>
        <p className="Counter8-text">Healthcare Professional Registered</p>
      </div>
    </div>
  );
};


// UHX Component combining services and counter
const UHX = () => {
  return (
    <div className="headLine" >
      <Helmet>
        <title>Ayushman Bharat Health Account (ABHA), UHI (Unified Health Interchange), Digital Health Incentive Scheme :RxOne
        </title></Helmet>
        <Helmet><meta name="description" content="Learn about Ayushman Bharat Health Account (ABHA) and the Ayushman Bharat Digital Mission (ABDM). Get seamless access to healthcare services through the ABHA health card, UHI for online consultations, and DHIS for Digital Health Incentive Scheme. Empowering India with secure, digital health solutions." /></Helmet>
        <Helmet><meta name="keywords" content="ABHA, Ayushman Bharat Health Account, ABDM, Ayushman Bharat Digital Mission, UHI, Unified Health Interchange, DHIS, Digital Health Incentive Scheme, digital healthcare India, National Health Authority, ABDM ABHA app, healthcare identity, digital health services, UPI for healthcare, Arogya Setu healthcare, digital healthcare adoption, healthcare incentives, RxOnecare" />
      </Helmet>
    {/* New Header Section */}
    <div className="uppHead" >
    <h1 className="UpTex">Unified Health Exchange (UHX)      </h1>
    </div>
  <div style={{ background: "linear-gradient(45deg, #005C74, #018C92, #01A09E)", padding: "20px", marginTop:"-100px" }}>
    <AllServices5InRectangle />
    <Counter8 />
  </div>
  <div style={{marginBottom:"-45px",}}>
    <BeforeFooter/></div>
  </div>
  );
};

export default UHX;
