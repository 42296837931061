import React,{useState,useContext, useEffect} from 'react'
import { Link } from 'react-router-dom';
import CancelIcon from '@mui/icons-material/Cancel';
import doctorTemplate from "../../Images/hospital-template.png"
import ServiceCall from "../../ServiceCall"
import UserAuth from "../../GlobelContext/UserAuth"



function Portal({close, hospitalData, url, background }) {

 const [searchData, setSearchData] = useState("")
 /**** ***** THESE TWO ARE FOR I FRAME ******** */
 const [isIframe, setIsIframe] = useState(false)
 const [Url, setUrl] = useState("")
 /**** ***** THESE TWO ARE FOR I FRAME ******** */
 const[loading, stillLoading] = useState(true)

 const[isChatBot, setChatBot] = UserAuth();

 useEffect(()=>{
  setChatBot(true)

  return () =>{
    setChatBot(false)
  }
 },[])

 const finishLoading = () => {
  stillLoading(false)
}

 const navigation = (e) => {
  setUrl(url(e))
   setIsIframe(true)
 }


const handleSubmit = (e) =>{
      e.preventDefault();
      setSearchData(searchData);
    }
    
    console.log(Url)
    
    return (
      <>
      {isIframe && 
      <div style={{width:"100%", height:"100%"}} className={ `${background} d-flex flex-column justify-content-center align-items-center position-relative embed-responsive embed-responsive-16by9`} >
     <CancelIcon  className="iframe_btn" fontSize='large' onClick={()=>setIsIframe(false)} />
     {
      loading ?  <div style={{width:"100vw",height:"100vh", left:"50%", top:"50%"}} className="position-absolute">
                      <div className="spinner-border" role="status">
                            <span className="visually-hidden">Loading...</span>
                            </div> 
                      </div>: null
     }
     <iframe style={{width:"90%", height:"90%",overflow:"hidden"}} className="iframe__container embed-responsive-item" src={Url}  allow="geolocation; microphone; camera"  onLoad={finishLoading}> </iframe>

     </div>
      }

    <div className='login_modal'>
    <div  className=' portal_modal_content login_modal_content'>
   <CancelIcon  className="login__Cancel " fontSize='large' onClick={()=>close(false)} />
   <form onSubmit={handleSubmit} className="portal_search">
      <input type="text" className="form-control" onChange={(e)=>setSearchData(e.target.value)}  placeholder="Search for Hospital/Clinic/Facility to Login"/>
      <p>or</p>
      <p>select from below list</p>
    </form>
   <div  className=" portal_btns d-flex align-items-center flex-column">

   {hospitalData.length === 0 ?
    <div style={{width:"100%",}} className="d-flex justify-content-center align-items-center">
                      <div className="spinner-border" role="status">
                            <span className="visually-hidden">Loading...</span>
                            </div> 
                           </div> :
              hospitalData.filter(value=>{
                if(searchData===""){
                  return value;
                }else if(value.hospital_name.toLowerCase().includes(searchData.toLowerCase())){
                    return value;
                }
              }).map((items,index) => (
          <>
       <button  style={{width:"100% !important"}} className='d-flex justify-content-evenly portal_btn row ms-secondary-btn' onClick={()=>navigation(items.hos_id)}>
       <div  className="col-3 portal_btn_first  align-self-center">
        <img src={ServiceCall.images(`/hospital/logo/${items.hos_id}`)} alt=""/> 
       </div>
        <div className="col-9 portal_btn_second align-self-center" >
          <h5>{items.hospital_name}</h5>
          <p>{items.address}</p>
        </div>
        </button>    
        </>

          ))
   }
      </div>
   </div>
   </div>
   {/* </div> */}
    </>
  )
}

export default Portal