import React from 'react';
import '../Css/home2.css'; // Import the corresponding CSS
import Header from './Header';
import { Helmet } from "react-helmet";
// Import icons from MUI
import LaptopMacIcon from '@mui/icons-material/LaptopMac';
import TabletMacIcon from '@mui/icons-material/TabletMac';
import PhoneIphoneIcon from '@mui/icons-material/PhoneIphone';
import Counter from './Counter';
import OurPartner from './OurPartner';
import CareFacility from './CareFacility';
import Testimonials from './Testimonials';
import Counter3 from './Counter3';
import BeforeFooter from './BeforeFooter';
// Import images
import image1 from '../Images/Picture33-removebg-preview.png';
import image2 from '../Images/Picture34-removebg-preview.png';
import image3 from '../Images/Picture35-removebg-preview.png';
import halfBgImage from '../Images/Doctor_Portal_Mockup.png';
import VideoSection from './VideoSection';
function Home2() {
  return (
    <>
    <Header/>
    <Helmet>
        <title> RxOnecare - EMR & Health Informatics | Actionable Insights for Doctors &
        Care Providers
        </title></Helmet>
        <Helmet>  <meta name="description" content="RxOnecare is a comprehensive, AI-powered healthcare platform designed to streamline medical services, optimize costs, and improve patient outcomes. Offering an all-in-one solution for hospitals, clinics, and healthcare providers, RxOne integrates advanced EMR software, IoT medical devices, and real-time decision support to enhance the quality of care. With features like automated workflows, telemedicine solutions, digital prescriptions, and continuous patient monitoring, RxOne enables healthcare professionals to deliver efficient, personalized care" /></Helmet>
       <Helmet> <meta name="keywords" content="best healthcare services, EMR for hospitals, AI in healthcare, healthcare cost reduction, IoT medical devices, hospital automation solutions, patient monitoring systems, digital health platforms, improving patient care, hospital EMR software, healthcare management system, AI for clinical decisions, automated healthcare workflows, telemedicine solutions, patient care software, IoT in medical field, electronic medical records system, optimizing hospital services, advanced medical technology, real-time healthcare decision support" />
      </Helmet>
    <div className="home2-container">
      <div className="gradient-section">
        <div className="content-section">
          <h1 className="heading" >Scale services, optimize costs and improve care outcomes</h1>
          <div className="button-container">
            <div style={{backgroundColor:"#005C74",border: "none",borderradius: "5px",}}>
          <a href="https://calendly.com/careconsult_online" target="_blank" rel="noopener noreferrer">
    <button className="btn-download">Book A Demo</button>
</a></div>
            <a href="https://play.google.com/store/apps/details?id=com.rxonedoctor&hl=en_IN" target="_blank" rel="noopener noreferrer">
    <button className="btn-download">Download App</button>
</a>

          </div>
          <div className="description" style={{color:"#fff",}}>
            Our EMR solution leverages the world’s leading AI models and IoT device integrations to seamlessly automate care workflows, provide real-time decision support, and optimize revenue. This allows you to focus on what matters most—your patients—and enhance the quality of care you deliver.
          </div>
          <div className="icon-row">
            <div className="icon-item">
              <p className="icon-text"><LaptopMacIcon/> Web App</p>
            </div>
            <div className="icon-item">  
              <p className="icon-text"><TabletMacIcon/> IOS & Android</p>
            </div>
            <div className="icon-item">
              <p className="icon-text"><PhoneIphoneIcon/> iPad</p>
            </div>
          </div>
          
        </div>
      </div>
      
      <div className="image-section">
        <img src={halfBgImage} alt="Decorative" className="half-bg-image" />
      </div>
      
      {/* New image section added */}
      {/* <div className="image-container">
        <img src={image1} alt="Image 1" className="image image1" />
        <img src={image2} alt="Image 2" className="image image2" />
        <img src={image3} alt="Image 3" className="image image3" />
      </div>
       */}
    </div>
    <Counter/>
    <OurPartner/>
    <VideoSection/>
    <CareFacility/>
    <Testimonials/>
    <Counter3/> 
   
    <BeforeFooter/>
    </>
  );
 
}


export default Home2;
